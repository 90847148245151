const info = () => import("@/views/my/InfoView.vue");
const health_info = () => import("@/views/my/HealthInfo.vue");

import Layout from "../layouts/LayoutBasic.vue";

export default [
    {
      path: "/my/info",
      name: "myInfo",
      component: info,
      meta: { layout: Layout, title: "내 가입정보" },
    },
    {
      path: "/my/health",
      name: "myHealth",
      component: health_info,
      meta: { layout: Layout, title: "내 건강정보" },
    },
  ];