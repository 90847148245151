<template>
    <div class="HomeView">
        <MainLogo />
        <InputSearch v-model="keyword" placeholder="제품명/성분명/제조사명으로 찾아보세요." @search="search" />
        <v-container class="A130CompWrap">
            <v-row no-gutters class="wrap">
                <v-col cols="6" class="pa-2">
                    <ImageButton :button="button.낱알검색" @click="$root.pop_menu('shape')" />
                </v-col>
                <v-col cols="6" class="pa-2">
                    <ImageButton :button="button.고급검색" @click="$root.pop_menu('advanced')" />
                </v-col>
                <v-col cols="6" class="pa-2">
                    <ImageButton :button="button.QR검색" @click="$root.pop_menu('qr')" />
                </v-col>
                <v-col cols="6" class="pa-2">
                    <ImageButton :button="button.음성검색" @click="$root.pop_menu('voice')" />
                </v-col>
            </v-row>
        </v-container>
        <div><HomeTab /></div>
    </div>
</template>
<script>
import HomeTab from './HomeTab.vue'
import ImageButton from '@/components/ImageButton.vue'
import InputSearch from '@/components/InputSearch.vue'
import MainLogo from '@/components/MainLogo.vue'

import storage from '@/assets/js/storage';

export default {
    name:'HomeView',
    components:{
        HomeTab,
        ImageButton,
        InputSearch,
        MainLogo
    },
    props:{},
    computed:{},
    data:()=>({
        button: {
            "낱알검색": {
                src: require('@/assets/images/main/pill.svg'),
                alt: 'pill',
                text: '낱알검색'
            },
            "고급검색": {
                src: require('@/assets/images/main/detail.svg'),
                alt: 'detail',
                text: '고급검색'
            },
            "QR검색": {
                src: require('@/assets/images/main/QR.svg'),
                alt: 'QR',
                text: 'QR검색'
            },
            "음성검색": {
                src: require('@/assets/images/main/voice.svg'),
                alt: 'voice',
                text: '음성검색'
            }
        },
        keyword: '',
    }),
    mounted() {

    },
    methods:{
        search() {
            if (storage.is_login()) {
                if (this.keyword == undefined || this.keyword.length < 2) {
                    this.$alert('두글자 이상 입력해주세요.')
                } else {
                    var query = { mode: 'basic', st: this.keyword }
                    this.$router.push({ path: "/drug/search", query: query });
                }
            } else {
                this.$alert('로그인이 필요합니다.')
                this.$router.push('/user/login');
            }
        }
    },
    watch:{}
}
</script>
<style lang="scss" scoped>
@import "@/assets/css/_variables.scss";
@import "@/assets/css/_mixins.scss";

.HomeView {
    position: relative;
    display:flex;
    flex-direction: column;
    height: 100%;
    height:calc( 100vh - 88px);
    height:calc( 100dvh - 88px);
    min-height: 100%;
}

.HomeView > div:last-child {
    flex-grow: 1;
    overflow-y: auto;
}

.logo {
    @include center;
    padding: 10px 0 30px;
}

.logo img {
    max-height: 90px;
    max-width: 50%;
}

.wrap {
    @include center;
    padding: 10px;
}

.A130CompWrap {
    margin: 0 auto;
}


.btn {
    padding: 20px 0;
    gap: 10px;
    @include H3;
    color: $b-7;
    border: 2px solid $b-9;
    border-radius: 12px;
    box-shadow: $shadow;
}
</style>