<template>
    <div class="ASBox1">
        <div class="title"><span>{{ title }}</span><slot name="title"></slot></div>
        <div class="input"><slot name="input"></slot></div>
    </div>
</template>
<script>
export default {
    name:'AdvancedSearch',
    components:{

    },
    props:{
        title:{
            type:String,
            default:''
        }
    },
    mounted() {
        
    },
    methods:{

    }
}
</script>
<style lang="scss" scoped>
@import "@/assets/css/_variables.scss";
@import "@/assets/css/_mixins.scss";

.title {
    padding-bottom: 4px;
}

.title span {
    @include H4;
    color: $b-g-6;
}
</style>