<template>
    <div class="PrescriptionPreview">
        <div v-if="is_loading">
            <GuideSpan>처방전에 등록된 의약품을 조회중입니다.</GuideSpan>
        </div>
        <div v-else>
            <PrescriptionTop :item="item" type="preview" />
            <ContentBox :title="titleDrugs" class="blue" :open="true">
                <TakeCard v-for="(drug, i) in item.drugs" :item="drug" :key="i" :show_right_buttons="false"
                    @click="sel_drug(drug, i)" />
            </ContentBox>
            <GuideSpan v-if="error_code != '' && error_code != '0'" class="a">처방일이 누락되었습니다.</GuideSpan>
        </div>
        <div class="footer_button" v-if="error_code != ''">
            <button @:click="v_date = true">처방일 설정</button>
        </div>

        <div class="footer_button" v-if="(error_code == '' || error_code == '0') && !is_loading">
            <button @:click="v_date = true" v-if="error_code != ''">처방일 변경</button>
            <button @:click="v_new = true">처방이력 등록</button>
        </div>

        <DialogComp v-model="v_new" title="폴더 선택" class="no_actions">
            <template v-slot:body>
                <GuideSpan>저장할 폴더를 선택해주세요.</GuideSpan>
                <FolderList :folders="folders" :show_right_buttons="false" @select_item="select_folder" />
            </template>
        </DialogComp>

        <DialogComp v-model="v_edit" title="의약품명 수정" class="no_actions">
            <template v-slot:body>
                <GuideSpan>{{ selected_drug.edi_code }}의 의약품명, 업체명을 입력해주세요.</GuideSpan>
                <ASBox1 title="의약품명">
                    <template v-slot:input>
                        <InputText v-model="selected_drug.item_name" />
                    </template>
                </ASBox1>
                <ASBox1 title="업체명">
                    <template v-slot:input>
                        <InputText v-model="selected_drug.entp_name" />
                    </template>
                </ASBox1>
                <ButtonComp @click="sel_save">
                    확인
                </ButtonComp>
            </template>
        </DialogComp>

        <DialogComp2 v-model="v_date" :no_title="true" class="no_actions no_margin top11" width="360">
            <template v-slot:body>
                <DatePicker v-model="date" title="처방일" @select_date="select_date"></DatePicker>
            </template>
        </DialogComp2>
    </div>
</template>
<script>
import ASBox1 from '@/components/search/ASBox1.vue'
import ButtonComp from '@/components/ButtonComp.vue'
import ContentBox from '@/components/drug/ContentBox.vue'
import DatePicker from '@/components/DatePicker.vue'
import DialogComp from '@/components/DialogComp.vue'
import DialogComp2 from '@/components/DialogComp2.vue'
import FolderList from '@/components/drug/FolderList.vue'
import GuideSpan from '@/components/GuideSpan.vue'
import InputText from '@/components/InputText.vue'
import PrescriptionTop from '@/components/my/drug/PrescriptionTop.vue'
import TakeCard from '@/components/my/drug/TakeCard.vue'

import ng from '@/assets/js/ng.js'

import moment from 'moment'

export default {
    name: 'PrescriptionPreview',
    components: {
        ASBox1,
        ButtonComp,
        ContentBox,
        DatePicker,
        DialogComp,
        DialogComp2,
        FolderList,
        GuideSpan,
        InputText,
        PrescriptionTop,
        TakeCard
    },
    props: {
        qr_message: {
            type: String,
            default: ''
        }
    },
    computed: {
        titleDrugs() {
            if (this.item.drugs == undefined) return '처방이력 상세정보';
            return '처방이력 상세정보 | 총' + this.item.drugs.length + '건'
        },
    },
    data: () => ({
        folders: [],
        is_loading: true,
        item: {
            drugs: []
        },
        v_date: false,
        v_edit: false,
        v_new: false,
        selected_drug: {
            entp_name: ''
        },
        selected_index: 0,
        error_code: '',
        date: '',
    }),
    mounted() {
        this.load();
    },
    methods: {
        sel_drug(drug, i) {
            if (drug.item_seq == 0) {
                this.selected_drug = drug;
                this.selected_index = i;
                this.v_edit = true;
            }
        },
        sel_save() {
            this.v_edit = false;
        },
        load() {
            var qrCodeMessage = this.qr_message;

            try {
                var arr = qrCodeMessage.split("\n");
                var item = ng.qr_decode(qrCodeMessage);

                if (item.date == '') {
                    console.log(item);
                    console.log(this.item)
                    var pu = item.pu;
                    var d = pu.substring(pu.indexOf(':') + 1)
                    console.log(d)
                    d = d.substring(0, 8);
                    console.log(d);
                    var p_date = moment(d).format('YYYY-MM-DD');
                    console.log(p_date)

                    if (moment(d).isValid()) {
                        console.log('유효한 알짜')
                        item.date = d;
                        this.error_code = '0';
                    } else {
                        console.log('유효하지 않는 알짜')
                        this.error_code = 'invalid_date';
                    }
                }

                console.log(this.error_code)

                this.$api.my.prescription.list({}, { pu: item.pu }).then(response => {
                    if (arr.length == 2) {
                        if (response.data.paging.total > 0) {
                            this.$emit('prescription_detail', response.data.data[0]);
                        } else {
                            this.$alert("아직 등록되지 않은 QR코드 입니다.")
                        }
                    } else {
                        if (response.data.paging.total > 0) {
                            this.$emit('prescription_detail', response.data.data[0]);
                        } else {
                            this.set_item(item);
                        }
                    }

                })
            } catch (e) {
                this.$alert(e.message);
                this.$emit('close');
            }
        },
        set_item(item) {
            this.set_medicines(item)
                .then((db) => {
                    this.is_loading = false;

                    var item = {};

                    item.hospital = db.hospital.name;
                    item.pharm = db.pharm.name;
                    item.p_date = moment(db.date).format('YYYY-MM-DD')
                    item.pu = db.pu;
                    item.p_num = db.number;
                    item.type = 1; // QR코드로 촬영

                    item.drugs = ng.clone(db.me);

                    this.item = item;

                    var days = 0;
                    this.item.drugs.forEach(d => {
                        if (d.days > days) days = d.days;
                    });
                    this.item.days = days;
                    this.item.num_of_drug = this.item.drugs.length;

                    var has_e = false;
                    this.item.drugs.forEach(item => {
                        if (item.item_seq == 0) has_e = true;
                    })

                    if (has_e) {
                        this.$alert('시스템에 등록되지 않은 의약품이 있습니다. 터치 후 명칭을 수정해주세요.');
                    }
                })
                .catch((err) => {
                    console.error(err);
                });
        },
        select_date(val) {
            console.log(val);
            this.v_date = false;
            this.item.p_date = moment(val).format('YYYY-MM-DD');
            this.error_code = '0';

        },
        select_folder(val) {
            this.item.folder_uuids = [val.uuid];

            this.$api.my.prescription.insert({}, {}, this.item).then(() => {
                this.v_new = false;
                this.$alert('저장되었습니다.').then(() => {
                    this.$emit('saved');
                })
            });
        },
        set_medicines(db) {
            var codes = [];
            db.me.forEach((item) => {
                codes.push(item.edi_code);
            });

            return new Promise((resolve, reject) => {
                var db_medicine = {};
                if (codes.length > 0) {
                    var query = { edi_code: codes.join(","), num_of_rows: 30 };
                    this.$api.medicine.search({}, query).then((response) => {
                        codes.forEach((code) => {
                            response.data.data.forEach((item) => {
                                if (item.bar_code.indexOf(code) != -1) {
                                    db_medicine[code] = item;
                                }
                            });
                        });

                        db.me.forEach((item) => {
                            var edi_code = item.edi_code;

                            if (db_medicine[edi_code] != undefined) {
                                item.item_seq = db_medicine[edi_code].item_seq;
                                item.item_name = db_medicine[edi_code].item_name;
                                item.entp_name = db_medicine[edi_code].entp_name;
                                item.item_image = db_medicine[edi_code].item_image == undefined ? '' : db_medicine[edi_code].item_image;
                            } else {
                                item.item_name = '의약품명 수정요함';
                                item.item_seq = 0;
                                item.entp_name = '';
                                item.item_image = '';
                            }

                        });
                        resolve(db);
                    });
                } else {
                    reject(db);
                }
            });
        }
    },
    watch: {
        v_new(val) {
            if (val) {
                this.$api.my.folder.list().then(response => {
                    var folders = response.data.data;
                    this.folders = folders;
                });
            }
        }
    }
}
</script>
<style lang="scss" scoped>
@import "@/assets/css/_variables.scss";
@import "@/assets/css/_mixins.scss";

.PrescriptionPreview {
    min-height: 560px;
}

.ContentBox {
    padding-bottom: 20px;
}

.footer_button {
    button {
        border-radius: 8px 8px 0 0 !important;
        width: 100%;
    }
}

.GuideSpan.a {
    margin-bottom: 30px;
}
</style>