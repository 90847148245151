<template>
    <div>
        <v-text-field v-model="input" :value="modelValue" variant="plain" hide-no-data density="compact"
            :hide-details="hideDetails" :clearable="clearable" @click:clear="clear" :placeholder="placeholder"
            @update:modelValue="$emit('update:modelValue', input)" append-inner-icon="mdi-magnify" @update:focused="focused"
            @click:control="click_control($event)" @click:appendInner="search" @keydown="keydown">
        </v-text-field>
        <div class="list_wrapper" v-if="items.length > 0 && is_show && false">
            <div class="list">
                <span v-for="(item, i) in items" :key="i" @click="updateValue(item)">
                    <slot name="item" :item="item"></slot>
                </span>
            </div>
        </div>
        <DialogComp2 v-model="show_dialog" class="no_actions" min-width="300px" max-width="500px">
            <template v-slot:body>
                <div class="bg1">
                    <GuideSpan v-if="items.length >= 50">검색결과가 50건이 넘습니다.<br>검색어를 상세히 입력해주세요.</GuideSpan>
                    <div class="list">
                        <span v-for="(item, i) in items" :key="i" @click="updateValue(item)">
                            <slot name="item" :item="item"></slot>
                        </span>
                    </div>
                </div>
            </template>
        </DialogComp2>
    </div>
</template>
<script>
import DialogComp2 from '@/components/DialogComp2.vue'
import GuideSpan from '@/components/GuideSpan.vue'

export default {
    name: 'ComboBox',
    components: {
        DialogComp2,
        GuideSpan
    },
    props: {
        modelValue: {
            type: String,
            default: ''
        },
        clearable: {
            type: Boolean,
            default: false
        },
        hideDetails: {
            type: Boolean,
            default: true
        },
        items: {
            type: Array,
            default: () => ([])
        },
        placeholder: {
            type: String,
            default: ''
        }
    },
    data: () => ({
        input: null,
        is_show: true,
        time: new Date().getTime(),
        timer: null,
        isDirty: false,
        show_dialog:false
    }),
    computed: {
        menuIcon() {
            if (this.items.length == 0) return '';
            if (this.is_show) {
                return 'mdi-chevron-up';
            } else {
                return 'mdi-chevron-down';
            }
        },
    },
    methods: {
        clear() {
            this.input = '';
            this.$emit('clear');
        },
        click_control(event) {
            if (event.target.tagName == 'INPUT') this.is_show = true;
        },
        is_valid() {
            var time = new Date().getTime();
            if ((time - this.time) > 100) {
                this.time = time;
                return true;
            }
            return false;
        },
        toggleMenu() {
            clearTimeout(this.timer);
            var is_valid = this.is_valid();
            if (is_valid) this.is_show = this.is_show ? false : true;
        },
        updateValue(val) {
            this.input = val;
            this.is_show = false;
            this.show_dialog = false;
            this.$emit('select:item', val);
        },
        focused(val) {
            var vm = this;

            if (val) {
                this.timer = setTimeout(function () {
                    if (vm.is_valid()) {
                        if (val) vm.is_show = true;
                        else vm.is_show = false;
                    }
                }, 200)
            } else {
                this.is_show = true;
            }
        },
        keydown() {
            this.isDirty = true;
        },
        search() {
            this.$emit('search', this.input);
        }
    },
    watch: {
        items(val) {
            if(val.length > 0) {
                this.show_dialog = true;
            }
        }
    }
}
</script>
<style lang="scss" scoped>
@import "@/assets/css/_variables.scss";
@import "@/assets/css/_mixins.scss";


.v-input {
    @include simple_input;
}

.v-input :deep(.v-field__append-inner) {
    padding-top: 6px;
    padding-right: 2px;
}

:deep(.v-field__append-inner > .v-icon) {
    opacity: 1;
}

:deep(.v-field__append-inner) {
    i {
        color: #fff;
        background-color: $b-9 !important;
        border-radius: 8px;
        padding: 18px;
        margin-right: 4px;
    }
}


.list_wrapper {
    position: relative;
    width: calc(100% - 5px);
    height: 0px;
    border: 0px solid grey;
}

.list_wrapper .list {
    position: absolute;
    background: white;
    min-width: 100px;
    width: calc(100% - 10px);
    max-height: 200px;
    overflow-y: auto;
    padding: 10px;
    border-radius: 5px;
    box-shadow: $shadow;
}

.list_wrapper .list span {
    display: block;
    cursor: pointer;
    line-height: 170%;
    margin: 5px 0px;
}

.bg1 {
    background-color:white;
    padding:20px;
    border-radius:10px;
    max-height: calc(100vh - 40px);
    max-height: calc(100dvh - 40px);
    height: fit-content;
    overflow-y: auto;
}
</style>