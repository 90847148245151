<template>
    <div class="DetailComp" :class="fontClass">
        <div class="marker" :class="{ 'on': is_marked }" @click="keyword_highlight_toggle"><v-icon
                size="24">mdi-marker</v-icon></div>

        <ContentBox v-for="(item1, i) in list" :title="item1.title" :key="i" :open="item1.open">
            <table class="info_a">
                <tbody>
                    <tr v-for="(item2, j) in item1.list" :key="j">
                        <th>{{ item2.title }}</th>
                        <td>
                            <p v-if="item2.type == undefined">{{ item2.text }}</p>
                            <a v-if="item2.type == 'file'" :href="item2.text" target="_blank">다운로드</a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </ContentBox>

        <ContentBox title="원료약품 및 분량" v-if="item.material_name != undefined" :open="true">
            <span>유효성분 : {{ clean_ingr(item.main_item_ingr) }}</span>
            <table class="info_a" v-if="item.arr_ingr == undefined">
                <tbody>
                    <tr v-for="(mat, j) in material_name(item.material_name).filter((x) => x.v != '')" :key="j">
                        <th>{{ mat.l }}</th>
                        <td>
                            <p>{{ mat.v }}</p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div v-if="item.arr_ingr != undefined" class="arr_ingr">
                <div v-for="(item1, indx) in item.arr_ingr" :key="indx">
                    <table class="info_a">
                        <tbody>
                            <tr v-for="(mat, j) in material_name(item1.material_name).filter((x) => x.v != '')"
                                :key="j">
                                <th>{{ mat.l }}</th>
                                <td>
                                    <p>{{ mat.v }}</p>
                                </td>
                            </tr>
                            <tr>
                                <th>첨가제</th>
                                <td>
                                    <p>{{ comp_ingr(item1.ingr_name) }}</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </ContentBox>

        <ContentBox title="효능효과" class="blue" v-if="item.atc_ee != undefined || item.ee_doc_data != undefined" :open="true">
            <span class="atc_ee">{{ item.atc_ee }}</span>
            <template v-if="item.ee_doc_data != undefined">
                <div class="info" v-for="(section, i) in item.ee_doc_data.sections" :key="i">
                <span class="section_title" v-if="section.title != ''" v-html="highlight_regex(section.title)"></span>
                <div v-for="(article, j) in section.articles" :key="j">
                    <span class="article_title" v-if="article.title != ''"
                        v-html="highlight_regex(article.title)"></span>
                    <div v-for="(paragraph, k) in article.paragraphs" :key="k">
                        <pre v-if="paragraph.tag == 'p'" v-html="highlight_regex(paragraph.text)"></pre>
                        <div class="tableWrap" v-if="paragraph.tag == 'table'">
                            <table v-html="paragraph.text" class="nb_basic"></table>
                        </div>
                    </div>
                </div>
            </div>
            </template>

            <a :href="item.ee_doc_id" target="_blank" v-if="item.ee_doc_id != undefined">다운로드
                <v-icon>mdi-download</v-icon></a>
        </ContentBox>

        <ContentBox title="용법용량" class="blue" v-if="item.ud_doc_data != undefined" :open="true">
            <div class="info" v-for="(section, i) in item.ud_doc_data.sections" :key="i">
                <span class="section_title" v-if="section.title != ''" v-html="highlight_ud(section.title)"></span>
                <div v-for="(article, j) in section.articles" :key="j">
                    <span class="article_title" v-if="article.title != ''" v-html="highlight_ud(article.title)"></span>
                    <div v-for="(paragraph, k) in article.paragraphs" :key="k">
                        <pre v-if="paragraph.tag == 'p'" v-html="highlight_ud(paragraph.text)"></pre>
                        <div class="tableWrap" v-if="paragraph.tag == 'table'">
                            <table v-html="paragraph.text" class="nb_basic"></table>
                        </div>
                    </div>
                </div>
            </div>
            <a :href="item.ud_doc_id" target="_blank" v-if="item.ud_doc_id != undefined">다운로드
                <v-icon>mdi-download</v-icon></a>
        </ContentBox>

        <ContentBox title="사용상의 주의사항(data indexing)" class="blue"
            v-if="item.indexed_doc != undefined && show_origin == false" :open="true">
            <NBTextBox3 v-for="(section, i) in item.indexed_doc.sections" :section="section" :key="i"
                :keyword_highlight="keyword_highlight" />
            <a :href="item.nb_doc_id" target="_blank" v-if="item.nb_doc_id != undefined">주의사항 다운로드
                <v-icon>mdi-download</v-icon></a>
            <a :href="item.pn_doc_id" target="_blank" v-if="item.pn_doc_id != undefined">전문가 주의사항 다운로드
                <v-icon>mdi-download</v-icon></a>

            <a @click="show_origin = true">원문보기</a>
        </ContentBox>

        <ContentBox title="사용상의 주의사항(원문)" class="blue"
            v-if="item.nb_doc_data != undefined && (item.indexed_doc == undefined || show_origin)" :open="true">
            <div v-for="(section, si) in item.nb_doc_data.sections" :key="si">
                <NBTextBox1 v-for="(nb, i) in section.articles" :key="i" :nb="nb"
                    :keyword_highlight="keyword_highlight"></NBTextBox1>
            </div>
            <div v-if="item.pn_doc_data != undefined">
                <div v-for="(section, si) in item.pn_doc_data.sections" :key="si">
                    <NBTextBox1 v-for="(nb, i) in section.articles" :key="i" :nb="nb"
                        :keyword_highlight="keyword_highlight"></NBTextBox1>
                </div>

            </div>
            <a :href="item.nb_doc_id" target="_blank" v-if="item.nb_doc_id != undefined">주의사항 다운로드
                <v-icon>mdi-download</v-icon></a>
            <a :href="item.pn_doc_id" target="_blank" v-if="item.pn_doc_id != undefined">전문가 주의사항 다운로드
                <v-icon>mdi-download</v-icon></a>

            <a @click="show_origin = false" v-if="item.indexed_doc != undefined">data indexing보기</a>
        </ContentBox>
    </div>
</template>
<script>
import ContentBox from './ContentBox.vue'
import NBTextBox1 from './NBTextBox.vue'
import NBTextBox3 from './NBTextBox3.vue'

import { use_ud, use_regex } from '@/assets/js/highlight';
import storage from '@/assets/js/storage.js'

export default {
    name: 'DetailComp',
    components: {
        ContentBox,
        NBTextBox1,
        NBTextBox3
    },
    props: {
        item: {
            type: Object,
            default: () => ({
                shape: {}
            })
        },
        user: {
            type: Object,
            default: () => ({})
        }
    },
    data: () => ({
        list: [],
        nb_list: [],
        panel: [0],
        nb_document: [],
        font_size: 1,
        indexed_doc: {},
        keyword_highlight: 0,
        show_origin: false
    }),
    computed: {
        fontClass() {
            var obj = {};
            obj['dt_' + this.$root.setting.font_size] = true
            return obj
        },
        is_marked() {
            if (this.keyword_highlight == 1) return true;
            return false;
        },
    },
    mounted() {
        this.keyword_highlight = storage.keyword_highlight();
        
        var list1 = [];

        list1.push({ title: '영문명', text: this.item.item_name_eng })
        list1.push({ title: '성상', text: this.item.chart })
        if (this.item.shape != null) list1.push({ title: '모양', text: this.item.shape.drug_shape })
        list1.push({ title: '전문/일반', text: this.item.etc_otc_code })
        list1.push({ title: '저장방법', text: this.item.storage_method })
        list1.push({ title: '사용기간', text: this.item.valid_term })
        //list1.push({ title: '유효기한', text: this.item.expiration_date })

        var list2 = [];
        list2.push({ title: '위탁제조업체', text: this.item.cnsgn_manuf })

        list2.push({ title: '허가일', text: this.item.item_permit_date })

        if(this.item.cancel_date != '') {
            list2.push({ title: this.item.cancel_name, text: this.item.cancel_date })
        }

        list2.push({ title: '품목기준코드', text: this.item.item_seq })
        list2.push({ title: '표준코드', text: this.item.bar_code })
        list2.push({ title: 'atc코드', text: this.item.atc_code })
        list2.push({ title: '포장정보', text: this.item.pack_unit })
        list2.push({ title: '첨부문서', text: this.item.insert_file, type: 'file' })

        var _list1 = [];
        var _list2 = [];

        list1.forEach(item => {
            if (item.text != undefined && item.text != '' && item.text != null) { 
                if(item.text.replace != undefined) item.text = item.text.replace(/&#13;/g, '');
                _list1.push(item); }
        })

        list2.forEach(item => {
            if (item.text != undefined && item.text != '' && item.text != null) { 
                if(item.text.replace != undefined) item.text = item.text.replace(/&#13;/g, '');
                _list2.push(item); 
            }
        })

        if (_list1.length > 0) {
            this.list.push({
                title: "기본정보",
                list: _list1,
                open: true
            })
        }

        if (_list2.length > 0) {
            this.list.push({
                title: "기타 기본정보",
                list: _list2,
                open: true
            })
        }

        this.nb_list.push({
            title: '경고',
            text: '주의사항 indexing 적용 후 재구성 예정 고지질혈증 고혈압 당뇨'
        })

        this.nb_list.push({
            title: '금기',
            text: '주의사항 indexing 적용 후 재구성 예정 고지질혈증 고혈압 당뇨'
        })

        this.nb_list.forEach(item => {
            item.html = this.highlight_regex(item.text)
        })

        //this.nb_document = ng.make_nb(this.item);


        if (this.item.indexed_doc != undefined) {
            this.indexed_doc = this.item.indexed_doc;

            this.indexed_doc.sections.forEach(section => {
                if (section.groups != undefined) {
                    section.groups.forEach(group => {

                        var ins_indx = 0;
                        group.articles.forEach((article, i) => {
                            if (article.paragraphs == 0) {
                                group.articles[ins_indx].paragraphs.push({
                                    text: article.title,
                                    tag: 'p'
                                })
                                article.title = '';
                                article.title2 = '';
                            } else {
                                ins_indx = i;
                            }
                        })

                        group.articles = group.articles.filter(x => {
                            return x.paragraphs.length > 0;
                        });

                        if (group.articles.length == 1) {
                            group.articles[0].expanded = true;
                            group.articles[0].no_exp = true;
                            group.articles[0].title2 = '';
                            /*
                            if (this.clean(group.title) == this.clean(group.articles[0].title)) {
                                group.articles[0].title2 = '';
                            }*/
                        } else {
                            group.expanded = true;
                        }
                    })
                }
            });
        }


    },
    methods: {
        clean(txt) {
            var arr = [
                /['"‘]/g,
                /<!--[\s\S]*?-->/g,
                /^\(?[0-9]*\.[0-9]?\)?\s*/,
                /^\(?주[0-9]\.[0-9]?\.?\)?\s*/,
                /&#x[a-fA-F0-9][a-fA-F0-9][a-fA-F0-9][a-fA-F0-9];/g,
                /[\r\n]+/g,
                /[.]/,
            ];

            try {
                arr.forEach((reg) => {
                    txt = txt.replace(reg, "");
                });
            } catch (e) { console.error(e) }
            txt = txt.trim();
            return txt;
        },
        clean_ingr: function (name) {
            if (name == undefined) return "";
            if (name.indexOf("]") != -1) {
                name = name.substring(name.indexOf("]") + 1);
            }
            if (name.indexOf("(") != -1) {
                name = name.substring(0, name.indexOf("("));
            }
            return name;
        },
        comp_ingr: function (arr) {
            if (arr == null) return;
            arr.forEach((name, indx) => {
                arr[indx] = this.clean_ingr(name);
            });
            var name = arr.join(", ");
            return name;
        },
        highlight_ud(val) {
            if (this.keyword_highlight == 0) return val;
            return use_ud(val)
        },
        highlight_regex(val) {
            if (this.keyword_highlight == 0) return val;
            return use_regex(val);
        },
        keyword_highlight_toggle() {
            this.keyword_highlight = this.keyword_highlight == 1 ? '0' : '1';
            storage.keyword_highlight(this.keyword_highlight);
        },
        material_name(txt) {
            var arr = txt.split('|');
            var arr2 = [];
            arr.forEach(k => {
                var arr3 = k.split(':');
                arr2.push({ l: arr3[0].trim(), v: arr3[1].trim() })
            })
            return arr2
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/_variables.scss";
@import "@/assets/css/_mixins.scss";

.DetailComp {
    padding: 10px 3px;
    font-size: inherit;

    .info {
        font-size: inherit;
    }

    .table.info_a {
        font-size: inherit;
    }
}


.blue span,
.blue pre {
    /*color: #0F46BE;*/
    font-size: 14px;
}


span.atc_ee {
    display: block;
    font-weight: 700;
    font-size: 1.1em;
    margin-bottom: 10px;
}

a {
    display: inline-block;
    padding: 3px 20px;
    border: 1px solid $b-3;
    margin-top: 10px;
    color: $b-3;
    font-size: 0.85em;
}

.ContentBox {
    margin-bottom: 20px !important;
    font-size: 1em;
}

.ContentBox:last-child {
    margin-bottom: 0px !important;
}

.ContentBox p {
    word-break: break-all;
    word-wrap: break-word;
}

.ContentBox:deep(pre) {
    font-size: 1em !important;
}

.article_title {
    font-size: 1.2em;
}

.tableWrap {
    width: 100%;
    overflow-x: auto
}

table.nb_basic {
    min-width: 700px;
    @include nb_basic;
}

table.info_a {
    @include nb_basic;

    th {
        text-align: center;
        min-width: 100px;
        font-size: 1em;
    }

    p {
        font-size: 1em;
    }

    td {
        border-right: none !important;
    }
}

.arr_ingr {
    margin-bottom: 10px;
}

.arr_ingr:last-child {
    margin-bottom: 0px;
}

.marker {
    position: fixed;
    bottom: 10px;
    right: 10px;
    color: $b-g-3;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    padding: 5px;
    z-index: 10;
    background-color: $b-3;
    border: 2px solid $b-3;
}

.marker.on {
    color: #ffe657;
}
</style>