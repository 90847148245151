<template>
    <div class="TopTitleCompWrap">
        <div class="wrap">
            <div class="Top">
                <p>
                    <span>{{ $filters.formatDate(item.p_date) }}</span>
                    <span> 처방된 처방 이력입니다.</span>
                </p>
            </div>
            <hr class="hr" />
            <div class="Bottom">
                <template v-if="$root.setting.width > 400">
                    <p>
                        <span v-if="item.hospital != ''">{{ item.hospital }}</span>
                        <span v-if="item.hospital != ''">|</span>
                        <span v-if="item.pharm != ''">{{ item.pharm }}</span>
                    </p>
                </template>
                <template v-else>
                    <p v-if="item.hospital != ''">
                        <span>{{ item.hospital }}</span>
                    </p>
                    <p v-if="item.pharm != ''">
                        <span>{{ item.pharm }}</span>
                    </p>
                </template>
                <p>
                    <span>총 {{ item.num_of_drug }}종</span>
                    <span>|</span>
                    <span>총 {{ item.days }}일 약 처방</span>
                </p>
                <p v-if="type == 'general'">
                    <FolderInfo :item="item" :show_all="true"/>
                </p>
            </div>
        </div>
    </div>
</template>
     
<script>
import FolderInfo from '@/components/drug/FolderInfo.vue'

export default {
    name: 'TopTitleComp',
    components: {
        FolderInfo
    },
    props: {
        item: {
            type: Object,
            default: () => ({})
        },
        type: {
            type: String,
            default: 'general'
        }
    },
    data: () => ({
        topText1: '',
    }),
    mounted() {
    }
}
</script>
     
<style lang="scss" scoped>
@import "@/assets/css/_variables.scss";
@import "@/assets/css/_mixins.scss";

.TopTitleCompWrap {
    padding: 0;
}

@media (min-width: 300px) {
    .TopTitleCompWrap {
        padding: 0 20px;
    }
}

.wrap {
    padding: 10px 0;
}

.hr {
    @include hr2;
    margin: 4px 0;
}

.Top p span {
    @include H4;
    color: $b-8;
}

.Bottom p span {
    @include H6;
    color: $b-g-5;
}

.Bottom p span:nth-child(2),
.Bottom p span:nth-child(4),
.Bottom p span:nth-child(6) {
    padding: 0 4px;
}

:deep(span.folder) {
    @include H6;
    color: $b-g-5;
}
</style>