const search = () => import("@/views/drug/SearchView.vue");
const view = () => import("@/views/drug/DrugView.vue");

import Layout from "../layouts/LayoutBasic.vue";

export default [
  {
    path: "/drug/search",
    name: "drugSearch",
    component: search,
    meta: { layout: Layout, title: "의약품 검색" },
  },
  {
    path: "/drug/view",
    name: "drugView",
    component: view,
    meta: { layout: Layout, title: "의약품 상세" },
  },
];
