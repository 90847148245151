<template>
    <div class="m_wrapper">
        <Header></Header>
        <div>
            <div class="TheView">
                <slot />
            </div>
        </div>
    </div>
</template>
<script>
import Header from './Header.vue';

export default {
    name: 'layoutBasic',
    components: {
        Header,
    },
}
</script>
<style lang="scss" scoped>
@import "@/assets/css/_variables.scss";
@import "@/assets/css/_mixins.scss";

.m_wrapper {
    display: flex;
    flex-direction: column;
    height:100vh;
    height: 100dvh;
}

.m_wrapper > div:last-child {
    flex-grow: 1;
    overflow-y: auto;
}

.TheView {
    padding: 20px 8px calc(20px + env(safe-area-inset-bottom)) 8px;
    max-width: 800px;
    margin: 0px auto;
}

@media (min-width: 300px) {
    .TheView {
        padding: 20px 18px calc(20px + env(safe-area-inset-bottom)) 18px;
    }
}
</style>