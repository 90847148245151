const bookmark = () => import("@/views/my/drug/BookmarkView.vue");
const history = () => import("@/views/my/drug/HistoryView.vue");
const main = () => import("@/views/my/drug/MainView.vue");

const prescription = () => import("@/views/my/drug/PrescriptionView.vue");
const takes = () => import("@/views/my/drug/TakesView.vue");
const memo = () => import("@/views/my/drug/MemoList.vue");

import Layout from "../layouts/LayoutBasic.vue";

export default [
  {
    path: "/my/drug",
    name: "myDrug",
    component: main,
    meta: { layout: Layout, title: "내 약 관리" },
  },
  {
    path: "/my/drug/history",
    name: "myDrugHistory",
    component: history,
    meta: { layout: Layout, title: "의약품 검색기록" },
  },
  {
    path: "/my/drug/bookmark",
    name: "myDrugBookmark",
    component: bookmark,
    meta: { layout: Layout, title: "관심 의약품" },
  },
  {
    path: "/my/drug/prescription",
    name: "myDrugPrescription",
    component: prescription,
    meta: { layout: Layout, title: "처방이력" },
  },
  {
    path: "/my/drug/takes",
    name: "myDrugTakes",
    component: takes,
    meta: { layout: Layout, title: "복용의약품" },
  },
  {
    path: "/my/drug/memo",
    name: "myDrugMemo",
    component: memo,
    meta: { layout: Layout, title: "의약품메모" },
  },
];
