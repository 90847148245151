<template>
    <div class="ImageComp">
        <div class="wrap" v-if="charts.length == 0 && item.item_image != undefined">
            <img :src="$item_image_rewrite(item.item_image)" @click="click_image(item.item_image)"/>
        </div>
        <div class="wrap" v-if="charts.length > 0">
            <img v-for="(chart, i) in charts" :key="i" :src="$item_image_rewrite(chart.item_image)" @click="click_image(chart.item_image)"/>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ImageComp',
    components: {

    },
    props: {
        charts: {
            type: Array,
            default: () => { [] }
        },
        item: {
            type: Object,
            default: () => ({})
        },
        user: {
            type: Object,
            default: () => ({})
        }
    },
    data: () => ({

    }),
    mounted() {

    },
    methods: {
        click_image(val) {
            this.$emit('click_image', val)
        }
    }
}
</script>
<style lang="scss" scoped>
@import "@/assets/css/_variables.scss";
@import "@/assets/css/_mixins.scss";


.ImageComp {
    width:100%;
    overflow-y:auto;
}

.wrap{
  @include start;
  padding: 5px 0;
  gap: 10px;
  width: fit-content;
    margin: 0px auto;
}

.wrap img {
    height:100px;
    cursor:pointer;
}
</style>