import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage, deleteToken } from "firebase/messaging";

export default {
    install: (app) => {
        const firebaseConfig = {
            apiKey: "AIzaSyDMDICvgN3rEH_5Ku-eInhfHljmy5SqBSA",
            authDomain: "edrug-safe.firebaseapp.com",
            projectId: "edrug-safe",
            storageBucket: "edrug-safe.appspot.com",
            messagingSenderId: "428863795041",
            appId: "1:428863795041:web:8ae87af6da100236ce9f20",
        };

        initializeApp(firebaseConfig);

        const messaging = getMessaging();

        app.config.globalProperties.$setPush = () => {
            return new Promise((resolve, reject) => {
                getToken(messaging, {
                    vapidKey:
                        "BCWWQ-4am4uTSpDSU-WzvC4JOfbbnIX9kPTNAbhTpJ3zc_IBABQt8uPkWgTOASJHTwx00fF3oqaAvFCgv3k3Uuw",
                })
                    .then((currentToken) => {
                        if (currentToken) {
                            resolve(currentToken);
                        } else {
                            resolve(null);
                        }
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        };

        app.config.globalProperties.$removePush = () => {
            deleteToken(messaging);
        };

        onMessage(messaging, (payload) => {
            if (/Android/i.test(window.navigator.userAgent)) {
                if (payload.data.action_url != undefined) {
                    app.config.globalProperties
                        .$confirm({
                            title: payload.data.title,
                            text: payload.data.body,
                            confirmButtonText: "확인",
                            cancelButtonText: "닫기",
                        })
                        .then((rs) => {
                            if (rs.isConfirmed) {
                                document.location.href = payload.data.action_url;
                            }
                        });
                } else {
                    app.config.globalProperties.$alert({
                        title: payload.data.title,
                        text: payload.data.body,
                    });
                }
            } else {
                var title = "Hello World";
                var options = {
                    icon: "https://www.edrugsafe.org/img/icon/apple-icon.png",
                    badge: "https://www.edrugsafe.org/img/icon/apple-badge.png",
                    body: "하하하하",
                    data: {},
                    sound: "default",
                };

                var url = 'https://www.edrugsafe.org';

                if (payload.data != undefined) {
                    if (payload.data.title != undefined) title = payload.data.title;
                    if (payload.data.body != undefined) options.body = payload.data.body;
                    if (payload.data.action_url != undefined) {
                        url = payload.data.action_url;
                        options.data.url = url;
                    }
                }

                var notification = new Notification(title, options);
                notification.onclick = function (event) {
                    if (payload.data.action_url != undefined)
                        url = payload.data.action_url;
                    event.preventDefault();
                    window.open(url, "_blank");
                    notification.close();
                };
            }
        });
    },
};
