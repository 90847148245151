<template>
    <div class="EasyComp">
        <div class="marker" :class="{ 'on': is_marked }" @click="keyword_highlight_toggle"><v-icon
                size="24">mdi-marker</v-icon></div>
        <v-expansion-panels v-for="(easy, i) in list" :key="i" v-model="panel[i]">
            <v-expansion-panel>
                <v-expansion-panel-title>
                    <div class="TitleWrap">
                        <span>
                            <img class="img" :src="require('@/assets/images/common/text_to_speech.svg')" alt="speech"
                                @click.stop="speech(easy)">
                        </span>
                        <span class="mainTitle">{{ easy.title }}</span>
                        <span class="hr" />
                    </div>
                    <template v-slot:actions="{ expanded }">
                        <svg-icon type="mdi" :path="expanded ? up : down"></svg-icon>
                    </template>
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                    <div class="content" v-html="easy.html"></div>
                </v-expansion-panel-text>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>
<script>
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiUnfoldMoreHorizontal } from '@mdi/js';
import { mdiUnfoldLessHorizontal } from '@mdi/js';
import { use_ud, use_regex } from '@/assets/js/highlight';

import storage from '@/assets/js/storage.js'

export default {
    name: 'EasyComp',
    components: {
        SvgIcon
    },
    props: {
        easy: {
            type: Object,
            default: () => { }
        },
    },
    data: () => ({
        up: mdiUnfoldLessHorizontal,
        down: mdiUnfoldMoreHorizontal,
        panel: [],
        list: [],
        keyword_highlight: 0,
    }),
    computed: {
        is_marked() {
            if (this.keyword_highlight == 1) return true;
            return false;
        },
    },
    mounted() {
        this.keyword_highlight = storage.keyword_highlight();

        var list = [];

        list.push({ title: '이 약의 효능은 무엇입니까?', text: this.easy.ee_doc })
        list.push({ title: '이 약은 어떻게 사용합니까?', text: this.easy.ud_doc })
        list.push({ title: '이 약을 사용하기 전에 반드시 알아야 할 내용은 무엇입니가?', text: this.easy.nb_1 })
        list.push({ title: '이 약의 사용상 주의사항은 무엇입니까?', text: this.easy.nb_2 })
        list.push({ title: '이 약을 사용하는 동안 주의해야 할 약 또는 음식은 무엇입니까?', text: this.easy.nb_3 })
        list.push({ title: '이 약은 어떤 이상반응이 나타날 수 있습니까?', text: this.easy.nb_1_4 })
        list.push({ title: '이 약은 어떻게 보관해야 합니까?', text: this.easy.nb_5 })

        list.forEach((item) => {
            if (item.text != undefined && item.text != '') {
                this.panel.push(0)
                this.list.push(item);
            }
        });
        this.load();
    },
    methods: {
        keyword_highlight_toggle() {
            this.keyword_highlight = this.keyword_highlight == 1 ? '0' : '1';
            storage.keyword_highlight(this.keyword_highlight);
            this.load();
        },
        load() {
            this.list.forEach((item, i) => {
                if (item.text != undefined && item.text != '') {
                    if (this.keyword_highlight == 1) {
                        if (i == 1) {
                            item.html = use_ud(item.text)
                        } else if (i == 6) {
                            item.html = item.text
                        } else {
                            item.html = use_regex(item.text)
                        }
                    } else {
                        item.html = item.text;
                    }
                }
            });
        },
        speech(easy) {
            this.$root.speech(easy.text);
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/_variables.scss";
@import "@/assets/css/_mixins.scss";

.EasyComp {
    padding: 10px 3px;
    @include panel_blue;
    padding-bottom: 10px;
}

.EasyComp>div {
    padding-bottom: 20px;
}

.EasyComp>div:last-child {
    padding-bottom: 0px;
}

.TitleWrap {
    @include between;
    width: 100%;
}

.TitleWrap>span {
    text-align: left;
    color: $b-g-7;
    font-size: 1em;
    font-weight: $fw-9;
}

@media (min-width: 300px) {
    .TitleWrap span {
        font-size: 1.15em;
    }
}

.mainTitle {
    padding-left: 10px;
}

.mainTitle {
    flex-grow: 1;
}

.hr {
    flex-grow: 8;
    @include hr-line;
}

.TitleWrap:deep(.v-btn) {
    min-width: 24px;
    width: 24px;
    min-height: 24px;
    height: 24px;
}

.content {
    /*@include H5;*/

    font-style: normal;
    font-weight: 400;
    line-height: 170%;
    /* 23.8px */
    color: $b-g-7;
    margin-bottom: 10px;
}

.marker {
    position: fixed;
    bottom: 10px;
    right: 10px;
    color: $b-g-3;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    padding: 5px;
    z-index: 10;
    background-color: $b-3;
    border: 2px solid $b-3;
}

.marker.on {
    color: #ffe657;
}
</style>
