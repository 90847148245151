const dose = () => import("@/views/my/DoseView.vue");

import Layout from "../layouts/LayoutBasic.vue";

export default [
    {
      path: "/my/dose",
      name: "myDose",
      component: dose,
      meta: { layout: Layout, title: "복용알림" },
    },
  ];