import { v4 as uuidv4 } from 'uuid';
import moment from 'moment'

export default {
    clear() {
        var setting = this.setting();
        var db = localStorage.getItem('db');
        
        localStorage.clear();

        this.setting(setting);
        if(db != undefined) {
            localStorage.setItem("db", db);
        }
        //if(return_url != undefined) this.return_url(return_url);
    },
    logout() {
        if (this.save_user_id()) {
            var user_id = this.user_id();
            this.clear();
            this.save_user_id("Y");
            this.user_id(user_id);
        } else {
            this.clear();
        }
    },
    return_url(return_url) {
        if (return_url == undefined) {
            return localStorage.getItem('return_url');
        } else {
            localStorage.setItem('return_url', return_url);
        }
    },
    session_id: {
        hide() {
            localStorage.setItem('on_hide_time', moment().format("YYYY-MM-DD HH:mm:ss"));
        },
        show() {
            if (localStorage.getItem('on_hide_time') != null) {
                var diff = moment() - moment(localStorage.getItem('on_hide_time'));

                if (diff > 1 * 60 * 1000) {
                    this.revoke();
                }
            }
        },
        get() {
            var uuid = '';
            if (localStorage.getItem('session_id') == null) {
                uuid = uuidv4();
                uuid = uuid.replace(/-/g, '');
                localStorage.setItem('session_id', uuid);
            } else {
                uuid = localStorage.getItem('session_id');
            }
            return uuid;
        },
        revoke() {
            localStorage.removeItem('session_id');
        }
    },
    session_key(key) {
        if (key == undefined) {
            return localStorage.getItem('psk');
        } else {
            localStorage.setItem('psk', key);
        }
    },
    token(token) {
        if (token == undefined) {
            return localStorage.getItem('token');
        } else {
            localStorage.setItem('token', token);
        }
    },
    user(user) {
        if (user == undefined) {
            if (localStorage.getItem('user') == undefined) return null;
            return JSON.parse(localStorage.getItem('user'));
        } else {
            var keys = ["email", "name", "user_id", "user_id_type", "type", "company_name"];
            var _user = {};
            keys.forEach(k => {
                _user[k] = user[k];
            });
            localStorage.setItem('user', JSON.stringify(_user));
            this.session_key(user.session_key);
            this.user_id(user.login_id);
        }
    },
    is_login() {
        if (localStorage.getItem('user') == undefined) return false;
        return true;
    },
    user_keyword(keyword) {
        if (keyword == undefined) {
            if (localStorage.getItem('keyword') == undefined) return null;
            return JSON.parse(localStorage.getItem('keyword'));
        } else {
            localStorage.setItem('keyword', JSON.stringify(keyword));
        }
    },
    keyword_highlight(on) {
        if (on == undefined) {
            if (localStorage.getItem('keyword_highlight') == undefined) return '1';
            return localStorage.getItem('keyword_highlight') + '';
        } else {
            localStorage.setItem('keyword_highlight', on);
        }
    },
    setting(setting) {
        if (setting == undefined) {
            if (localStorage.getItem('setting') == undefined) {
                return {};
            } else {
                return JSON.parse(localStorage.getItem('setting'));
            }
        } else {
            localStorage.setItem('setting', JSON.stringify(setting));
        }
    },
    setting_key(id, value) {
        if (value == undefined) {
            var setting0 = this.setting();
            return setting0[id];
        } else {
            var setting = this.setting();
            setting[id] = value;
            this.setting(setting);
        }
    },
    setting_key_remove(id) {
        var setting0 = this.setting();
        delete setting0[id];
        this.setting(setting0);
    },
    cameraId(cameraId) {
        if (cameraId == undefined) {
            return this.setting_key('cameraId');
        } else {
            this.setting_key('cameraId', cameraId);
        }
    },
    cameraLabel(cameraLabel) {
        if (cameraLabel == undefined) {
            return this.setting_key('cameraLabel');
        } else {
            this.setting_key('cameraLabel', cameraLabel);
        }
    },
    cameraTorch(cameraTorch) {
        if (cameraTorch == undefined) {
            return this.setting_key('cameraTorch') == '1' ? true : false;
        } else {
            this.setting_key('cameraTorch', cameraTorch == true ? '1' : '0');
        }
    },
    cameraZoom(cameraZoom) {
        if (cameraZoom == undefined) {
            return this.setting_key('cameraZoom');
        } else {
            this.setting_key('cameraZoom', cameraZoom);
        }
    },
    qrSize(qrSize) {
        if (qrSize == undefined) {
            return this.setting_key('qrSize');
        } else {
            this.setting_key('qrSize', qrSize);
        }
    },
    fontSize(size) {
        if (size == undefined) {
            var de = this.setting_key('font_size') == undefined ? 1 : this.setting_key('font_size');
            return de;
        } else {
            this.setting_key('font_size', size);
        }
    },
    save_user_id(save_user_id) {
        if (save_user_id == undefined) {
            return this.setting_key('save_user_id') == 'Y' ? true : false;
        } else {
            this.setting_key('save_user_id', save_user_id);
        }
    },
    user_id(user_id) {
        if (user_id == undefined) {
            return this.setting_key('user_id');
        } else {
            this.setting_key('user_id', user_id);
        }
    },
    route_id(route_id) {
        if (route_id == undefined) {
            return this.setting_key('route_id');
        } else {
            this.setting_key('route_id', route_id);
        }
    },
    suid(suid) {
        if (suid == undefined) {
            return localStorage.getItem('suid');
        } else {
            localStorage.setItem('suid', suid);
        }
    },
    side_menu(menu) {
        var user = this.user();

        if(user == null) {
            return null;
        }

        var key = user.user_id;

        var db = {};
        db[key] = {};
        if(localStorage.getItem("db") != undefined) {
            db = JSON.parse(localStorage.getItem("db"));
            if(db[key] == undefined) db[key] = {};
        }

        if(menu == undefined) {
            if(db[key].side_menu == undefined) return null;
            else return JSON.parse(db[key].side_menu);
        } else {
            db[key].side_menu = JSON.stringify(menu);
            localStorage.setItem("db", JSON.stringify(db));
        }
    },
    useAPI(use_api) {
        if (use_api == undefined) {
            return this.setting_key('use_api') == 'Y' ? true : false;
        } else {
            if(use_api) this.setting_key('use_api', 'Y');
            else this.setting_key('use_api', 'N');
        }
    },
    useFinal(use_final) {
        if (use_final == undefined) {
            if(this.setting_key('use_final') == undefined) {
                if(/Android/i.test(navigator.userAgent)) {
                    return true;
                } else {
                    return false;
                }
            } 
            return this.setting_key('use_final') == 'Y' ? true : false;
        } else {
            if(use_final) this.setting_key('use_final', 'Y');
            else this.setting_key('use_final', 'N');
        }
    },
    useGrammar(use_grammar) {
        if (use_grammar == undefined) {
            return this.setting_key('use_grammar') == 'Y' ? true : false;
        } else {
            if(use_grammar) this.setting_key('use_grammar', 'Y');
            else this.setting_key('use_grammar', 'N');
        }
    }
};