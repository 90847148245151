<template>
    <div class="NBTextBox3">
        <div class="sectionTitle" v-if="section.title != ''">{{ section.title }}</div>
        <template v-if="section.groups != undefined">
            <div v-for="(group, i) in section.groups" :key="i" class="group">
                <div class="head">
                    <div class="title" v-html="group.title"></div>
                    <div class="hr"></div>
                    <div class="right">
                        <v-icon class='icon' @click="group.expanded = !group.expanded"
                            :icon="group.expanded ? 'mdi-unfold-less-horizontal' : 'mdi-unfold-more-horizontal'"></v-icon>
                    </div>
                </div>
                <div class="content" v-if="group.expanded">
                    <div v-for="(article, j) in group.articles" :key="j" class="article">
                        <template v-if="article.paragraphs.length > 0">
                            <div class="head" v-if="article.title2 != ''">
                                <div class="title" v-html="article.title2 "></div>
                                <div class="hr" v-if="!article.no_exp"></div>
                                <div class="right" v-if="!article.no_exp">
                                    <v-icon class='icon' @click="article.expanded = !article.expanded"
                                        :icon="article.expanded ? 'mdi-unfold-less-horizontal' : 'mdi-unfold-more-horizontal'"></v-icon>
                                </div>
                            </div>
                            <div v-for="(paragraph, k) in article.paragraphs" :key="k">
                                <div v-if="article.expanded">
                                    <pre v-if="paragraph.tag == 'p'" v-html="highlight_regex(paragraph.text, group.id)"></pre>
                                    <div class="tableWrap" v-if="paragraph.tag == 'table'">
                                        <table v-html="paragraph.text" class="nb_basic"></table>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template v-if="article.paragraphs.length == 0">
                            <pre v-html="article.title"></pre>
                        </template>
                    </div>
                </div>
            </div>
        </template>
        <template v-if="section.articles != undefined">
            <div v-for="(article, j) in section.articles" :key="j" class="group">
                <div class="head">
                    <div class="title" v-html="article.title"></div>
                    <div class="hr"></div>
                    <div class="right">
                        <v-icon class='icon' @click="article.expanded = !article.expanded"
                            :icon="article.expanded ? 'mdi-unfold-less-horizontal' : 'mdi-unfold-more-horizontal'"></v-icon>
                    </div>
                </div>
                <div v-for="(paragraph, k) in article.paragraphs" :key="k">
                    <div v-if="article.expanded">
                        <pre v-if="paragraph.tag == 'p'" v-html="highlight_regex(paragraph.text)"></pre>
                        <div class="tableWrap" v-if="paragraph.tag == 'table'">
                            <table v-html="paragraph.text" class="nb_basic"></table>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>
<script>
import { use_regex } from '@/assets/js/highlight';

export default {
    name: 'NBTextBox3',
    components: {},
    props: {
        section: {
            type: Object,
            default: () => ({})
        },
        keyword_highlight:{
            type:String,
            default:'0'
        }
    },
    computed: {},
    data: () => ({}),
    mounted() {
    },
    methods: {
        highlight_regex(val, group_id) {
            var a = {
                '02':true,
                '03':true,
                '07':true
            }
            if(this.keyword_highlight == 0) return val;
            if(a[group_id]) return use_regex(val);
            return val;
        }
    },
    watch: {}
}
</script>
<style lang="scss" scoped>
@import "@/assets/css/_variables.scss";
@import "@/assets/css/_mixins.scss";

.head {
    display: flex;

    .title {
        width: fit-content;
        padding-right: 10px;
    }

    .hr {
        flex-grow: 1;
        display: block;
        height: 1px;
        border: 0;
        border-top: 1px solid #CBD5E1;
        margin: 1em 0;
        padding: 0;
    }

    .right {
        min-width: 34px;
        max-width: 34px;
        padding-left: 10px;

        .v-icon {
            color: #1E40AF;
        }
    }
}

.sectionTitle {
    font-size: 18px;
    font-weight: 700;
    background-color: $b-g-2;
    text-align: left;
    padding: 5px;
    margin-bottom: 10px;
    border-radius: 8px 8px 0px 0px;
}

.group {
    margin-bottom: 20px;


    .content {
        border-bottom: 1px solid $b-g-2;
    }
}

.group>.head>.title {
    font-size: 16px;
    font-weight: 700;
    padding-top: 2px;
}

.article {
    padding: 10px 0px 10px 10px;

    pre {
        margin-bottom: 5px;
    }
}

.article>.head>.title {
    font-size: 15px;
    font-weight: 500;
    line-height: 32px;
}


.tableWrap {
    width: 100%;
    overflow-x: auto;
    margin: 5px 0px;
}

table.nb_basic {
    min-width: 700px;
    @include nb_basic;

    th,
    th {
        font-size: 14px;
    }

    p {
        font-size: 14px;
    }
}

table.nb_basic:deep(th),
table.nb_basic:deep(td),
table.nb_basic:deep(p) {
    font-size: 14px;
}
</style>