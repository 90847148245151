import moment from 'moment';

export default {
    formatDate:(val) => {
        if (val == undefined || val == null || val == "") return "";
        if (val.length == "6")
            return moment("20" + String(val)).format("YYYY년 MM월 DD일");
        return moment(String(val)).format("YYYY년 MM월 DD일");
    },
    formatDate2:(val) => {
        if (val == undefined || val == null || val == "") return "";
        if (val.length == "6")
            return moment("20" + String(val)).format("YYYY.MM.DD");
        return moment(String(val)).format("YYYY.MM.DD");
    },
    formatDateTime:(val) => {
        if (val == undefined || val == null || val == "") return "";
        if (val.length == "6")
            return moment("20" + String(val)).format("YYYY년 MM월 DD일");
        return moment(String(val)).format("YYYY년 MM월 DD일 HH시mm분");
    },
    formatNumber:(val) => {
        return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
};