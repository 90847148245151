<template>
    <v-btn class="btn" block>
          <template v-slot:prepend>
            <img :src="button.src" :alt="button.alt">
          </template>
          {{button.text}}</v-btn>
</template>
<script>
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'ImageButton',
  props: {
        button: {
            type: Object,
        }
    }
});
</script>