<template>
    <div class="PrescriptionDetail">
        <PrescriptionTop :item="item" />

        <ContentBox :title="titleDrugs" class="blue" :open="true">
            <TakeCard v-for="(drug, i) in item.drugs" :item="drug" :key="i" @take_on="take_on" @take_off="take_off"
                @memo="memo" @sel="open_drug"/>
        </ContentBox>

        <ContentBox :title="titleHistory" class="blue" :open="true">
            <div v-for="(his, i) in history" :key="i">
                <div class="history" @click="show_history(his)">
                    <div class="head">
                        <span v-if="i == 0">최초등록일시</span>
                        <span v-if="i > 0">수정일시</span>
                        {{ $filters.formatDateTime(his.create_time) }}
                    </div>
                    <div class="content" v-for="(d, j) in his.drugs.filter((x) => x.m != undefined)" :key="j">
                        <div class="item_name">{{ ng.clean_item_name(d.item_name) }} {{ label(d.m.type) }}</div>
                        <div v-for="(m, k) in d.m.db" :key="k">
                            {{ label(m.l) }} | {{ m.p }}{{ label2(m.l) }} → {{ d[m.l] }} {{ label2(m.l) }}
                        </div>
                    </div>
                    <div class="right_b">
                        <v-icon>mdi-chevron-right</v-icon>
                    </div>
                </div>
            </div>
        </ContentBox>
        <div class="footer_button">
            <button @:click="v_move = true" v-if="false">폴더 이동</button>
            <button @:click="v_edit = true">수정</button>
            <button @:click="del">삭제</button>
        </div>

        <DialogComp v-model="v_memo" title="복용의약품 메모" class="no_actions" min-width="300" max-width="600">
            <template v-slot:body>
                <TakeMemo :item_seq="drug.item_seq" :edi_code="drug.edi_code" @close="v_memo = false" />
            </template>
        </DialogComp>

        <DialogComp v-model="v_history" title="처방이력 조회" class="no_actions" min-width="300" max-width="600">
            <template v-slot:body>
                <table class="basic history">
                    <tbody>
                        <tr>
                            <th>의약품명</th>
                            <th>복용법</th>
                        </tr>
                        <template v-for="(drug, i) in selected.history.drugs" :key="i">
                            <tr>
                                <td>{{ ng.clean_item_name(drug.item_name) }}</td>
                                <td>{{ drug.quantity }}(알)
                                    {{ drug.rate }}회
                                    {{ drug.days }}일
                                    {{ drug.usage }}</td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </template>
        </DialogComp>

        <DialogComp v-model="v_move" title="폴더 이동" class="no_actions" min-width="300" max-width="600">
            <template v-slot:body>
                <GuideSpan>이동할 폴더를 선택해주세요.</GuideSpan>
                <FolderList :show_right_buttons="false" :folders="folders" @select_item="move_folder" />
            </template>
        </DialogComp>

        <DialogComp v-model="v_edit" title="처방 이력 수정" class="no_actions" fullscreen>
            <template v-slot:body>
                <TakeForm :type="item.type" :item="item" v-if="item != null" @saved="v_edit = false; load()"/>
            </template>
        </DialogComp>
    </div>
</template>
<script>
import ContentBox from '@/components/drug/ContentBox.vue'
import DialogComp from '@/components/DialogComp.vue'
import FolderList from '@/components/drug/FolderList.vue'
import GuideSpan from '@/components/GuideSpan.vue'
import PrescriptionTop from '@/components/my/drug/PrescriptionTop.vue'
import TakeCard from '@/components/my/drug/TakeCard.vue'
import TakeForm from '@/components/take/TakeForm.vue'
import TakeMemo from '@/components/drug/TakeMemo.vue'

import ng from '@/assets/js/ng'

export default {
    name: 'PrescriptionDetail',
    components: {
        ContentBox,
        DialogComp,
        FolderList,
        GuideSpan,
        PrescriptionTop,
        TakeCard,
        TakeForm,
        TakeMemo
    },
    props: {
        uuid:{
            type:String,
            default:''
        }
    },
    computed: {
        titleDrugs() {
            if (this.item.drugs == undefined) return '처방이력 상세정보';
            return '처방이력 상세정보 | 총' + this.item.drugs.length + '건'
        },
        titleHistory() {
            if (this.history == undefined) return '수정이력';
            return '수정이력 | 총' + (this.history.length) + '건'
        }
    },
    data: () => ({
        ng:ng,
        item: {},
        history: [],
        v_edit: false,
        v_history: false,
        v_memo: false,
        v_move: false,
        drug: {},
        label1: [
            "quantity", "rate", "days", "usage"
        ],
        selected: {
            history: {}
        },
        folders: []
    }),
    mounted() {
        this.load();
        this.$api.my.folder.list().then(response => {
            var folders = response.data.data;
            this.folders = folders;
        });
    },
    methods: {
        del() {
            this.$confirm('처방이력을 삭제할까요?').then(rs => {
                if (rs.isConfirmed) {
                    this.$api.my.prescription.delete({uuid: this.item.uuid}).then(() => {
                        this.$emit('close');
                        this.$emit('refresh');
                    });
                }
            });
        },
        label(key) {
            var db = {
                "days": "총 투여일수",
                "quantity": "1회투여량",
                "rate": "1일투여횟수",
                "usage": "용법",
                "m": "수정",
                "n": "추가"
            }

            if (db[key] == undefined) return '';
            return db[key];
        },
        label2(key) {
            var db = {
                "days": "일",
                "quantity": "",
                "rate": "회",
                "usage": "",
                "m": "",
                "n": ""
            }
            if (db[key] == undefined) return '';
            return db[key];
        },
        load() {
            this.$api.my.prescription.get({ uuid: this.uuid }).then(response => {
                this.item = response.data.data;

                var days = 0;
                this.item.drugs.forEach(d => {
                    if (d.days > days) days = d.days;
                });

                this.item.days = days;

                this.$api.my.prescription.history({ uuid: this.item.uuid }).then(response => {
                    this.history = response.data.data;

                    this.history.forEach((dl, i) => {
                        if (i > 0) {
                            var pre = {};
                            this.history[i - 1].drugs.forEach(d => {
                                pre[d.item_seq] = d;
                            });

                            dl.drugs.forEach(d => {
                                if (pre[d.item_seq] == undefined) {
                                    d.m = { type: 'n' };
                                } else {
                                    var arr_m = [];
                                    var keys = ['quantity', 'rate', 'days', 'usage'];
                                    keys.forEach(key => {
                                        if (d[key] != pre[d.item_seq][key]) {
                                            arr_m.push({ l: key, p: pre[d.item_seq][key] });
                                        }
                                    });
                                    if (arr_m.length > 0) {
                                        d.m = { type: 'm', db: arr_m };
                                    }
                                }
                            });
                        }
                    });
                });
            });
        },
        memo(item) {
            //item.item_seq = String(item.item_seq);
            this.drug = item;
            this.v_memo = true;
        },
        move_folder(folder) {
            this.$confirm('처방이력을 ' + folder.name + '으로 이동할까요?').then(rs => {
                if (rs.isConfirmed) {
                    this.$api.my.prescription.update({}, {}, { mode: 'folder', folder: folder.uuid, uuids: this.item.uuid }).then(() => {
                        this.v_move = false;
                        this.load();
                    });
                }
            });
        },
        open_drug(item) {
            if(item.item_seq == 0) {
                this.$alert('시스템에 등록된 의약품이 아니여서, 의약품 상세조회를 제공하지 않습니다.')
            } else {
                this.$root.open_drug(item);
            }
            
        },
        show_history(history) {
            this.selected.history = history;
            this.v_history = true;
        },
        take_off(item) {
            if (item != null) {
                this.$confirm(item.item_name + '을 미복용으로 설정할까요?').then(rs => {
                    if (rs.isConfirmed) {
                        this.$api.my.drug.takes.medication_off({ uuid: item.uuid }).then(() => {
                            this.load();
                        });
                    }
                });
            }
        },
        take_on(item) {
            if (item != null) {
                this.$confirm(item.item_name + '을 복용중으로 설정할까요?').then(rs => {
                    if (rs.isConfirmed) {
                        this.$api.my.drug.takes.medication_on({ uuid: item.uuid }).then(() => {
                            this.load();
                        });
                    }
                });
            }
        },
    },
    watch: {
        v_memo(val) {
            if (!val) {
                this.load();
            }
        }
    }
}
</script>
<style lang="scss" scoped>
@import "@/assets/css/_variables.scss";
@import "@/assets/css/_mixins.scss";

.PrescriptionDetail>div {
    margin-bottom: 20px;
}

/*
.PrescriptionDetail:deep(.TakeCard .right) {
    min-width: 64px;
}*/

.PrescriptionDetail {
    .TakeCard:deep(.right) {
        min-width: 64px;
    }
}


div.history {
    position: relative;
    @include around;
    display: block;
    text-align: left;
    box-shadow: $shadow;
    padding: 10px;
    border-radius: 8px;
    margin: 5px;

    div {
        display: block;
    }

    .head {
        @include H4;
        color: $b-g-8;
    }

    .content {
        @include H6;
        color: $b-8;
        padding: 5px 0px;

        .item_name {
            font-size: 14px;
        }
    }

    .right_b {
        position: absolute;
        right: 5px;
        top: 0px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $b-g-4;
    }
}

table.history {
    td {
        width: 25%;
        text-align: center;
        line-height: 2rem;
    }

}
</style>