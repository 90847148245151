<template>
    <span class="GuideSpan"><slot></slot></span>
</template>
<script>
export default {
    name:'GuideSpan'
}
</script>
<style lang="scss" scoped>
@import "@/assets/css/_variables.scss";
@import "@/assets/css/_mixins.scss";

.GuideSpan {
    display: block;
    text-align: center;
    padding: 20px 5px;
    font-size: 18px;
    font-weight: 700;
    line-height: 150%;
    color: #1e40af;
    word-break: keep-all;
}

.GuideSpan.t1 {
    padding: 20px 5px;
    font-size: 16px;
}

.GuideSpan.c1 {
    color: #475569;
}
</style>