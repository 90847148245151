<template>
    <div class="TakeMemo">
        <div class="item" v-for="(q, i) in form.list" :key="i">
            <div class="top">
                <div class="label"><span>#{{ q.l }}</span></div>
                <div class="button">
                    <v-btn class="btn_edit2" variant="text" icon="mdi-plus-box" @click="add(q)"
                        :disabled="q.d.length > 5"></v-btn>
                    <v-btn class="btn_edit2" variant="text" icon="mdi-minus-box" @click="sub(q)"
                        :disabled="q.d.length == 1"></v-btn>
                </div>
            </div>
            <div v-for="(inp, j) in q.d" :key="j" class="input">
                <SimpleInput v-model="q.d[j]['memo']" @update:modelValue="delete q.d[j]['time']"/>
            </div>
        </div>
        <div class="wrap2">
            <div class="l">
                <span v-if="is_important">중요 메모</span>
                <span v-if="!is_important">일반 메모</span>
            </div>
            <div class="r">
                <v-switch v-model="is_important" hide-details inset color="#ffe657"></v-switch>
            </div>
        </div>
        <div class="buttons">
            <ButtonComp @click="save" class="p1">저장</ButtonComp>
            <ButtonComp @click="close">닫기</ButtonComp>
        </div>
    </div>
</template>
<script>
import ButtonComp from '@/components/ButtonComp.vue'
import SimpleInput from '@/components/search/SimpleInput'

export default {
    name: 'TakeMemo',
    components: {
        ButtonComp,
        SimpleInput
    },
    props: {
        item_seq: {
            type: Number,
            default: 0,
        },
        edi_code : {
            type: String,
            default: ''
        }
    },
    computed: {},
    data: () => ({
        data: {
            용도: [],
            복용법: [],
            부작용: [],
            기타: [],
            알러지: []
        },
        form: {
            is_important: 0,
            list: []
        },
        is_important: false
    }),
    mounted() {
        var keys = ['용도', '복용법', '부작용', '알러지', '기타'];
        keys.forEach(key=>{
            this.form.list.push({l:key, d:[{memo:''}]})
        });

        var param = this.item_seq;
        if(this.item_seq == 0) {
            param = 'U:'+this.edi_code;
        }   

        this.$api.my.drug.memo.get({ item_seq: param }).then(response => {
            if (response.data.data != undefined) {
                this.data = response.data.data;
                this.form.is_important = this.data.is_important;
                this.reset();
            }
        });
    },
    methods: {
        add(q) {
            q.d.push({memo:''});
        },
        sub(q) {
            q.d.pop();
        },
        close() {
            this.$emit('close');
        },
        reset() {
            var memo = JSON.parse(this.data.memo);
            this.form.list.forEach(item => {
                item.d = memo[item.l];
            })

            if (this.data.is_important == 1) this.is_important = true;
            else this.is_important = false;
        },
        save() {
            var param = this.item_seq;
            if(this.item_seq == 0) {
                param = 'U:'+this.edi_code;
            } 

            var data = {
                is_important: this.form.is_important,
                memo: {},
                item_seq: param
            };

            this.form.list.forEach(m => {
                data.memo[m.l] = m.d;
            });

            this.$api.my.drug.memo.update({ item_seq: param }, {}, data).then(() => {
                this.$emit('close');
            });
        },
    },
    watch: {
        is_important(val) {
            if (val) this.form.is_important = 1;
            else this.form.is_important = 0;
        }
    }
}
</script>
<style lang="scss" scoped>
@import "@/assets/css/_variables.scss";
@import "@/assets/css/_mixins.scss";

.item {
    .top {
        display: flex;
        width: 100%;

        .label {
            width: 60px;

            span {
                display: inline-block;
                line-height: 38px;
                color: $b-g-6;
            }
        }

        .button {
            flex-grow: 1;
            text-align: right
        }
    }

    .input {
        margin-bottom: 10px;
    }
}

.btn_edit2::v-deep.v-btn--icon.v-btn--density-default {
    width: calc(var(--v-btn-height) + 2px);
    height: calc(var(--v-btn-height) + 2px);
}


.btn_edit2::v-deep {
    .v-btn--icon.v-btn--density-default {
        width: calc(var(--v-btn-height) + 2px);
        height: calc(var(--v-btn-height) + 2px);
    }

    .v-icon {
        font-size: 24px;
        color: #1e40af
    }
}

.buttons {
    display: flex;
    margin-top: 20px;

    .ButtonComp {
        width: 50%;

    }

    .ButtonComp:nth-child(2) {
        margin: 0px 5px;
    }
}

.wrap2 {
    display: flex;

    .l {
        line-height: 56px;
        display: inline-block;
        flex-grow: 1;
    }

    .r {
        min-width: 60px;
        max-width: 60px;

        :deep(.v-switch__track) {
            opacity: 0.5;
        }
    }
}
</style>