<template>
    <div class="QRCode">
        <div v-if="page == 0">
            <GuideSpan>내 건강정보 저장 후 사용 가능합니다.</GuideSpan>
            <ButtonComp @click="move">확인</ButtonComp>
        </div>
        <div v-if="page == 1">
            <GuideSpan>복용의약품 공유 QR코드를 만듭니다.<br><br>QR코드 링크의 유효시간은 2분이며, 1회만 열람가능합니다.</GuideSpan>
            <ButtonComp @click="page = 2">확인</ButtonComp>
        </div>
        <div v-if="page == 2">
            <GuideSpan>
                생성된 QR코드의 링크는 1회만 열람가능합니다.<br><br>
                <template v-if="time > 0"><strong>{{ time }} 초</strong> 후엔 사용하실 수 없습니다.</template>
                <template v-if="time == 0">만료되었습니다.<br>창을 닫은 후 다시 만들어주세요.</template>
            </GuideSpan>
            <div class="qr"><qrcode-vue :value="value" :size="size" level="H" v-if="time > 0" /></div>
        </div>
    </div>
</template>
<script>
import ButtonComp from '@/components/ButtonComp.vue'
import GuideSpan from '@/components/GuideSpan.vue'
import QrcodeVue from 'qrcode.vue'

import analytics from "@/assets/js/analytics";

export default {
    name: 'QRCode',
    components: {
        ButtonComp,
        GuideSpan,
        QrcodeVue
    },
    props: {
        is_open: {
            type: Boolean,
            default: false
        }
    },
    computed: {},
    data: () => ({
        page: 0,
        value: '',
        size: 200,
        timer: null,
        time: 0,
    }),
    mounted() {
        this.$api.my.info.bio().then(response => {
            if (response.data.data == undefined) {
                this.page = 0;
            } else {
                this.page = 1;
            }
        });
    },
    unmounted() {
        if (this.timer != null) clearInterval(this.timer);
    },
    methods: {
        move() {
            this.$emit('close_share_my_bio')
        }
    },
    watch: {
        page(val) {
            if (val == 2) {
                this.$api.my.drug.share.post({}).then(response => {
                    var p_id = this.$root.popup_id['SHARE_MY_BIO'] != undefined ? this.$root.popup_id['SHARE_MY_BIO'] : '';
                    if (p_id != '') analytics.log_p('P_SHARE_MY_BIO.ACT', p_id, {});

                    this.value = "SHARE:" + response.data.data.uuid;
                    this.dialog_qrcode = true;

                    this.time = 120;
                    var vm = this;
                    this.timer = setInterval(function () {
                        vm.time--;
                        if (vm.time == 0) {
                            clearInterval(vm.timer);
                        }
                    }, 1000);
                });
            }
        }
    }
}
</script>
<style lang="scss" scoped>
@import "@/assets/css/_variables.scss";
@import "@/assets/css/_mixins.scss";

div.qr {
    display: block;
    text-align: center;
}
</style>