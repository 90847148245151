<template>
    <div class="ButtonComp">
        <div class="wrap">
            <v-btn class="btn" block :disabled="disabled">
                <slot></slot>
            </v-btn>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ButtonComp',
    props: {
        label: {
            type: String,
            default: 'Label'
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    components: {

    },
    data: () => ({

    }),
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/_variables.scss";
@import "@/assets/css/_mixins.scss";

.wrap {
    @include check-default;
}

.btn {
    @include center;
    width: 100%;
    border: 2px solid $b-8;
    border-radius: 8px;
    padding: 10px 20px;
    min-height: 48px;
    font-size: 16px;
    font-weight: 700;
    line-height: 130%;
    color: #18338c;
}

.btn:hover {
    background-color: $b-1;
}

.t1 .btn {
    border: 0px;
    font-size: 16px;
    font-weight: 700;
    line-height: 130%;
    width: 100%;
    color: #434447;
    background-color: #e2e8f0;
    border-radius: 8px;
}

.p1 .btn {
    font-size: 16px;
    font-weight: 700;
    line-height: 130%;
    width: 100%;
    padding: 10px 0;
    color: #434447;
    background-color: #e2e8f0;
    border-radius: 8px;
}

.p2 .btn {
    font-size: 16px;
    font-weight: 700;
    line-height: 130%;
    width: 100%;
    padding: 10px 0;
    color: #434447;
    background-color: $b-g-1;
    border-radius: 8px;
}

.v-btn.btn:deep(.v-icon),
.v-btn.btn:deep(img) {
    margin: 0px 5px 0px 0px;
}
</style>