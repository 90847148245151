<template>
    <div>
        <div class="PillSearch" v-if="page == 1">
            <div>
                <InputText v-model="search_opts.print1" title="각인1" placeholder="알약에 각인된 문구를 입력하세요." />
                <InputText v-model="search_opts.print2" title="각인2" placeholder="알약에 각인된 문구를 입력하세요." />
                <span class="guide">앞뒷면의 각인을 각각 입력해주세요.<br />정확하지 않을 경우 일부만 입력해주세요.</span>
            </div>


            <GridButtonBox title="제형">
                <span class="btn" :class="{ active: form == search_opts.form }" @click="search_opts.form = form"
                    v-for="form in search_opts_list.form" :key="form">
                    {{ form }}
                </span>
            </GridButtonBox>

            <GridButtonBox title="모양">
                <span class="btn shape" :class="{ active: shape.selected }" @click="sel_shape(shape)"
                    v-for="shape in search_opts_list.shape" :key="shape.l">
                    <img :src="image_type(shape)" v-if="shape.num != undefined">
                    {{ shape.l }}
                </span>
            </GridButtonBox>

            <GridButtonBox title="색상">
                <div class="btn color" :class="{ active: color.selected }" @click="sel_color(color)"
                    v-for="color in search_opts_list.color" :key="color.l">
                    <div class="btn_image"><img :src="image_color(color)" v-if="color.num != undefined"></div>
                    <span>

                        {{ color.l }}
                    </span>
                </div>
            </GridButtonBox>

            <GridButtonBox title="분할선">
                <span class="btn" :class="{ active: line.v == search_opts.line }" @click="search_opts.line = line.v"
                    v-for="line in search_opts_list.line" :key="line.l">
                    {{ line.l }}
                </span>
            </GridButtonBox>

            <GridButtonBox title="도안" class="mark one">
                <v-row>
                    <v-col cols="4">
                        <div class="selected_mark"><img :src="search_opts.mark.mark_image"
                                v-if="search_opts.mark != undefined && search_opts.mark.mark_code != ''"></div>
                    </v-col>
                    <v-col cols="4">
                        <ButtonComp class="t1" @click="page = 2">선택</ButtonComp>
                    </v-col>
                    <v-col cols="4">
                        <ButtonComp class="t1" @click="search_opts.mark.mark_code = ''">취소</ButtonComp>
                    </v-col>
                </v-row>
            </GridButtonBox>

            <div class="foot_button">
                <button @:click="search_clear">초기화</button>
                <button @:click="search_shape">검색</button>
            </div>
        </div>
        <div class="PillSearch" v-if="page == 2">

            <GridButtonBox title="도안 검색조건" class="five">
                <span class="btn" :class="[{ active: item == search_opts.mark_cate }]" @click="search_opts.mark_cate = item"
                    v-for="item in search_opts_list.mark_cate" :key="item">{{ item }}</span>
            </GridButtonBox>

            <InputText v-model="search_mark_text" title="도안 검색어" placeholder="도안에 연상되는 낱말을 입력하세요." />

            <GridButtonBox title="도안 선택" class="marks five">
                <div class="btn mark" v-for="img in filtered_mark" @click="sel_mark(img)" :key="img.mark_image"><img
                        :src="img.mark_image" style="max-width:100%;height:40px" /></div>
            </GridButtonBox>

            <div class="foot_button type2">
                <button @:click="page = 1">닫기</button>
            </div>
        </div>
    </div>
</template>
<script>
import ButtonComp from '@/components/ButtonComp.vue'
import GridButtonBox from '@/components/search/GridButtonBox.vue'
import InputText from '@/components/InputText.vue'

import SearchData from '@/assets/data/search.json';

export default {
    name: 'PillSearch',
    components: {
        ButtonComp,
        GridButtonBox,
        InputText
    },
    data: () => ({
        page: 1,
        search_opts: {},
        selected_color: {},
        selected_shape: {},
        search_opts_list: {
            mark: []
        },
        search_mark_text: ''
    }),
    computed: {
        filtered_mark: function () {
            //if (this.search_mark_text == '' && this.search_opts.mark_cate == '전체') return [];
            return this.search_opts_list.mark.filter((item) => {
                return (
                    (this.search_opts.mark_cate == "전체" || (item.mark_category != null && item.mark_category.indexOf(this.search_opts.mark_cate) > -1)) &&

                    (item.mark_text
                        .toLowerCase()
                        .indexOf(this.search_mark_text.toLowerCase()) > -1 ||
                        (item.mark_category != null &&
                            item.mark_category
                                .toLowerCase()
                                .indexOf(this.search_mark_text.toLowerCase()) > -1))
                );
            });
        },
    },
    mounted() {
        Object.keys(SearchData['낱알검색']).forEach(k => {
            this[k] = JSON.parse(JSON.stringify(SearchData['낱알검색'][k]));
        });

        this.default_search_opts = JSON.parse(JSON.stringify(this.search_opts));

        var query = this.$route.query;
        Object.keys(query).forEach((k) => {
            this.search_opts[k] = query[k];
        });

        this.$api.marks.list().then((response) => {
            this.search_opts_list.mark = response.data.data;
            this.search_opts_list.mark.forEach((item) => {
                item.mark_image = `https://img7.edrugsafe.org/mark/${item.mark_code}.gif`;

                if (query.mark_code != undefined && query.mark_code == item.mark_code) {
                    this.search_opts.mark = item;
                }
            });
        });

        if (this.search_opts.shape) {
            var arr = this.search_opts.shape.split(",");
            var dic_shape = {};
            arr.forEach((shape) => {
                dic_shape[shape] = true;

                if (this.selected_shape.oblong.cs.indexOf("|" + shape + "|") != -1)
                    this.selected_shape.oblong.selected = true;
            });

            this.search_opts_list.shape.forEach((shape) => {
                if (dic_shape[shape.l]) {
                    shape.selected = true;
                } else {
                    shape.selected = false;
                }
            });
        } else {
            this.search_opts_list.shape.forEach((shape) => {
                if (shape.l == "전체") {
                    shape.selected = true;
                } else {
                    shape.selected = false;
                }
            });
        }

        if (this.search_opts.color) {
            var arr_c = this.search_opts.color.split(",");
            var dic_color = {};
            arr_c.forEach((color) => {
                dic_color[color] = true;

                if (this.selected_color.red.cs.indexOf(color) != -1)
                    this.selected_color.red.selected = true;
                if (this.selected_color.blue.cs.indexOf(color) != -1)
                    this.selected_color.blue.selected = true;
                if (this.selected_color.green.cs.indexOf(color) != -1)
                    this.selected_color.green.selected = true;
                if (this.selected_color.purple.cs.indexOf(color) != -1)
                    this.selected_color.purple.selected = true;
            });

            this.search_opts_list.color.forEach((color) => {
                if (dic_color[color.l]) {
                    color.selected = true;
                } else {
                    color.selected = false;
                }
            });
        } else {
            this.search_opts_list.color.forEach((color) => {
                if (color.l == "전체") {
                    color.selected = true;
                } else {
                    color.selected = false;
                }
            });
        }
    },
    methods: {
        chk_color: function (color_group, color) {
            var arr = color_group.cs.split(",");

            var cnt = 0;
            var dic_color = {};
            arr.forEach((_color) => {
                dic_color[_color] = true;
            });

            this.search_opts_list.color.forEach((_color) => {
                if (dic_color[_color.l] && _color.selected) cnt++;
            });

            if (cnt == 0) {
                this.search_opts_list.color.forEach((_color) => {
                    if (dic_color[_color.l]) _color.selected = true;
                });
            } else {
                if (color.selected) color.selected = false;
                else color.selected = true;
            }
        },
        chk_shape(shape_group, shape) {
            var arr = shape_group.cs
                .substring(1, shape_group.cs.length - 1)
                .split("|");

            var cnt = 0;
            var dic_shape = {};
            arr.forEach((_shape) => {
                dic_shape[_shape] = true;
            });

            this.search_opts_list.shape.forEach((_shape) => {
                if (dic_shape[_shape.l] && _shape.selected) cnt++;
            });

            if (cnt == 0) {
                this.search_opts_list.shape.forEach((_shape) => {
                    if (dic_shape[_shape.l]) _shape.selected = true;
                });
            } else {
                if (shape.selected) shape.selected = false;
                else shape.selected = true;
            }
        },
        clear_mark: function () {
            this.search_opts.mark = { mark_code: "" };
        },
        image_color(color) {
            try {
                return require(`@/assets/images/pill_search/color-${color.num}.svg`);
            } catch (error) {
                console.error('이미지 업로드 x:', error);
                return null;
            }
        },
        image_type(shape) {
            try {
                return require(`@/assets/images/pill_search/type-${shape.num}.svg`);
            } catch (error) {
                console.error('이미지 업로드 x:', error);
                return null;
            }
        },
        sel_color: function (color) {
            if (this.selected_color.red.cs.indexOf(color.l) != -1) {
                this.chk_color(this.selected_color.red, color);
            } else if (this.selected_color.blue.cs.indexOf(color.l) != -1) {
                this.chk_color(this.selected_color.blue, color);
            } else if (this.selected_color.green.cs.indexOf(color.l) != -1) {
                this.chk_color(this.selected_color.green, color);
            } else if (this.selected_color.purple.cs.indexOf(color.l) != -1) {
                this.chk_color(this.selected_color.purple, color);
            } else {
                if (color.l == "전체") {
                    if (color.selected == false) {
                        this.search_opts_list.color.forEach((_color) => {
                            _color.selected = false;
                        });
                        this.search_opts_list.color[0].selected = true;
                    }
                } else {
                    if (color.selected) color.selected = false;
                    else color.selected = true;
                }
            }

            var cc = 0;
            this.search_opts_list.color.forEach((_color) => {
                if (_color.l != "전체" && _color.selected) cc++;
            });

            var indx = 0;//this.search_opts_list.color.length - 1;
            if (cc == 0) {
                this.search_opts_list.color[indx].selected = true;
            } else {
                this.search_opts_list.color[indx].selected = false;
            }
        },
        sel_mark: function (img) {
            this.search_opts.mark = img;
            this.page = 1;
        },
        sel_shape(shape) {
            if (this.selected_shape.oblong.cs.indexOf("|" + shape.l + "|") != -1) {
                this.chk_shape(this.selected_shape.oblong, shape);
            } else {
                if (shape.l == "전체") {
                    if (shape.selected == false) {
                        this.search_opts_list.shape.forEach((_shape) => {
                            _shape.selected = false;
                        });
                        this.search_opts_list.shape[0].selected = true;
                    }
                } else {
                    if (shape.selected) shape.selected = false;
                    else shape.selected = true;
                }
            }

            var cc = 0;
            this.search_opts_list.shape.forEach((_shape) => {
                if (_shape.l != "전체" && _shape.selected) cc++;
            });

            var indx = this.search_opts_list.shape.length - 1;
            indx = 0;
            if (cc == 0) {
                this.search_opts_list.shape[indx].selected = true;
            } else {
                this.search_opts_list.shape[indx].selected = false;
            }
        },
        search_shape: function () {
            var params = {};

            var arr_color = [];
            this.search_opts_list.color.forEach((_color) => {
                if (_color.selected && _color.l != "전체") arr_color.push(_color.l);
            });

            if (arr_color.length > 0) {
                params.color = arr_color.join(",");
            }

            var arr_shape = [];
            this.search_opts_list.shape.forEach((_shape) => {
                if (_shape.selected && _shape.l != "전체") arr_shape.push(_shape.l);
            });

            if (arr_shape.length > 0) {
                params.shape = arr_shape.join(",");
            }

            if (this.search_opts.form != "전체") params.form = this.search_opts.form;
            if (this.search_opts.line != "전체") params.line = this.search_opts.line;

            if (this.search_opts.mark.mark_code != "")
                params.mark_code = this.search_opts.mark.mark_code;


            this.search_opts.print1 = this.search_opts.print1.trim();
            this.search_opts.print2 = this.search_opts.print2.trim();


            if (this.search_opts.print1 != '') {
                params.print1 = this.search_opts.print1;
                params.print2 = this.search_opts.print2;
                params.sorting = 'pt.desc';
            } else if (this.search_opts.print2 != '') {
                params.print1 = this.search_opts.print2;
                params.sorting = 'pt.desc';
            }

            if (Object.keys(params) == 0) {
                this.$alert("낱알 검색 옵션을 선택해주세요.");
            } else {
                params.mode = 'shape'
                params.p = 1;
                this.$emit("close_pill_option");
                this.$router.push({ path: "/drug/search", query: params });
            }
        },
        search_clear: function () {
            this.search_opts = JSON.parse(JSON.stringify(this.default_search_opts));

            this.search_opts_list.color.forEach((color) => {
                if (color.l == "전체") {
                    color.selected = true;
                } else {
                    color.selected = false;
                }
            });
        },

        show_marks: function () {
            this.page = 2;
        },
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/_variables.scss";
@import "@/assets/css/_mixins.scss";

.PillSearch>div {
    margin-bottom: 20px !important;
}

.PillSearch>div:last-child {
    margin-bottom: 0px !important;
}

.guide {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 14px;
}

.btn {
    text-align: center;
    @include H4;
    color: $b-g-6;
    padding: 10px 0;
}

.btn.active {
    background: $b-1;
    box-shadow: 0 0 0 1px $b-6 inset;
}

div.btn {
    min-height: 40px;
    padding: 0px 0px;
    display: flex;
}

div.btn div.btn_image {
    display: inline-block;
    height: 40px;
    flex-grow: 1;
    text-align: right;
    margin-right: 3px;
}

div.btn>span {
    display: inline-block;
    line-height: 40px;
    text-align: left;
    flex-grow: 1;
    margin-left: 3px;
}

.GridButtonBox.mark .v-col {
    padding: 10px;
}

.GridButtonBox.mark:deep(.btn) {
    color: #64748b;
    font-size: 14px;
    min-height: 38px
}

.btn.mark {
    display: block;
    width: 100%;
    text-align: center;
}

.selected_mark {
    text-align: center
}

.selected_mark img {
    width: 40px;
    height: 40px;
}


.foot_button {
    @include center;
}

.foot_button button {
    @include H2;
    width: 50%;
    padding: 10px 0;
    color: $b-9;
    min-height: 48px;
}

.foot_button button:first-child {
    border-radius: 8px 0 0 0;
    background-color: $b-g-1;
}

.foot_button button:last-child {
    border-radius: 0 8px 0 0;
    background-color: $b-g-1;
}

.foot_button.type2 button {
    border-radius: 8px 8px 0 0;
}

.foot_button button:hover {
    background-color: $b-1;
}

.marks:deep(.wrap) {
    height: 200px;
    overflow-y: auto;
}

.marks:deep(.wrap .btn) {
    padding: 10px 0px;
    min-height: 60px;
}

.btn.shape img {
    max-height: 14px;
    margin-right: 10px !important;
}
</style>