<template>
    <div class="FolderList">
        <div class="listWrap">
            <div v-for="(item, i) in folders.filter((x) => x.uuid != '')" :key="i" class="item">
                <div class="left">
                    <span class="folder">
                        <v-icon v-if="item.color == ''">mdi-folder-outline</v-icon>
                        <v-icon v-if="item.color != ''" :class="folderColor(item)">mdi-folder</v-icon>
                    </span>
                </div>
                <div class="middle" @click="sel(item)">
                    {{ item.name }}
                </div>
                <div class="right" v-if="show_right_buttons">
                    <button @click="edit(item)"><v-icon>mdi-folder-edit-outline</v-icon></button>
                    <button @click="del(item)"><v-icon>mdi-trash-can-outline</v-icon></button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'FolderList',
    components: {
    },
    props: {
        folders: {
            type: Array,
            default: () => ([])
        },
        show_right_buttons: {
            type: Boolean,
            default: true
        }
    },
    computed: {},
    data: () => ({}),
    mounted() {
    },
    methods: {
        folderColor(item) {
            var cl = {}
            if (item.color == undefined || item.color == '') return {}
            cl[item.color] = true;
            return cl;
        },
        del(item) {
            this.$emit('delete_item', item)
        },
        edit(item) {
            this.$emit('edit_item', item)
        },
        sel(item) {
            this.$emit('select_item', item)
        },
    },
    watch: {}
}
</script>
<style lang="scss" scoped>
@import "@/assets/css/_variables.scss";
@import "@/assets/css/_mixins.scss";

.item {

    display: flex;
    @include around;
    text-align: left;
    box-shadow: $shadow;
    padding: 10px;
    border-radius: 8px;
    margin: 5px;

    .left {
        min-width: 40px;
    }

    .checkbox {
        @include check-default;
    }

    .checkbox .v-checkbox {
        padding: 0px 0px 0px;
    }

    .middle {
        flex-grow: 1;
        text-overflow: ellipsis;

        span {
            display: inline-block;
        }

        span.memo {
            margin-left: 5px;
            font-size: 14px;
        }
    }

    .right {
        min-width: 80px;

        button {
            width: 30px;
            height: 30px;
            padding: 0px;
            text-align: center;
            margin-left: 10px;
            border: 2px solid #1e40af;
            border-radius: 6px;
            color: #1e40af;
        }
    }
}
</style>