const safetyLetter = () => import("@/views/notice/SafetyLetter.vue");
const discardList = () => import("@/views/notice/DiscardList.vue");

import Layout from "../layouts/LayoutBasic.vue";

export default [
    {
      path: "/notice/safety_letter",
      name: "safetyLetter",
      component: safetyLetter,
      meta: { layout: Layout, title: "공지사항" },
    },
    {
      path: "/notice/discards",
      name: "discards",
      component: discardList,
      meta: { layout: Layout, title: "공지사항" },
    },
  ];