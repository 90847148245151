<template>
    <div class="SettingView">
        <div class="label">글꼴 크기</div>
        <div class="content">
            <div class="slider">
                <span>작게</span>
                <v-slider v-model="font_size" :min="1" :max="4" :step="1" color="primary" hide-details></v-slider>
                <span>크게</span>
            </div>
        </div>
        <div class="content">
            <div class="sample" :class="fontClass">
                <span class="t1">사용상의 주의사항</span>
                <span class="t2">경고</span>
                <span class="t3">1) 매일 세잔 이상 정기적으로 술을 마시는 사람이 이 약이나 다른 해열 진통제를 복용해야 할 경우 반드시 의사 또는 약사와 상의해야 한다. 이러한 사람이
                    이 약을
                    복용하면 간손상이 유발될 수 있다.</span>
            </div>
        </div>
        

        <div class="info_box">
            <div class="label">앱버전</div>
            <div class="info">{{ $root.setting.version }}</div>
        </div>

        <v-divider></v-divider>
        <div class="info_box">
            <div class="label">실험실</div>
            <div class="info">
                
            </div>
        </div>
        <div class="content">
            <v-switch v-model="use_grammar" color="primary" label="음성인식 grammar 사용" :value="true" hide-details v-if="false"></v-switch>
            <v-switch v-model="use_api" color="primary" label="음성인식 ETRI API 사용" :value="true" hide-details></v-switch>
            <v-switch v-model="use_final" color="primary" label="음성인식 보정 사용" :value="true" hide-details></v-switch>
        </div>

        <hr />
        <v-row>
            <v-col cols="4">
                <ButtonComp @click="save" class="p1">저장</ButtonComp>
            </v-col>
            <v-col cols="4">
                <ButtonComp @click="$root.v_dialog.setting = false">취소</ButtonComp>
            </v-col>
            <v-col cols="4">
                <ButtonComp @click="restart">앱재시작</ButtonComp>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import ButtonComp from '@/components/ButtonComp.vue'
import storage from '@/assets/js/storage'

export default {
    name: "SettingView",
    components: {
        ButtonComp
    },
    data: () => ({
        font_size: 1,
        use_api : false,
        use_final : false,
        use_grammar: false
    }),
    computed: {
        fontClass() {
            var obj = {};
            obj['dt_' + this.font_size] = true
            return obj
        }
    },
    mounted() {
        this.font_size = storage.fontSize();
        this.use_api = storage.useAPI();
        this.use_final = storage.useFinal();
        this.use_grammar = storage.useGrammar();
    },
    methods: {
        save() {
            storage.fontSize(this.font_size);
            this.$root.setting.font_size = this.font_size;

            storage.useAPI(this.use_api);
            this.$root.setting.use_api = this.use_api;

            storage.useFinal(this.use_final);
            this.$root.setting.use_final = this.use_final;

            storage.useGrammar(this.use_grammar);
            this.$root.setting.use_grammar = this.use_grammar;

            this.$root.v_dialog.setting = false;
        },
        restart() {
            this.$confirm('앱을 재시작할까요?').then(rs => {
                if (rs.isConfirmed) {
                    window.location.reload();
                }
            })
        }
    },
    watch: {
    }
}
</script>
<style lang="scss" scoped>
@import "@/assets/css/_variables.scss";
@import "@/assets/css/_mixins.scss";


.SettingView .label {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 10px;
}

.content {
    margin-bottom: 20px;
    border: 0px solid $b-g-4;
    border-radius: 10px;
    padding: 10px;
}

.slider {
    display: flex;
    max-width: 400px;
    margin: 0px auto;
    ;
}

.slider>span {
    display: block;
    min-width: 50px;
    text-align: center;
    line-height: 32px;
}

.sample {
    font-size: 14px;
}

.sample.dt_2 {
    font-size: 16px;
}

.sample.dt_3 {
    font-size: 18px;
}

.sample.dt_4 {
    font-size: 20px;
}

.sample span {
    display: block;
}

.sample .t1 {
    font-size: 1.2em;
}

.sample .t2 {
    font-size: 1.1em;
}

hr {
    @include hr-line-gray;
}

.ButtonComp {
    margin: 0px 5px;
}


.info_box {
    display: flex;
    flex-direction: row;

    .label {
        min-width: 100px;
    }

    .info {
        flex-grow: 1;
        text-align: right;
        padding-right: 10px;
    }
}
</style>