import Layout from "../layouts/LayoutBasic.vue";

export default [
  {
    path: "/food/warning",
    name: "foodWarning",
    component: () => import("@/views/food/WarningView.vue"),
    meta: { layout: Layout, title: "건강기능 식품 주의 정보" },
  },
  {
    path: "/food/warning/search",
    name: "foodWarningSearch",
    component: () => import("@/views/food/SearchView.vue"),
    meta: { layout: Layout, title: "의약품 검색" },
  },
  {
    path: "/food/warning/take",
    name: "foodWarningTake",
    component: () => import("@/views/food/SearchView.vue"),
    meta: { layout: Layout, title: "복용의약품 목록" },
  },
];
