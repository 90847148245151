<template>
    <div class="CardCompWrap">
        <div class="wrap">
            <div class="title">
                <p>{{ title }}</p>
            </div>
            <div class="text">
                <slot></slot>
                <p>{{ text }}</p>
            </div>
        </div>
    </div>
</template>
  
<script>


export default {
    name: 'CardComp',
    components: {
    },
    props: {
        title: {
            type: String,
            default: 'cardTitle',
        },
        text: {
            type: String,
            default: '',
        },
    },
    data: () => ({

    }),
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/_variables.scss";
@import "@/assets/css/_mixins.scss";

.wrap {
    padding: 10px;
    box-shadow: $shadow;
    border-radius: 8px;
    margin-bottom: 10px;
}

.title p {
    @include H3;
    color: $b-8;
    margin-bottom: 5px;
}

.text p {
    @include H6;
    color: $b-g-5;
}
</style>