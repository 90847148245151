export default {
    regex:{
        list_usage : [
            /[0-9]{1,9}[ ]?[～∼~-][ ]?[0-9]{1,9}[ ]?(일 간격|시간|일간|일|주|회)/,
            /[0-9]{1,9}[회]?[ ]?[～∼~-][ ]?(수회)/,
            /[0-9]{1,9}[ ]?(시간 이내|시간 이상|시간 이후|주에 걸쳐|시간 전|일 간격|주 이내|주 이상|개월|분간|시간|일간|주간|주일|분|일|주|회)/,
            /(일|이|삼|사|오|육|칠|팔|구|십)[ ]?주일/,
            /(한|두|세|네|다섯)[ ]?시간/,
            /(식사[ ]?시간|식사[ ]?때|식사[ ]?전|식사[ ]?후|식[ ]?전|식[ ]?후|공복|식간|식사|아침|저녁|점심|취침)/
          ],
          
          list_volume : [
            /(([0-9]{1,10})|(([0-9]{1,3}[,])?([0-9]{1,3}[,])?[0-9]{1,3}))([.][0-9]{1,9})?[ ]?(～|~|-|\/|\+)[ ]?(([0-9]{1,10})|(([0-9]{1,3}[,])?([0-9]{1,3}[,])?[0-9]{1,3}))([.][0-9]{1,9})?[ ]?(mmol\/L|mg\/m<sup>2<\/sup>|mg\/m2|I.U.|kcal|Kcal|mEq|µg|µl|AU|cm|IU|mg|mL|ml|mm|oz|g|L|l|(마이크로|밀리)?(그람|그램|리터)|만[ ]?단위|티스푼|단위|리터|방울|캡슐|패취|개|매|배|병|장|적|정|컵|포)/,
            /(([0-9]{1,10})|(([0-9]{1,3}[,])?([0-9]{1,3}[,])?[0-9]{1,3}))([.][0-9]{1,9})?[ ]?(mmol\/L|mg\/m<sup>2<\/sup>|mg\/m2|I.U.|kcal|Kcal|mEq|µg|µl|AU|cm|IU|mg|mL|ml|mm|oz|g|L|l|(마이크로|밀리)?(그람|그램|리터)|만[ ]?단위|티스푼|단위|리터|방울|캡슐|패취|개|매|배|병|장|적|정|컵|포)/,
            /(한)[ ]?(티스푼|방울|패취|개|매|배|병|장|컵|포)/,
            /((\/)?체중[ ]?(\()?kg(\))?[ ]?(당)?|\/[ ]?(day|min|hr|kg|mL|L|킬로그람|킬로그램|시간|분|일)|적당량|충분량|체중)/i
          ],
          list:[]
    },
    extract_info:(tokens, regex, c) => {
        var new_tokens = [];
        tokens.forEach(token=>{
            if(token.t != 'raw') {
                new_tokens.push(token);
            } else {
                var str = token.s;
                let a_match;
                while( (a_match = regex.exec(str)) !=null) {
                    var new_token = a_match[0];
                    var a1 = str.substring(0, a_match.index);
      
                    new_tokens.push({t:'raw', s:a1});
                    new_tokens.push({t:c, s:new_token});
      
                    str = str.substring(a_match.index+new_token.length);
                }
                if(str!='') new_tokens.push({t:'raw', s:str});
            }
        });
        return new_tokens;
    },
    highlight_ud:(text) => {
        var inp_tokens = [{t:'raw', s:text}];
      
        this.regex.list_usage.forEach(regex=>{
            inp_tokens = this.extract_info(inp_tokens, regex, 'usage');
        });
          
        this.regex.list_volume.forEach(regex=>{
            inp_tokens = this.extract_info(inp_tokens, regex, 'volume');
        });

        var _html = "";
        inp_tokens.forEach(token=>{
            if(token.t == 'raw') _html += token.s;
            else {
                _html += `<span class="hl_${token.t}">${token.s}</span>`;
            }
        });
        return _html;
    },
    highlight_regex:(text) => {
        if(this.regex.list.length == 0) return text;

        var inp_tokens = [{t:'raw', s:text}];

        this.list.forEach(r1=>{
            r1.r.forEach(t1=>{
                var regex = new RegExp(t1, 'i');
                inp_tokens = this.extract_info(inp_tokens, regex, 'keyword');
            });
        });

        var _html = "";
        inp_tokens.forEach(token=>{
            if(token.t == 'raw') _html += token.s;
            else {
                _html += `<span class="hl_${token.t}">${token.s}</span>`;
            }
        });
        return _html;
    }
};