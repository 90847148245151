<template>
    <div>
        <BasicCardText v-for="(item, i) in list" :key="(item.saft_lett_no + '_' + i)" :title="item.title">
            <pre>{{ item.text }}</pre>
        </BasicCardText>

        <BasicCardText title="첨부파일" v-if="files.length > 0">
            <a :href="file.url" target="_blank" v-for="file in files" :key="file.name">{{ file.name }}</a>
        </BasicCardText>

        <BasicCardText :title="medicine_div_title" v-if="medicines.length > 0">
            <InputText v-model="filter_medicine" placeholder="제품명을 입력하세요."></InputText>

            <div class="scroll_medicine">
                <BasicCardText v-for="(item) in filteredMedicine" :key="(item.item_seq)" :title="item.item_name"
                    :text="item.entp_name">
                </BasicCardText>
            </div>
        </BasicCardText>
    </div>
</template>
<script>
import BasicCardText from '../../components/BasicCardText.vue'
import InputText from '../../components/InputText.vue'

import analytics from "@/assets/js/analytics";

export default {
    name: 'SafetyLetter',
    components: {
        BasicCardText,
        InputText
    },
    props: {
        no: {
            type: String,
            default: '',
        }
    },
    data: () => ({
        list: [],
        files: [],
        medicines: [],
        filter_medicine: '',
    }),
    computed: {
        medicine_div_title() {
            return "관련의약품(" + this.medicines.length + "건)";
        },
        filteredMedicine() {
            return this.medicines.filter((value) => {
                if (this.filter_medicine == "") return true;
                return value.item_name.indexOf(this.filter_medicine) != -1;
            });
        },
    },
    mounted() {
        this.$api.safety_notices
            .detail({ code: this.no })
            .then((response) => {
                var item = response.data.data;

                item.chrg_dep = item.chrg_dep + ' ' + item.rgstn_id;
                item.rls_bgng_ymd = item.rls_bgng_ymd.substring(0, 10);

                var list = [['제목', 'title'], ['담당자', 'chrg_dep'], ['공고일자', 'pbanc_ymd'], ['공개시작일', 'rls_bgng_ymd'], ['요약', 'sumry_cont'], ['상세내용', 'pbanc_cont'], ['조치사항', 'actn_mttr_cont']];

                list.forEach(k => {
                    if (item[k[1]] != undefined && item[k[1]] != null && item[k[1]] != '') {
                        this.list.push({
                            "title": k[0],
                            "text": item[k[1]]
                        })
                    }
                })

                this.files = item.files;

                analytics.log_h('H_SAFETY_LETTER', '', {
                    saft_lett_no:this.no
                });

                Promise.all([
                    this.$api.safety_notices.medicines({ code: this.no }),
                ]).then((responses) => {
                    this.medicines = responses[0].data.data;
                });
            });
    }
}
</script>
<style lang="scss" scoped>
@import "@/assets/css/_variables.scss";
@import "@/assets/css/_mixins.scss";

pre {
    white-space: pre-wrap;
}

.scroll_medicine {
    max-height: 400px;
    overflow-y: auto;
    padding: 10px
}
</style>