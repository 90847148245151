<template>
    <div class="TakeCard item" :class="classWrap">
        <div class="left checkbox" v-if="checkbox"><v-checkbox v-model="isChecked" hide-details density="compact"
                @update:modelValue="check"></v-checkbox></div>
        <div class="middle" @click="sel(item)">
            <p>{{ ng.clean_item_name(item.item_name) }}</p>
            <p v-if="type == 'tl'">{{ $filters.formatDate(item.create_time) }} |
                <FolderInfo :item="item" :show_all="true"/>
            </p>
            <p v-if="type == 'tl' && (item.hospital != '' || item.pharm != '')">
                {{ item.hospital }}
                <span v-if="item.pharm != ''">| {{ item.pharm }}</span>
            </p>
            <p>1일 {{ item.rate }}회 | 1회 {{ item.quantity }}알 | 총 {{ item.days }}일
                <span v-if="item.usage != ''">| {{ item.usage }}</span>
            </p>
        </div>
        <div class="right" v-if="show_right_buttons">
            <v-icon v-if="item.on_medication == 1" class="on" @click="take_off(item)">mdi-pill</v-icon>
            <v-icon v-if="item.on_medication == 0" @click="take_on(item)">mdi-pill</v-icon>
            <v-icon @click="memo(item)" v-if="item.memo_is_important == 1" class="important">mdi-note</v-icon>
            <v-icon @click="memo(item)" v-else-if="item.memo_is_important == null">mdi-note-outline</v-icon>
            <v-icon @click="memo(item)" v-else class="on">mdi-note-outline</v-icon>
        </div>
    </div>
</template>
<script>
import FolderInfo from '@/components/drug/FolderInfo.vue'
import ng from '@/assets/js/ng'

export default {
    name: 'TakeCard',
    components: {
        FolderInfo
    },
    props: {
        item:{
            type:Object,
            default:()=>({})
        },
        checkbox:{
            type:Boolean,
            default:false
        },
        show_right_buttons:{
            type:Boolean,
            default:true
        },
        type:{
            type:String,
            default:'pd' /* pd:처방전 상세 */
        }
    },
    computed: {},
    data: () => ({
        isChecked:false,
        ng:ng
    }),
    mounted() {

    },
    methods: {
        classWrap(){
            var opt = {};
            opt[this.type] = true;
            return opt;
        },
        memo(item) {
            this.$emit('memo', item);
        },
        sel(item) {
            this.$emit('sel', item);
        },
        take_on(item) {
            this.$emit('take_on', item);
        },
        take_off(item) {
            this.$emit('take_off', item);
        }
    },
    watch: {}
}
</script>
<style lang="scss" scoped>
@import "@/assets/css/_variables.scss";
@import "@/assets/css/_mixins.scss";

.item {

display: flex;
@include around;
text-align: left;
box-shadow: $shadow;
padding: 10px;
border-radius: 8px;
margin: 5px;

.left {
    min-width: 40px;
}

.checkbox {
    @include check-default;
}

.checkbox .v-checkbox {
    padding: 0px 0px 0px;
}

.middle {
    flex-grow: 1;

    p {
        display: block;
        @include H6;
        color: $b-8;
    }

    p:first-child {
        @include H4;
        color: $b-g-8;
    }


}

.right {
    min-width: 32px;
    max-width: 64px;

    .v-icon {
        display: inline-block;
        width: 32px;
        height: 32px;
        color: $b-g-3;
    }

    .v-icon.on {
        color: $b-8;
    }

    .v-icon.important {
        color: #ffe657;
    }
}
}
</style>