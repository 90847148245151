<template>
    <v-select hide-details hide-no-data density="compact" item-title="l" item-value="v" variant="plain"></v-select>
</template>
<style lang="scss" scoped>
@import "@/assets/css/_variables.scss";
@import "@/assets/css/_mixins.scss";


.v-select {

    &:deep(.v-field__outline) {
        padding: 12px 7px 12px 17px;
        border-radius: 8px;
        border: 2px solid $b-8;
        height: 48px;
        padding: 10px;
    }

    &:deep(.v-select__selection) {
        height: 48px;
        color: #18338c;
        font-size: 14px;
        font-weight: 500;
        line-height: 170%;
    }

    &:deep(.v-field--variant-filled .v-field__overlay) {
        opacity: 0;
    }

    &:deep(.v-field__input) {
        padding: 0px 0px 0px 16px;
    }

    &:deep(input) {
        height: 48px;
        color: #18338c;
        font-size: 14px;
        font-weight: 500;
        line-height: 170%;
    }

    &:deep(input::placeholder) {
        opacity: 1;
    }

    &:deep(.v-field__append-inner) {
        padding-top: 12px;
        padding-right:6px;
    }
}
</style>