<template>
    <div class="InputSearch">
        <v-text-field class="v-text" v-model="input" :placeholder="placeholder" variant="outlined" hide-details hide-no-data
            density="compact" append-inner-icon="mdi-magnify" :value="modelValue"
            v-on:keyup.enter="$emit('search')"
            @click:appendInner="$emit('search')"
            @update:modelValue="$emit('update:modelValue', input)"></v-text-field>
    </div>
</template>
<script>
export default {
    name: 'InputSearch',
    props: {
        modelValue: {
            type: String,
            default: '',
        },
        searchTitle: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        },
    },
    data: () => ({
        input: ''
    }),
    methods: {
        click_input() {
            this.$emit('update:modelValue', this.input)
        }
    }
}
</script>
  
<style lang="scss" scoped>
@import "@/assets/css/_variables.scss";
@import "@/assets/css/_mixins.scss";

.InputSearch {
    width: 100%;
    @include search_append_inner;
}
</style>