<template>
    <div>
        <div class="TheHeader">
            <div class="Left">
                <router-link :to="`/`" v-if="route != '/'">
                    <v-icon>mdi-home-outline</v-icon>
                    <span>안전e약</span>
                </router-link>
                <span class="routeName" v-if="route != '/'"> : {{ $route.meta.title }}</span>
            </div>
            <div class="Right" v-if="simple == false">
                <v-btn class="btn_login" variant="text" color="white" @click="go('/user/login')"
                    v-if="$root.user == null">로그인</v-btn>

                <template v-if="user != null">
                    <v-btn variant="text" icon="" @click="$root.v_dialog.notification = true">
                        <v-badge dot color="error" v-if="$root.notification.has_new == true">
                            <v-icon color="white">mdi-bell-outline</v-icon>
                        </v-badge>
                        <v-icon color="white" v-if="$root.notification.has_new == false">mdi-bell-outline</v-icon>
                    </v-btn>

                    <v-btn variant="text" icon="mdi-account-circle" color="white" @click="go('/my/info')"
                        v-if="false"></v-btn>
                </template>


                <v-btn variant="text" icon="mdi-menu" color="white" @click="menu_open"></v-btn>
            </div>
        </div>
        <div class="side_menu_bg" :class="{ 'on': drawer }">
        </div>
        <div class="side_menu" :class="{ 'on': drawer }">
            <div class="menu_body">
                <div class="head">
                    <div class="l">
                        <template v-if="user != null">
                            <v-btn variant="text" icon="mdi-account-circle" color="white"
                                @click="go('/my/info')"></v-btn>
                            <span @click="go('/my/info')">{{ user.name }}님</span>
                        </template>

                        <template v-if="user == null">
                            <v-btn variant="text" icon="mdi-account-circle" color="white"
                                @click="go('/user/login')"></v-btn>
                            <span @click="go('/user/login')">로그인해주세요.</span>
                        </template>
                    </div>
                    <div class="r">
                        <v-btn variant="text" icon="mdi-cog" color="white" @click="$root.v_dialog.setting = true"
                            v-if="user != null"></v-btn>
                        <v-btn variant="text" icon="mdi-logout" color="white" @click="logout"
                            v-if="user != null"></v-btn>
                        <v-btn variant="text" icon="mdi-close" color="white" @click="menu_close"></v-btn>
                    </div>
                </div>
                <SideMenu @click-menu="menu_click" :key="$root.time.user"></SideMenu>
            </div>
        </div>
    </div>
</template>

<script>
import SideMenu from '@/components/SideMenu.vue'
import ng from '@/assets/js/ng.js'
//import storage from '@/assets/js/storage';

export default {
    name: 'TheHeader',
    data: () => ({
        drawer: false,
        time: null
    }),
    components: {
        SideMenu
    },
    computed: {
        route() {
            return this.$route.path;
        },
        user() {
            return this.$root.user;
        }
    },
    props: {
        simple: {
            type: Boolean,
            default: false
        }
    },

    mounted() {
        var vm = this;
        window.onpopstate = function () {
            setTimeout(function () {
                if (vm.drawer) vm.drawer = false
            }, 100)
            return false;
        };
    },

    methods: {
        logout() {
            this.$confirm('로그아웃할까요?').then(rs => {
                if (rs.isConfirmed) {
                    this.drawer = false;
                    this.$root.logout2();
                }
            })
        },
        menu_click(menu) {
            if (menu.path == '') {
                switch (menu.title) {
                    case '낱알검색':
                        this.menu_close();
                        this.$root.pop_menu('shape');
                        break;
                    case 'QR검색':
                        this.menu_close();
                        this.$root.pop_menu('qr');
                        break;
                    case '고급검색':
                        this.menu_close();
                        this.$root.pop_menu('advanced');
                        break;
                    case '음성검색':
                        this.menu_close();
                        this.$root.pop_menu('voice');
                        break;
                    case '내 약력 전송':
                        this.menu_close();
                        this.$root.pop_menu('share_my_bio');
                        break;
                    case '푸시안내팝업':
                        this.menu_close();
                        this.$root.v_dialog.push = true;
                        break;
                    case '안전성 서한 알림이력':
                        this.menu_close();
                        this.$root.v_dialog.notification = true;
                        break;
                    default:
                        this.$alert(menu.title)
                }
            } else {
                this.go(menu.path)
            }
        },
        menu_close() {
            this.drawer = false;
            //this.$router.go(-1)
        },
        menu_open() {
            this.drawer = true;
            this.time = new Date().getTime();
        },
        go(path) {
            this.drawer = false;
            if (path != undefined && path != '') this.$router.push({ path: path });
        },

    },
    watch: {
        drawer(val) {
            ng.modal.is_menu_open = val;
            if (val) {
                ng.modal.menu_open();

                var opt = {
                    path: this.$route.path,
                    query: this.$route.query,
                    params: this.$route.params
                }
                opt.params._menu = 'Y'
                opt.params._time = new Date().getTime();
                //this.$router.push(opt)
            } else {
                ng.modal.menu_close();
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/_variables.scss";
@import "@/assets/css/_mixins.scss";

.TheHeader {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-height: 56px;
    padding: 0px 0px 0px 16px;
    background-color: $b-8;
    border-radius: 0 0 12px 12px;
}

@media (hover: none) {
    .TheHeader {
        border-radius: 0 0 0 12px;
    }
}





.Left {
    flex-grow: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 56px;
    color: $w;

    .v-icon {
        max-width: 28px;
        max-height: 28px;
        font-size: 24px;
    }

    span,
    a {
        color: $w;
        font-size: 20px;
        font-weight: $fw-8;
        line-height: 100%;
    }

    a {
        cursor: pointer;
    }

    .routeName {
        font-size: 18px;
        font-weight: 500;
        line-height: 18px;
        margin-top: 4px;
    }
}

.Right {
    min-width: 100px;
    @include center;
    gap: 0px;
}

.btn_login:deep(.v-btn__content) {
    font-size: 10px;
    border: 1px solid white;
    padding: 5px 10px;
    border-radius: 6px;
}


.TheHeader {
    :deep(.v-btn--size-default) {
        min-width: 48px;
    }
}


.side_menu_bg {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 100%;
    height: 100vh;
    background-color: black;
    opacity: 0.7;
    display: none;
    z-index: 10;
}

.side_menu_bg.on {
    display: block;
}


.side_menu {
    position: fixed;
    top: 0px;
    right: 0px;
    width: 100%;
    height: 100vh;
    z-index: 10;
    transform: translatex(110%);
    transition: transform 0.3s ease-in-out;

    .menu_body {
        position: absolute;
        top: 0px;
        right: 0px;
        max-width: 500px;
        min-width: 200px;
        min-height: 100vh;
        width: 100%;
        background-color: white;

        .head {
            display: flex;
            width: 100%;
            max-height: 56px;
            padding: 0px 0px 0px 16px;
            background-color: $b-8;
            border-radius: 0 0 12px 12px;


            .l {
                flex-grow: 1;
                color: white;
            }
        }
    }
}

.side_menu.on {
    transform: translateX(0px)
}

@media (max-width: 412px) {
    .side_menu .menu_body {
        width: 100%
    }
}
</style>