<template>
    <div class="TabComp">
        <v-tabs v-model="tab" class="tabWrap" dense grow hide-slider>
            <v-tab class="tab" selected-class="one" value="one">{{ first }}</v-tab>
            <v-tab class="tab" selected-class="two" value="two">{{ second }}</v-tab>
        </v-tabs>
        <v-window v-model="tab" class="tab_window">
            <v-window-item value="one">
                <ListSafety :list="list_safety"/>
            </v-window-item>
            <v-window-item value="two">
                <ListDiscard :list="list_discard"/>
            </v-window-item>
        </v-window>
    </div>
</template>
<script>
import ListDiscard from '../components/ListDiscard.vue';
import ListSafety from '../components/ListSafety.vue';

export default {
    name: 'TabComp',
    components: {
        ListDiscard,
        ListSafety
    },
    props: {

    },
    data: () => ({
        tab: 'one',
        first: '안전성 서한',
        second: '회수폐기',
        list_discard: [],
        list_safety: []
    }),
    mounted() {
        this.$api.discards.list().then(response => {
            this.list_discard = response.data.data
        });

        this.$api.safety_notices.list().then(response => {
            this.list_safety = response.data.data
        });
    }
}
</script>
<style lang="scss" scoped>
@import "@/assets/css/_variables.scss";
@import "@/assets/css/_mixins.scss";

.TabComp {
    display:flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
}

.tabWrap {
    max-height: 40px;
    min-height: 40px;
    background-color: $b-g-2;
    border-radius: 8px 8px 0 0;
}

.tab {
    @include H2;
    width: 50%;
    padding: 11px 0;
    color: $b-g-4;
}

.one,
.two {
    @include H2;
    color: $b-9;
    background-color: $b-2;
}

.tab_window {
    flex-grow: 1;
    min-height: 150px;
    padding: 5px 3px 5px 3px;
}

.tab_window:deep(.v-window__container) {
    height: 100%;
}

.v-window-item {
    max-height: 100%;
    overflow-y: scroll;
}

.ListDiscard,
.ListSafety {
    padding: 5px;
}
</style>