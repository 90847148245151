<template>
    <div class="ContentBox">
        <div class="wrap">
            <v-expansion-panels v-model="panel">
                <v-expansion-panel>
                    <v-expansion-panel-title>
                        <div class="TitleWrap">
                            <span class="mainTitle">{{ title }}</span>
                            <span class="hr" />
                        </div>
                        <template v-slot:actions="{ expanded }">
                            <svg-icon type="mdi" :path="expanded ? up : down"></svg-icon>
                        </template>
                    </v-expansion-panel-title>
                    <v-expansion-panel-text>
                        <slot></slot>
                    </v-expansion-panel-text>
                </v-expansion-panel>
            </v-expansion-panels>
        </div>
    </div>
</template>
<script>
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiUnfoldMoreHorizontal } from '@mdi/js';
import { mdiUnfoldLessHorizontal } from '@mdi/js';

export default {
    name: 'PanelComp',
    components: {
        SvgIcon
    },
    props: {
        open: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: 'mainTitle',
        },
        subTitle: {
            type: String,
            default: '',
        },
    },
    data: () => ({
        up: mdiUnfoldLessHorizontal,
        down: mdiUnfoldMoreHorizontal,
        panel: [],
    }),
    mounted() {
        if(this.open) this.panel = [0];
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/_variables.scss";
@import "@/assets/css/_mixins.scss";

.ContentBox {
    margin-bottom: 10px;
}

.wrap {
    @include panel_white;
}

.TitleWrap {
    @include between;
    width: 100%;
}

.TitleWrap span {
    text-align: left;
    color: $b-g-7;
    font-size: 1em;
    font-weight: $fw-9;
}


.ContentBox.blue .wrap .v-expansion-panel-title:deep(span) {
    background-color: $b-1;
    opacity: 0.9;
}



.mainTitle {
    min-width: 150px;
}

.subTitle {
    flex-grow: 1;
}

.hr {
    flex-grow: 8;
    @include hr-line;
}

.wrap:deep(.v-expansion-panel-text) {
    padding: 10px 10px 10px 10px!important;
}
</style>
