<template>
    <div class="QRInfoBox" v-if="item.expiration_date != undefined">
        <div class="wrap">
            <div class="title">
                <span>의약품 QR 코드 정보</span>
            </div>
            <div class="noticeWrap">
                <p><span>유통기한</span><span>|</span>
                    <span v-if="item.expiration_date_diff > 0">{{ $filters.formatDate(item.expiration_date) }}
                        ({{ $filters.formatNumber(item.expiration_date_diff) }}일 남음)
                    </span>
                    <span v-if="item.expiration_date_diff == 0">{{ $filters.formatDate(item.expiration_date) }}
                        (오늘까지)</span>
                    <span class="red" v-if="item.expiration_date_diff < 0">유효기간 만료</span>
                </p>

                <p><span>로트번호</span><span>|</span><span>{{ item.pl_num }}</span></p>
                <p><span>시리얼번호</span><span>|</span><span>{{ item.sn }}</span></p>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: 'QRInfoBox',
    props: {
        item: {
            type: Object,
            default: () => ({})
        },
    },
    data: () => ({
    }),
    mounted() {
        
    }
}
</script>
  
<style lang="scss" scoped>
@import "@/assets/css/_variables.scss";
@import "@/assets/css/_mixins.scss";

.title {
    padding: 0 0 10px 0;
}

.title span {
    @include H4_EM;
    color: $b-9;
}

.noticeWrap {
    padding: 7px 0;
    background-color: $b-05;
    border: 2px solid $y-9;
    border-radius: 4px;
    padding: 10px;
}

.noticeWrap p {
    @include H4_EM;
    color: $b-9;
    display:block;
}

p>span {
    display: inline-block;
}

p>span:first-child {
    min-width: 80px;
}

p>span:last-child {
    padding-left: 10px;
}

span.red {
    color: $r-5;
}
</style>
  