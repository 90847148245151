<template>
    <div class="TopTitleCompWrap">
        <div class="wrap">
            <div class="Left">
                <span>{{ item.item_name }}</span>
                <hr class="hr" />
                <span>{{ item.entp_name }}</span>
            </div>
            <div class="Right">
                <v-btn variant="text" @click="click_item('click_memo')"
                    :class="{ 'important': item.memo_is_important == 1, 'on': item.memo_is_important == 0 }">
                    <v-icon v-if="item.memo_is_important == 1">mdi-note</v-icon>
                    <v-icon v-else-if="item.memo_is_important == 0">mdi-note</v-icon>
                    <v-icon v-else>mdi-note-outline</v-icon>
                </v-btn>

                <v-btn variant="text" @click="click_item('click_bookmark_off')" v-if="user.type == 'E' && is_bookmarked">
                    <v-icon>mdi-bookmark-check</v-icon>
                </v-btn>
                <v-btn variant="text" @click="click_item('click_bookmark_on')" v-if="user.type == 'E' && !is_bookmarked">
                    <v-icon>mdi-bookmark</v-icon>
                </v-btn>
                <v-btn variant="text" @click="click_item('click_taken')">
                    <v-icon>mdi-pill</v-icon>
                </v-btn>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: 'TopTitleComp',
    components: {
    },
    props: {
        item: {
            type: Object,
            default: () => ({})
        },
        user: {
            type: Object,
            default: () => ({})
        },
        is_bookmarked: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({

    }),
    mounted() {
    },
    methods: {
        click_item(val) {
            this.$emit(val, this.item)
        }
    }
}
</script>
  
<style lang="scss" scoped>
@import "@/assets/css/_variables.scss";
@import "@/assets/css/_mixins.scss";

.wrap {
    @include between;
}

.hr {
    @include hr_1_gray;
}

.Left {
    flex-grow: 8;
    text-align: left;
}

.Left span {
    margin: 4px;
}

.Left span:nth-child(1) {
    @include H3_EM;
    color: $b-9;
}

.Left span:nth-child(3) {
    @include H4_EM;
    color: $b-9;
}

.Right {
    @include center;
    gap: 5px;
    padding-left: 20px;
}

.Right:deep(.v-btn) {
    min-width: 36px;
    width: 36px;
    border-radius: 3px;
}

.Right:deep(.v-btn .v-btn__underlay) {
    border: 2px solid $b-9;
    border-radius: 10px
}

.Right:deep(.v-btn .v-btn__content) {
    color: $b-9;
}

.Right:deep(.v-btn.important .v-btn__content) {
    color: #ffe657;
}

.Right:deep(.v-btn.on .v-btn__content) {
    color: $b-9;
}
</style>