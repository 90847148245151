<template>
    <div class="TheView">
        <div class="buttons1" v-if="often.length > 0">
            <button @click="tab1='often'" :class="{ 'selected' : tab1 == 'often'}">자주</button>
            <button @click="tab1='history'" :class="{ 'selected' : tab1 == 'history'}">최근</button>
        </div>
        <SideMenuCard v-if="often.length > 0 && tab1 == 'often'">
            <v-list dense nav class="menu">
                <v-list-item v-for="menu2 in often" :key="menu2.title" link @click="go(menu2)">
                    <template v-slot:prepend>
                        <div class="icon">
                            <img :src="menu2.img" :alt="menu2.title" v-if="menu2.img != undefined">
                            <v-icon size="30" v-if="menu2.icon != undefined">{{ menu2.icon }}</v-icon>
                        </div>
                    </template>

                    <v-list-item-title v-text="menu2.title"></v-list-item-title>
                </v-list-item>
            </v-list>
        </SideMenuCard>

        <SideMenuCard v-if="history.length > 0 && tab1 == 'history'">
            <v-list dense nav class="menu">
                <v-list-item v-for="menu2 in history" :key="menu2.title" link @click="go(menu2)">
                    <template v-slot:prepend>
                        <div class="icon">
                            <img :src="menu2.img" :alt="menu2.title" v-if="menu2.img != undefined">
                            <v-icon size="30" v-if="menu2.icon != undefined">{{ menu2.icon }}</v-icon>
                        </div>
                    </template>

                    <v-list-item-title v-text="menu2.title"></v-list-item-title>
                </v-list-item>
            </v-list>
        </SideMenuCard>

        <SideMenuCard v-for="menu in menus" :title="menu.title" :key="menu.title">
            <v-list dense nav class="menu">
                <v-list-item
                    v-for="menu2 in menu.menus.filter((item) => (item.type == undefined || (user != null && item.type == user.type)))"
                    :key="menu2.title" link @click="go(menu2)">
                    <template v-slot:prepend>
                        <div class="icon">
                            <img :src="menu2.img" :alt="menu2.title" v-if="menu2.img != undefined">
                            <v-icon size="30" v-if="menu2.icon != undefined">{{ menu2.icon }}</v-icon>
                        </div>
                    </template>

                    <v-list-item-title v-text="menu2.title"></v-list-item-title>
                </v-list-item>
            </v-list>
        </SideMenuCard>
    </div>
</template>
<script>
import { defineComponent } from "vue";
import SideMenuCard from './SideMenuCard.vue';
import storage from '@/assets/js/storage';

import moment from 'moment';

export default defineComponent({
    name: 'SideMenu',
    components: {
        SideMenuCard
    },
    data: () => ({
        tab1: 'often',
        db: {
            count: {},
            last_access: {},
            menu: {}
        },
        menus: [
            {
                title: '공지사항',
                menus: [
                    {
                        img: require('@/assets/images/menu_icon/menu_1-1.svg'),
                        title: "안전서한",
                        path: "/notice/safety_letter",
                        id: "notice_safety_letter"
                    },
                    {
                        img: require('@/assets/images/menu_icon/menu_1-2.svg'),
                        title: "회수/폐기",
                        path: "/notice/discards",
                        id: "notice_discards"
                    }
                    /*
                    ,
                    {
                        img: require('@/assets/images/menu_icon/menu_1-2.svg'),
                        title: "테스트",
                        path: "/about",
                    },
                    {
                        img: require('@/assets/images/menu_icon/menu_1-2.svg'),
                        title: "푸시안내팝업",
                        path: "",
                    }*/
                ]
            },
            {
                title: '약력관리',
                menus: [
                    {
                        img: require('@/assets/images/menu_icon/menu_2-1.svg'),
                        title: "내 약 관리",
                        path: "/my/drug",
                        id: "my_drug"
                    },
                    {
                        img: require('@/assets/images/menu_icon/menu_2-2.svg'),
                        title: "복용알림",
                        path: "/my/dose",
                        id: "my_dose"
                    },
                    {
                        img: require('@/assets/images/menu_icon/menu_2-3.svg'),
                        title: "검색기록",
                        path: "/my/drug/history",
                        id: "my_drug_history",
                    },
                    {
                        img: require('@/assets/images/menu_icon/menu_2-4.svg'),
                        title: "내 건강정보",
                        path: "/my/health",
                        id: "my_health",
                    },
                    {
                        img: require('@/assets/images/menu_icon/menu_2-5.svg'),
                        title: "관심의약품",
                        path: "/my/drug/bookmark",
                        type: "E",
                        id: "my_drug_bookmark",
                    },
                    {
                        img: require('@/assets/images/menu_icon/menu_2-6.svg'),
                        title: "의약품메모",
                        path: "/my/drug/memo",
                        id: "my_drug_memo",
                    },
                ]
            },
            {
                title: '의약품 검색',
                menus: [
                    {
                        img: require('@/assets/images/menu_icon/menu_3-1.svg'),
                        title: "고급검색",
                        path: "",
                        id: "search_advanced"
                    },
                    {
                        img: require('@/assets/images/menu_icon/menu_3-2.svg'),
                        title: "낱알검색",
                        path: "",
                        id: "search_shape"
                    },
                    {
                        img: require('@/assets/images/menu_icon/menu_3-3.svg'),
                        title: "QR검색",
                        path: "",
                        id: "search_qr"
                    },
                    {
                        img: require('@/assets/images/menu_icon/menu_3-4.svg'),
                        title: "음성검색",
                        path: "",
                        id: "search_voice"
                    },
                ]
            },
            {
                title: '부가기능',
                menus: [
                    {
                        img: require('@/assets/images/menu_icon/menu_4-1.svg'),
                        title: "환자약력 조회",
                        path: "/expert/share",
                        type: "E",
                        id: "expert_share"
                    },
                    {
                        img: require('@/assets/images/menu_icon/menu_4-2.svg'),
                        title: "내 약력 전송",
                        path: "",
                        id: "send_my_health"
                    },
                    {
                        img: require('@/assets/images/menu_icon/menu_4-3.svg'),
                        title: "안전성 서한 알림이력",
                        path: "/my/notification/safety_letter",
                        type: "E",
                        id: "my_notification_safety_letter"
                    },

                    {
                        img: require('@/assets/images/menu_icon/menu_4-4.svg'),
                        title: "수어영상",
                        path: "/edu/sign",
                        id: "edu_sign"
                    },
                    {
                        img: require('@/assets/images/menu_icon/menu_4-5.svg'),
                        title: "교육자료",
                        path: "/edu/data",
                        id: "edu_data"
                    },
                    {
                        img: require('@/assets/images/menu_icon/menu_4-6.svg'),
                        title: "건강기능 식품 주의 정보",
                        path: "/food/warning",
                        id: "food_warning",
                    },
                    {
                        img: require('@/assets/images/menu_icon/menu_4-7.svg'),
                        title: "학술정보",
                        path: "/edu/link",
                        type: "E",
                        id: "edu_link",
                    },
                    {
                        icon: 'mdi-forum-outline',
                        title: "1:1 문의",
                        path: "/my/qna",
                        id: "my_qna"
                    },
                    {
                        img: require('@/assets/images/menu_icon/menu_4-8.svg'),
                        title: "도움말",
                        path: "/help",
                        id: "help"
                    },
                ]
            }
        ]
    }),
    computed: {
        often() {
            var list = [];

            this.menus.forEach(menu => {
                menu.menus.forEach(item => {
                    if (item.id != undefined && this.db.count[item.id] != undefined) {
                        item.count = this.db.count[item.id];
                        list.push(item)
                    }
                })
            })

            list.sort(function (a, b) {
                if(a.count < b.count) return 1;
                else if(a.count > b.count) return -1;
                return 0;
            });

            if (list.length > 4) return list.slice(0, 4);
            else return list;
        },
        history() {
            var list = [];

            this.menus.forEach(menu => {
                menu.menus.forEach(item => {
                    if (item.id != undefined && this.db.last_access[item.id] != undefined) {
                        item.last_access = this.db.last_access[item.id];
                        list.push(item)
                    }
                })
            })

            list.sort(function (a, b) {
                if(a.last_access < b.last_access) return 1;
                else if(a.last_access > b.last_access) return -1;
                return 0;
            });

            if (list.length > 4) return list.slice(0, 4);
            else return list;
        },
        user() {
            return storage.user();
        },

    },
    mounted() {
        var _db = storage.side_menu();
        if(_db != null) {
            this.db = _db;
            this.db.menu = {};
        }
        
        this.menus.forEach(menu => {
            menu.menus.forEach(item => {
                if (item.id != undefined) {
                    this.db.menu[item.id] = item;
                }
            })
        })
    },
    emits: ['click-menu'],
    methods: {
        go(menu) {
            if (menu.id != undefined) {
                if (this.db.count[menu.id] == undefined) this.db.count[menu.id] = 1
                else this.db.count[menu.id]++;
                this.db.last_access[menu.id] = moment().toISOString();
            }
            //localStorage.setItem('menu', JSON.stringify(this.db));
            /*count: {},
            last_access: {}, */
            storage.side_menu({
                count:this.db.count,
                last_access:this.db.last_access
            })

            this.$emit('click-menu', menu)
        },
        menuFilter() {

        }
    }
})
</script>
<style lang="scss" scoped>
@import "@/assets/css/_variables.scss";
@import "@/assets/css/_mixins.scss";

.TheView {
    padding: 20px 8px;
    height: calc(var(--app-height) - 56px);
    overflow-y: auto;
}

@media (min-width: 300px) {
    .TheView {
        padding: 20px 18px;
    }
}


.buttons1 {
    button {
        padding:3px 15px;
        border-radius: 10px;
        border:1px solid #94A3B8;
        font-size:12px;
        margin-right:10px;
    }

    button.selected {
        background-color: #94A3B8;
        color:white;
    }
}

.menu {
    background: transparent;
}

.menu .icon {
    width: 100%;
    text-align: center;
}


.menu .v-list-item {
    display: inline-block;
    width: 25%;
    text-align: center;
    padding: 15px 0;
    border: 8px;
    vertical-align: top;
    margin-bottom: 0px;
}

.menu .v-list-item>div.v-list-item__prepend {
    display: inline-block !important;
}

.menu .v-list-item--nav .v-list-item-title {
    word-break: break-word;
    white-space: normal;
}

.icon img {
    width: 30px;
    height:30px;
}
</style>