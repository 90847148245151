<template>
    <div class="SpeechRecon">
        <div class="ok" v-if="has_permission">
            <GuideSpan class="g1">의약품의 제품명이나 성분명<br>또는 제조사명을 말해주세요.</GuideSpan>
            <GuideSpan class="g2">예) 부루펜</GuideSpan>
            <div class="animate">
                <AudioWaveComp ref="wave" :is_record="true" @send_blob="receive_blob" @has_permission="set_permission">
                </AudioWaveComp>
            </div>
            <GuideSpan class="result" v-if="is_speaking">말해주세요.</GuideSpan>
            <GuideSpan class="result" v-if="waiting">분석 중</GuideSpan>
            <GuideSpan class="result">{{ speak_text }}</GuideSpan>

            <div class="foot_button type3">
                <button @:click="retry" :disabled="is_speaking">다시 시도</button>
                <button @:click="search" :disabled="is_speaking">검색</button>
                <button @:click="close">닫기</button>
            </div>
        </div>
        <div class="error" v-if="!has_permission">
            <GuideSpan class="g1">마이크 사용권한이 필요합니다.<br>아래와 같이 브라우저의 사용권한을 허용해주세요.</GuideSpan>
            크롬, 사파리, 삼성인터넷 등 상황에 맞는 이미지 캡셔해서 삽입할 예정
        </div>
    </div>
</template>
<script>
import GuideSpan from './GuideSpan.vue'
import AudioWaveComp from './AudioWaveComp.vue';

import analytics from "@/assets/js/analytics";

export default {
    name: 'SpeechRecon',
    components: {
        AudioWaveComp,
        GuideSpan
    },
    data: () => ({
        is_speaking: false,
        is_stop: false,
        speak_text: '',
        waiting: false,
        has_permission: true
    }),
    mounted() {
        var vm = this;
        setTimeout(function () {
            vm.retry();
        }, 100);
    },
    methods: {
        retry() {
            if (!this.is_speaking)
                this.is_speaking = true;
        },
        search() {
            if (this.speak_text.length < 2) {
                this.$alert('두글자 이상 검색가능합니다.')
                return;
            }
            this.$emit('search', this.speak_text)
        },
        close() {
            this.is_stop = true;
            this.$refs.wave.stop();
            this.$emit('close')
        },
        stop() {
            if (this.$refs.wave != undefined) this.$refs.wave.stop();
        },
        log(text) {
            var p_id = this.$root.popup_id['SEARCH_VOICE'] != undefined ? this.$root.popup_id['SEARCH_VOICE'] : '';
            analytics.log_h('H_VOICE', p_id, { st: text, api: 'etri' });
        },
        receive_blob(blob) {
            if (this.is_stop) return;
            this.is_speaking = false;
            if (blob == null) {
                this.$alert('음성을 분석할 수 없습니다. 다시 해주세요.')
                return;
            }

            this.waiting = true;

            if (!this.is_stop) {
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = () => {
                    const base64data = reader.result;

                    this.$api.test.speech({}, {}, { test: 'test', 'blob': base64data }).then(response => {
                        this.waiting = false;
                        this.speak_text = response.data.data.return_object.recognized;
                        // 음성인식 로그
                        this.log(this.speak_text);
                    }).catch(e => {
                        console.error(e);
                        this.$alert('음성을 분석할 수 없습니다. 다시 해주세요.')
                        this.waiting = false;
                    })
                }
            }

        },
        set_permission(val) {
            this.has_permission = val;
        }
    },
    watch: {
        is_speaking(val) {
            if (val) {
                this.speak_text = '';
                this.$refs.wave.start();
            } else {
                this.$refs.wave.stop();
            }
        }
    }
}
</script>
<style lang="scss" scoped>
@import "@/assets/css/_variables.scss";
@import "@/assets/css/_mixins.scss";

.GuideSpan.g1 {
    color: #334155;
}

.GuideSpan.g2 {
    color: #64748b;
}

.animate {
    display: block;
    width: 300px;
    height: 100px;
    margin: 20px auto;
    background-color: #F3F4F6;
}

.foot_button {
    position: absolute;
    width: calc(100% - 40px);
    bottom: 0px;
}

.foot_button button:disabled {
    color: grey
}
</style>