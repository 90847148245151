import moment from "moment";
import { v4 as uuidv4 } from "uuid";

import Swal from "sweetalert2/dist/sweetalert2.js";

import NB from "@/assets/data/NB.json";
import PN from "@/assets/data/PN.json";

var arr_regex = [
    /<!--(.*?)-->/g, //주석 블럭 제거
    /<.*?>/g, //html 태그 제거
    /^\(?[0-9]*\.?[0-9]?\)?\s*/g, //넘버링 제거
    /^\(?주[0-9]\.?[0-9]?\)?\s*/g,
    /\./g, // . 제거
    / /g, // 공백 제거
    /"/g, // " 제거
    /&lt;/g,
    /&gt;/g,
    /&nbsp;/g,
    /&#x[a-fA-F0-9][a-fA-F0-9][a-fA-F0-9][a-fA-F0-9];/g, // html hex code 삭제
];

var nb_document = [
    {
        title: "경고",
        articles: [{ title: "경고", paragraphs: [], identifier: "17020100" }],
    },
    {
        title: "일반적 주의",
        articles: [
            { title: "일반적 주의", paragraphs: [], identifier: "17020500" },
            {
                title: "환자를 위한 정보",
                paragraphs: [],
                identifier: "17020501",
                offset: 1,
            },
            {
                title: "운전 및 기계 조작 시 영향",
                paragraphs: [],
                identifier: "17020502",
                offset: 1,
            },
        ],
    },
    {
        title: "다음 환자에는 투여하지 말 것",
        articles: [
            {
                title: "다음 환자에는 투여하지 말 것",
                paragraphs: [],
                identifier: "17020200",
            },
        ],
    },
    {
        title: "다음 환자에는 신중히 투여할 것",
        articles: [
            {
                title: "다음 환자에는 신중히 투여할 것",
                paragraphs: [],
                identifier: "17020300",
            },
        ],
    },
    {
        title: "이상반응",
        articles: [{ title: "이상반응", paragraphs: [], identifier: "17020400" }],
    },
    {
        title: "상호작용",
        articles: [{ title: "상호작용", paragraphs: [], identifier: "17020600" }],
    },
    {
        title: "특정 집단에서의 사용",
        articles: [
            { title: "특정 집단", paragraphs: [], identifier: "17020700" },
            { title: "임부", paragraphs: [], identifier: "17020701", offset: 1 },
            { title: "수유부", paragraphs: [], identifier: "17020702", offset: 1 },
            {
                title: "가임기 환자",
                paragraphs: [],
                identifier: "17020703",
                offset: 1,
            },
            { title: "소아", paragraphs: [], identifier: "17020704", offset: 1 },
            { title: "고령자", paragraphs: [], identifier: "17020705", offset: 1 },
            { title: "신장애", paragraphs: [], identifier: "17020706", offset: 1 },
            { title: "간장애", paragraphs: [], identifier: "17020707", offset: 1 },
        ],
    },
    {
        title: "임상검사치에의 영향",
        articles: [
            { title: "임상검사치에의 영향", paragraphs: [], identifier: "17020800" },
        ],
    },
    {
        title: "과량투여시의 처치",
        articles: [
            { title: "과량투여시의 처치", paragraphs: [], identifier: "17020900" },
        ],
    },
    {
        title: "보관, 적용, 취급상의 주의사항",
        articles: [
            { title: "적용상의 주의", paragraphs: [], identifier: "17021000" },
            {
                title: "보관 및 취급상의 주의사항",
                paragraphs: [],
                identifier: "17021100",
            },
        ],
    },
    {
        title: "전문가를 위한 정보",
        articles: [
            { title: "전문가를 위한 정보", paragraphs: [], identifier: "17021200" },
            { title: "약리작용", paragraphs: [], identifier: "17021201", offset: 1 },
            {
                title: "약동학적 정보",
                paragraphs: [],
                identifier: "17021202",
                offset: 1,
            },
            {
                title: "독성시험 정보",
                paragraphs: [],
                identifier: "17021203",
                offset: 1,
            },
            {
                title: "임상시험 정보",
                paragraphs: [],
                identifier: "17021204",
                offset: 1,
            },
            {
                title: "의약품동등성시험 정보",
                paragraphs: [],
                identifier: "17021205",
                offset: 1,
            },
        ],
    },
    {
        title: "기타",
        articles: [
            { title: "기타", paragraphs: [], identifier: "17021300" },
            { title: "남용 및 의존성", paragraphs: [], identifier: "17021400" },
        ],
    },
];

const methods = {
    swal: {
        opt: {
            confirmButtonText: "예",
            confirmButtonAriaLabel: "예",
            cancelButtonText: "아니요",
            cancelButtonAriaLabel: "아니요",
            showCancelButton: false,
            scrollbarPadding: false,
            focusConfirm: true,
            heightAuto: false,
            customClass: {
                cancelButton: "btn_cancel",
                confirmButton: "btn_confirm",
                denyButton: "btn_deny",
            },
        },
        alert: (text) => {
            var opt = JSON.parse(JSON.stringify(methods.swal.opt));
            opt.focusConfirm = true;

            if (typeof text == "string") opt.text = text;
            else opt = text;
            return new Promise((resolve) => {
                Swal.fire(opt).then((result) => {
                    resolve(result);
                });
            });
        },
        confirm: (text) => {
            var opt = JSON.parse(JSON.stringify(methods.swal.opt));
            opt.showCancelButton = true;

            if (typeof text == "string") opt.text = text;
            else {
                Object.keys(text).forEach((key) => {
                    opt[key] = text[key];
                });
            }
            return new Promise((resolve) => {
                Swal.fire(opt).then((result) => {
                    resolve(result);
                });
            });
        },
    },
    isApple: () => {
        if (navigator.vendor.indexOf("Apple") > -1) return true;
        return false;
    },
    isNumber: (str) => {
        if (typeof str == "number") return true;
        else if (typeof str == "string") {
            str = str.trim();
            if (str == "") return false;
            if (isNaN(Number(str))) return false;
            return true;
        } else return false;
    },
    clone: (obj) => {
        return JSON.parse(JSON.stringify(obj));
    },
    clean_item_name(txt) {
        if(txt.indexOf('(') != -1) {
            txt = txt.substring(0, txt.indexOf('('));
        }
        return txt;
    },
    formatDate: (obj) => {
        var val = obj;
        val = String(val);
        val = val.replace(" (한국 표준시)", "");
        return moment(val).format("YYYY-MM-DD");
    },
    playVoice: (txt) => {
        var synth = window.speechSynthesis;

        const utterThis = new SpeechSynthesisUtterance(txt);

        utterThis.onerror = function (event) {
            console.error("SpeechSynthesisUtterance.onerror");
            console.error(event);
        };

        var voices = synth.getVoices();
        for (let i = 0; i < voices.length; i++) {
            if (
                voices[i].name.indexOf("한국") != -1 ||
                voices[i].name.indexOf("korea") != -1 ||
                voices[i].name.indexOf("Korea") != -1
            ) {
                utterThis.voice = voices[i];
                i = voices.length;
            }
        }

        utterThis.pitch = 1; //pitch.value;
        utterThis.rate = 1; //rate.value;
        synth.speak(utterThis);
    },
    extract_qr_data: (q1, q2) => {
        if (q1 == undefined) q1 = "";
        if (q2 == undefined) q2 = "";
        var qrCodeMessage = q1 + String.fromCharCode(29) + q2;
        var edi_code = null;
        var expired_date = null;
        var lot = null;
        var sn = null;
        //var make_date = null;

        while (qrCodeMessage.length > 4) {
            if (qrCodeMessage.charCodeAt(0) == 29)
                qrCodeMessage = qrCodeMessage.substring(1);

            var he = qrCodeMessage.substring(0, 2);
            qrCodeMessage = qrCodeMessage.substring(2);

            if (he == "01") {
                edi_code = qrCodeMessage.substring(0, 14);
                qrCodeMessage = qrCodeMessage.substring(14);
            } else if (he == "02") {
                qrCodeMessage = qrCodeMessage.substring(14);
            } else if (he == "10") {
                var indx = qrCodeMessage.indexOf(String.fromCharCode(29));
                if (indx != -1) {
                    lot = qrCodeMessage.substring(0, indx);
                    qrCodeMessage = qrCodeMessage.substring(indx);
                } else {
                    lot = qrCodeMessage;
                    qrCodeMessage = "";
                }
            } else if (he == "11") {
                //make_date = qrCodeMessage.substring(0, 6);
                qrCodeMessage = qrCodeMessage.substring(6);
            } else if (he == "15") {
                qrCodeMessage = qrCodeMessage.substring(6);
            } else if (he == "17") {
                expired_date = qrCodeMessage.substring(0, 6);
                qrCodeMessage = qrCodeMessage.substring(6);
            } else if (he == "21") {
                var indx2 = qrCodeMessage.indexOf(String.fromCharCode(29));
                if (indx2 != -1) {
                    sn = qrCodeMessage.substring(0, indx2);
                    qrCodeMessage = qrCodeMessage.substring(indx2);
                } else {
                    sn = qrCodeMessage;
                    qrCodeMessage = "";
                }
            }
        }

        return {
            edi_code: edi_code,
            expired_date: expired_date,
            lot: lot,
            sn: sn,
        };
    },
    qr_decode(text) {
        var arr = text.split("\n");

        var db = {
            hospital: {},
            doctor: {},
            pharm: {},
            cd: [],
            me: [],
            number: "",
        };

        if(arr[0].indexOf("KOQP:") == -1) {
            throw new Error("유효한 처방이력 QR코드가 아닙니다."+arr[0]);
        }

        if(arr[arr.length-1].indexOf("PU:") != 0) {
            throw new Error("유효한 처방이력 QR코드가 아닙니다."+arr[arr.length-1]);
        }

        arr.forEach((line, index) => {
            if (index == 0) {
                db.version = line.substring("KOQP:".length);
            } else {
                if (line.indexOf("DA:") == 0) {
                    var _arr0 = line.substring(3).split("_");
                    if(_arr0.length == 0) {
                        throw new Error("유효한 처방일 정보가 없습니다.");
                    }
                    db.date = _arr0[0];
                    if (_arr0.length == 2) db.number = _arr0[1];
                } else if (line.indexOf("HS:") == 0) {
                    var _arr = line.substring(3).split("_");
                    if(_arr.length == 0) {
                        throw new Error("유효한 병원 정보가 없습니다.");
                    }
                    db.hospital = { name: _arr[0] };
                    if (_arr.length == 2) db.hospital.code = _arr[1];
                } else if (line.indexOf("DC:") == 0) {
                    var _arr1 = line.substring(3).split("_");
                    if(_arr1.length == 0) {
                        throw new Error("유효한 의사 정보가 없습니다.");
                    }
                    db.doctor = { name: _arr1[0] };
                    if (_arr1.length == 2) db.doctor.code = _arr1[1];
                } else if (line.indexOf("PH:") == 0) {
                    var _arr2 = line.substring(3).split("_");
                    if(_arr2.length == 0) {
                        throw new Error("유효한 약국 정보가 없습니다.");
                    }
                    db.pharm = { name: _arr2[0] };
                    if (_arr2.length == 2) db.pharm.code = _arr2[1];
                } else if (line.indexOf("CD:") == 0) {
                    db.cd = line.substring(3).split(",");
                    if(db.cd.length == 0) delete db.cd;
                } else if (line.indexOf("ME:") == 0) {
                    var _arr3 = line.substring(3).split("_");
                    var me = {
                        edi_code: _arr3[0],
                        quantity: _arr3[1],
                        rate: _arr3[2],
                        days: _arr3[3],
                        usage: _arr3.length == 5 ? _arr3[4] : "",
                    };

                    if(!this.isNumber(me.quantity)) throw new Error("유효한 1회 투여량이 아닙니다.");
                    if(!this.isNumber(me.rate)) throw new Error("유효한 1일 투여횟수가 아닙니다.");
                    if(!this.isNumber(me.days)) throw new Error("유효한 투여일수가 아닙니다.");

                    db.me.push(me);
                } else if (line.indexOf("US:") == 0) {
                    //db.usage = line.substring(3);
                } else if (line.indexOf("PU:") == 0) {
                    db.pu = line.substring(3);
                }
            }
        });

        if(arr.length > 2 && db.me.length == 0) {
            throw new Error("처방의약품 정보가 없습니다.");
        }

        return db;
    },
    ng_key(str) {
        arr_regex.forEach((re) => {
            str = str.replace(re, "");
        });
        return str;
    },
    make_nb(item) {
        var document = JSON.parse(JSON.stringify(nb_document));

        if (
            item.nb_doc_data != undefined &&
            item.nb_doc_data.sections != undefined
        ) {
            item.nb_doc_data.sections.forEach((section) => {
                section.articles.forEach((article) => {
                    var nkey = methods.ng_key(article.title);
                    if (NB[nkey] != undefined) {
                        NB[nkey]
                            .replace(/ /g, "")
                            .split(",")
                            .forEach((id) => {
                                make_nb(document, article, id);
                            });
                    }
                });
            });
        }

        if (
            item.pn_doc_data != undefined &&
            item.pn_doc_data.sections != undefined
        ) {
            item.pn_doc_data.sections.forEach((section) => {
                section.articles.forEach((article) => {
                    var nkey = methods.ng_key(article.title);
                    if (PN[nkey] != undefined) {
                        PN[nkey]
                            .replace(/ /g, "")
                            .split(",")
                            .forEach((id) => {
                                make_nb(document, article, id);
                            });
                    }
                });
            });
        }
        return document;
    },
    item_image_rewrite(url) {
        if(url != undefined && url != null) {
            return url.replace("https://nedrug.mfds.go.kr/pbp/cmn/itemImageDownload/","https://img7.edrugsafe.org/drug/b/");
        }
        return url;
    },
    modal: {
        is_open: false,
        is_menu_open: false,
        scrollTop: 0,
        scrollLeft: 0,
        list: [],
        last_time: function () {
            if (this.list.length == 0) return null;
            else return this.list[this.list.length - 1];
        },
        close() {
            this.list.pop();
            this.z_index--;
            if (this.list.length == 0) {
                this.is_open = false;
                this.z_index = 100;
                document.documentElement.classList.remove('no_scroll');
                document.body.classList.remove('no_scroll');
            }
        },
        open() {
            if(this.z_index == 100) {
                document.documentElement.classList.add('no_scroll');
                document.body.classList.add('no_scroll');
            }
            /*
            this.scrollTop =
                window.pageYOffset ||
                document.documentElement.scrollTop;
            this.scrollLeft =
                window.pageXOffset ||
                document.documentElement.scrollLeft;*/
   
        },
        menu_close() {
            this.is_menu_open = false;
            if (this.list.length == 0) {
                document.documentElement.classList.remove('no_scroll');
                document.body.classList.remove('no_scroll');
            }
        },
        menu_open() {
            this.is_menu_open = true;
            this.open();
        },
        modal_open() {
            this.is_open = true;
            this.open();
            this.z_index++;
        },
        z_index:100
    },
    history: {
        list: []
    },
    checkIdPasswd(id, passwd) {
        if (id == undefined || id.length < 5) return false;

        for (var i = 0; i < id.length - 4; i++) {
            var c = id.substring(i, (i + 5));
            if (passwd.indexOf(c) != -1) return false;
        }
        return true;
    },
    checkPasswdContinues(input) {
        if (typeof input !== 'string' || input.length < 3) {
            return false;
        }

        for (let i = 0; i < input.length - 2; i++) {
            const currentSubstring = input.slice(i, i + 3);
            const char1 = currentSubstring[0];
            const char2 = currentSubstring[1];
            const char3 = currentSubstring[2];

            if (char2.charCodeAt(0) === char1.charCodeAt(0) + 1 && char3.charCodeAt(0) === char2.charCodeAt(0) + 1) {
                return false;
            }
        }

        return true;
    },
    uuid() {
        return uuidv4().replace(/-/g, '');
    }
};

function make_nb(document, article, identifier) {
    if (identifier != undefined) {
        document.forEach((sec) => {
            sec.articles.forEach((art) => {
                if (art.identifier == identifier) {
                    art.paragraphs.push(...article.paragraphs);
                    sec.is_valid = true;
                }
            });
        });
    }
}

export default methods;
