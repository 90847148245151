<template>
    <div class="DrugComp" :class="fontClass">
        <div v-if="is_valid == false" class="not_found">해당 의약품 정보를 찾을 수 없습니다.</div>
        <div class="drugDetail" v-if="!is_loading">
            <TopTitle :item="medicine" :user="user" :is_bookmarked="is_bookmarked" @click_bookmark_on="bookmark_on"
                @click_bookmark_off="bookmark_off" @click_memo="memo" @click_taken="taken" :key="time1" />
            <ImageComp :item="medicine" :charts="charts" @click_image="click_image" />
            <QRInfoBox :item="medicine" />
            <SafetyLetter :discards="discards" :safety_letter="safety_letter" />
            <div v-if="medicine_memo != null" class="user_memo" :class="{ 'important': medicine.memo_is_important == 1}">
                <div v-if="medicine_memo['용도'] != undefined">
                    <strong>용도</strong>
                    <span v-for="text0 in medicine_memo['용도']" :key="text0.memo">{{ text0.memo }}</span>
                </div>
                <div v-if="medicine_memo['복용법'] != undefined">
                    <strong>복용법</strong>
                    <span v-for="text0 in medicine_memo['복용법']" :key="text0.memo">{{ text0.memo }}</span>
                </div>
                <div v-if="medicine_memo['부작용'] != undefined">
                    <strong>부작용</strong>
                    <span v-for="text0 in medicine_memo['부작용']" :key="text0.memo">{{ text0.memo }}</span>
                </div>
                <div v-if="medicine_memo['알러지'] != undefined">
                    <strong>알러지</strong>
                    <span v-for="text0 in medicine_memo['알러지']" :key="text0.memo">{{ text0.memo }}</span>
                </div>
                <div v-if="medicine_memo['기타'] != undefined">
                    <strong>기타</strong>
                    <span v-for="text0 in medicine_memo['기타']" :key="text0.memo">{{ text0.memo }}</span>
                </div>
            </div>
        </div>

        <div v-if="!is_loading && easy.item_seq != undefined">
            <v-tabs v-model="tab" class="tabWrap" dense grow hide-slider>
                <v-tab class="tab" selected-class="one" value="one">E약은요</v-tab>
                <v-tab class="tab" selected-class="two" value="two">상세정보(허가사항)</v-tab>
            </v-tabs>
            <v-window v-model="tab" class="tab_window">
                <v-window-item value="one">
                    <EasyComp :easy="easy" :key="time2" v-if="time2 > 0" />
                </v-window-item>
                <v-window-item value="two">
                    <DetailComp :item="medicine" :key="time3" v-if="time3 > 0" />
                </v-window-item>
            </v-window>
        </div>
        <div v-if="!is_loading && easy.item_seq == undefined">
            <DetailComp :item="medicine" :key="time3" v-if="time3 > 0" />
        </div>

        <DialogComp v-model="v_new" title="복용의약품 추가" fullscreen class="no_actions">
            <template v-slot:body>
                <TakeForm :type="3" :item="medicine" @saved="saved" />
            </template>
        </DialogComp>

        <DialogComp v-model="v_memo" title="의약품 메모" class="no_actions" min-width="300" max-width="600">
            <template v-slot:body>
                <TakeMemo :item_seq="medicine.item_seq" @close="v_memo = false" />
            </template>
        </DialogComp>
    </div>
</template>
<script>
import moment from 'moment';

import DetailComp from '@/components/drug/DetailComp.vue'
import DialogComp from '@/components/DialogComp.vue'
import EasyComp from '@/components/drug/EasyComp.vue'
import ImageComp from '@/components/drug/ImageComp.vue'
import QRInfoBox from '@/components/drug/QRInfoBox.vue'
import SafetyLetter from '@/components/drug/SafetyLetterBox.vue'
import TakeForm from '@/components/take/TakeForm.vue'
import TakeMemo from '@/components/drug/TakeMemo.vue'
import TopTitle from '@/components/drug/TopTitle.vue'

import analytics from "@/assets/js/analytics";

import ng from '@/assets/js/ng';
import storage from '@/assets/js/storage';

export default {
    name: "drugView",
    components: {
        DetailComp,
        DialogComp,
        EasyComp,
        ImageComp,
        QRInfoBox,
        SafetyLetter,
        TakeForm,
        TakeMemo,
        TopTitle
    },
    props: {
        item_seq: {
            type: String,
            default: '201309963'
        },
        q1: {
            type: String,
            default: ''
        },
        q2: {
            type: String,
            default: ''
        },
    },
    data: () => ({
        is_valid: true,
        is_bookmarked: false,
        is_loading: true,
        backup: {},
        charts: [],
        discards: [],
        easy: [],
        item_image: '', /* 팝업용 아이템 이미지 링크 */
        medicine: {
            shape: {}
        },
        medicine_memo: null,
        safety_letter: [],
        tab: 'one',
        time1: 0,
        time2: 0,
        time3: 0,
        user: {},
        v_memo: false,
        v_new: false
    }),
    computed: {
        fontClass() {
            var obj = {};
            obj['dt_' + this.$root.setting.font_size] = true
            return obj
        }
    },
    mounted() {
        if (this.item_seq == '') {
            this.is_valid = false;
            return;
        }

        var yyyymmdd = null;
        var pl_num = null;
        var sn = null;

        var ext_data = ng.extract_qr_data(this.q1, this.q2);

        if (ext_data.expired_date != null) yyyymmdd = ext_data.expired_date;
        if (ext_data.lot != null) pl_num = ext_data.lot;
        if (ext_data.sn != null) sn = ext_data.sn;

        //yyyymmdd = '240301'
        //pl_num = '123456'
        //sn = 'A001'

        this.$api.medicine.detail({ code: this.item_seq }).then(response => {
            if (response.data.data == undefined) this.is_valid = false;
            else {

                var p_id = this.$root.popup_id['DRUG'] != undefined ? this.$root.popup_id['DRUG'] : '';
                delete this.$root.popup_id['DRUG'];


                this.is_loading = false;
                this.medicine = response.data.data;

                analytics.log_h('H_DRUG', p_id, {
                    item_seq: this.medicine.item_seq
                });
                if (p_id != '') analytics.log_p('P_DRUG.ACT', p_id, {
                    item_seq: this.medicine.item_seq
                });

                if (yyyymmdd != null) this.medicine.expiration_date = yyyymmdd;
                if (pl_num != null) this.medicine.pl_num = pl_num;
                if (sn != null) this.medicine.sn = sn;

                if (this.medicine.expiration_date !== undefined) {
                    this.medicine.expiration_date_diff = moment('20' + this.medicine.expiration_date).diff(moment(), 'days');
                }

                this.reload_memo();

                this.$api.medicine.shapes({ code: this.item_seq }).then(shapes => {
                    if (this.medicine.ee_doc_id != undefined) {
                        this.medicine.ee_doc_id = this.medicine.ee_doc_id.replace('pdfDownload', 'pdf/drb')
                    }

                    if (this.medicine.ud_doc_id != undefined) {
                        this.medicine.ud_doc_id = this.medicine.ud_doc_id.replace('pdfDownload', 'pdf/drb')
                    }

                    if (this.medicine.nb_doc_id != undefined) {
                        this.medicine.nb_doc_id = this.medicine.nb_doc_id.replace('pdfDownload', 'pdf/drb')
                    }

                    if (this.medicine.pn_doc_id != undefined) {
                        this.medicine.pn_doc_id = this.medicine.pn_doc_id.replace('pdfDownload', 'pdf/drb')
                    }

                    if (shapes.data.data.length > 1) {
                        this.charts = shapes.data.data;
                        var db_chart = {};
                        var db_shape = {};
                        this.charts.forEach(chart => {
                            db_chart[chart.chart] = true;
                            db_shape[chart.drug_shape] = true;
                        })

                        if (Number(this.medicine.de) > this.charts.length) {
                            this.medicine.de = 1
                        }

                        if (Object.keys(db_chart).length > 1) {
                            this.medicine.charts = Object.keys(db_chart)
                        }

                        if (Object.keys(db_shape).length > 0) {
                            this.medicine.shape.drug_shape = Object.keys(db_shape).join(", ")
                        }
                    }



                    if (this.medicine.atc_code != undefined && this.medicine.atc_code != null && this.medicine.atc_code != '') {
                        this.$api.atc.list({}, { c4: this.medicine.atc_code }).then(response => {
                            if (response.data.data != undefined) {
                                this.medicine.atc_ee = response.data.data.h;
                                this.time3++;
                            }

                        })
                    } else {
                        this.time3++;
                    }


                    Promise.all([
                        this.$api.medicine.discards({ code: this.item_seq }),
                        this.$api.medicine.safety_notices({ code: this.item_seq }),
                        this.$api.medicine.easy({ code: this.item_seq })
                        //api.medicine.dur({code:data.item_seq}),
                    ]).then(responses => {
                        this.discards = responses[0].data.data;
                        this.safety_letter = responses[1].data.data;
                        //this.dur = responses[2].data.data;

                        if (this.discards.length > 0) {
                            this.set_discards(this.discards);
                        }

                        if (responses[2].data.data.length > 0) {
                            this.easy = responses[2].data.data[0];
                            this.backup.easy = JSON.parse(JSON.stringify(this.easy));
                        }

                        this.time2++;
                    }).catch(() => {

                    })
                }).catch(() => {

                })


                this.$api.user.is_login().then(response => {
                    if (response.data.user != undefined) {
                        this.is_login = true;

                        var log_data = { item_seq: this.medicine.item_seq, item_name: this.medicine.item_name, entp_name: this.medicine.entp_name, item_image: '' };
                        if (this.medicine.item_image != undefined) log_data.item_image = this.medicine.item_image;

                        this.$api.my.drug.log.post({}, {}, log_data)

                        this.$api.my.drug.bookmarks.list({}, { item_seq: this.medicine.item_seq }).then(response => {
                            if (response.data.data.length == 0) {
                                this.is_bookmarked = false;
                            } else {
                                this.is_bookmarked = true;
                            }
                        }).catch(err => {
                            console.error(err);
                        })

                        this.$api.my.folder.list().then(response => {
                            this.folders = response.data.data;
                        });
                    }
                });
            }
        }).catch(() => {
            this.is_valid = false;
        })
        this.user = storage.user();
    },
    methods: {
        bookmark_on() {
            this.$confirm(this.medicine.item_name + '을 관심의약품으로 등록할까요?').then(rs => {
                if (rs.isConfirmed) {
                    this.$api.my.drug.bookmarks.insert({}, {}, this.medicine).then(() => {
                        //this.$alert('등록되었습니다');
                        this.is_bookmarked = true;
                        this.time1++;
                    }).catch(err => {
                        console.error(err);
                    })
                }
            });
        },
        bookmark_off() {
            this.$confirm(this.medicine.item_name + '을 관심의약품으로 삭제할까요?').then(rs => {
                if (rs.isConfirmed) {
                    this.$api.my.drug.bookmarks.delete({ item_seq: this.medicine.item_seq }).then(() => {
                        //this.$alert('삭제되었습니다');
                        this.is_bookmarked = false;
                        this.time1++;
                    }).catch(err => {
                        console.error(err);
                    })
                }
            });
        },
        click_image(val) {
            this.$root.show_image(val)
        },
        memo() {
            this.v_memo = true;
        },
        reload_memo() {
            this.$api.my.drug.memo.get({ item_seq: this.item_seq }).then((response_memo => {
                    if (response_memo.data.data != undefined) {
                        this.medicine.memo_is_important = response_memo.data.data.is_important;
                        var medicine_memo = JSON.parse(response_memo.data.data.memo);
                        Object.keys(medicine_memo).forEach(key => {
                            if (medicine_memo[key].length == 1 && medicine_memo[key][0].memo == '') {
                                delete medicine_memo[key];
                            }
                        })
                        this.medicine_memo = medicine_memo;
                    } else {
                        this.medicine_memo = null;
                        delete this.medicine.memo_is_important;
                    }
                })).catch(e => {
                    console.error(e);
                })
        },
        saved() {
            this.v_new = false;
            if (this.$route.path != '/my/drug/takes') {
                this.$confirm('복용의약품 화면으로 이동할까요?').then(rs => {
                    if (rs.isConfirmed) {
                        this.$emit('close');
                        this.$router.push('/my/drug/takes');
                    }
                })
            }
        },
        set_discards(items) {
            items.forEach((item) => {
                if (item.product_num != undefined) {
                    item.product_num = item.product_num
                        .replace(new RegExp("[[]]", "g"), "")
                        .replace(new RegExp(",", "g"), ", ");
                }
            });
        },
        taken() {
            this.v_new = true
        },
        extract_data(q1, q2) {
            if (q1 == undefined) q1 = "";
            if (q2 == undefined) q2 = "";
            var qrCodeMessage = q1 + String.fromCharCode(29) + q2;
            var edi_code = null;
            var expired_date = null;
            var lot = null;
            var sn = null;
            //var make_date = null;

            while (qrCodeMessage.length > 4) {
                if (qrCodeMessage.charCodeAt(0) == 29)
                    qrCodeMessage = qrCodeMessage.substring(1);

                var he = qrCodeMessage.substring(0, 2);
                qrCodeMessage = qrCodeMessage.substring(2);

                if (he == "01") {
                    edi_code = qrCodeMessage.substring(0, 14);
                    qrCodeMessage = qrCodeMessage.substring(14);
                } else if (he == "02") {
                    qrCodeMessage = qrCodeMessage.substring(14);
                } else if (he == "10") {
                    var indx = qrCodeMessage.indexOf(String.fromCharCode(29));
                    if (indx != -1) {
                        lot = qrCodeMessage.substring(0, indx);
                        qrCodeMessage = qrCodeMessage.substring(indx);
                    } else {
                        lot = qrCodeMessage;
                        qrCodeMessage = "";
                    }
                } else if (he == "11") {
                    //make_date = qrCodeMessage.substring(0, 6);
                    qrCodeMessage = qrCodeMessage.substring(6);
                } else if (he == "15") {
                    qrCodeMessage = qrCodeMessage.substring(6);
                } else if (he == "17") {
                    expired_date = qrCodeMessage.substring(0, 6);
                    qrCodeMessage = qrCodeMessage.substring(6);
                } else if (he == "21") {
                    var indx2 = qrCodeMessage.indexOf(String.fromCharCode(29));
                    if (indx2 != -1) {
                        sn = qrCodeMessage.substring(0, indx2);
                        qrCodeMessage = qrCodeMessage.substring(indx2);
                    } else {
                        sn = qrCodeMessage;
                        qrCodeMessage = "";
                    }
                }
            }

            return {
                edi_code: edi_code,
                expired_date: expired_date,
                lot: lot,
                sn: sn,
            };
        }
    },
    watch: {
        tab(val) {
            if (val == 'one') this.time2++;
            else this.time3++;
        },
        v_memo(val) {
            if(val == false) {
                this.reload_memo();
            }
        }
    }
}
</script>
<style lang="scss" scoped>
@import "@/assets/css/_variables.scss";
@import "@/assets/css/_mixins.scss";

.not_found {
    width: 100%;
    margin-top: 50px;
    min-height: 150px;
    text-align: center;
}

.drugDetail>div {
    margin-bottom: 20px;
}


.item_image {
    min-width: 300px;
    min-height: 300px;
    background-size: cover;
}

.tabWrap {
    background-color: $b-g-2;
    border-radius: 8px 8px 0 0;
}

.tab {
    @include H5;
    width: 50%;
    padding: 11px 0;
    color: $b-g-4;
}

.one,
.two {
    @include H5;
    color: $w;
    background-color: $b-8;
}

@media (min-width: 300px) {
    .tab {
        @include H3;
        width: 50%;
        padding: 11px 0;
        color: $b-g-4;
    }

    .one,
    .two {
        @include H3;
        color: $w;
        background-color: $b-8;
    }
}

.itemWrap {
    padding: 10px 15px;
}

.DrugComp {
    font-size: 14px;
}

.DrugComp.dt_1 {
    font-size: 14px;
}

.DrugComp.dt_2 {
    font-size: 16px;

    :deep(.article_title),
    :deep(.article .head .title) {
        font-size: 18px;
    }
}

.DrugComp.dt_3 {
    font-size: 18px;

    :deep(.article_title),
    :deep(.article .head .title) {
        font-size: 18px;
    }
}

.DrugComp.dt_4 {
    font-size: 20px;

    :deep(.article_title),
    :deep(.article .head .title) {
        font-size: 20px;
    }
}

.user_memo {
    border: 1px solid #DBEAFE;
    background-color: #DBEAFE;
    border-radius: 10px;
    padding: 10px;
}

.user_memo.important {
    border: 1px solid #f6f78b;
    background-color: #f6f78b;
}

.user_memo>div {
    margin-bottom: 10px;

    strong {
        display: block
    }

    span {
        display: block;
        padding-left: 5px
    }
}

.user_memo>div:last-child {
    margin-bottom: 0px;
}
</style>