<template>
    <div class="CameraComp">
        <div class="ok" v-if="has_permission">
            <GuideSpan class="top">
                <template v-if="type == 'general'">바코드 또는 QR코드가 화면 중앙에 오도록 촬영해주세요.</template>

                <template v-if="type == 'expert_share'">환자약력조회용 QR코드가 <br>화면 중앙에 오도록 촬영해주세요.</template>

                <template v-if="type == 'new_prescription'">처방전의 QR코드가 화면 중앙에 오도록 촬영해주세요.</template>

                <template v-if="type == 'new_take'">의약품의 바코드 또는 QR코드가<br>화면 중앙에 오도록 촬영해주세요.</template>
            </GuideSpan>
            <div class="guide">
                <div class="title">
                    <GuideSpan class="guide1" @click="toggleSetting">인식이 잘 안되면 설정을 변경해주세요.</GuideSpan>
                    <GuideSpan @click="toggleSetting">
                        <v-icon v-if="setting == 0">mdi-chevron-down</v-icon>
                        <v-icon v-if="setting == 1">mdi-chevron-up</v-icon>
                    </GuideSpan>

                </div>
            </div>
            <div class="setting" v-if="setting == 1">
                <div class="label">카메라 선택</div>
                <div>
                    <v-select v-model="cameraId" :items="cameras" item-title="label" item-value="id" dense outlined
                        hide-details variant="plain">
                    </v-select>
                </div>

                <div class="label">QR코드 박스</div>
                <div class="slider">
                    <span>작게</span>
                    <v-slider v-model="qrbox_size" :min="100" :max="box_size_max" :step="50" color="primary"
                        show-ticks="always" tick-size="4"></v-slider>
                    <span>크게</span>
                </div>
                <div class="label" v-if="zoom.has">ZOOM</div>
                <div class="slider" v-if="zoom.has">
                    <span>축소</span>
                    <v-slider v-model="zoom.current" :min="zoom.min" :max="zoom.max" :step="zoom.step" color="primary"
                        show-ticks="always" tick-size="4"></v-slider>
                    <span>확대</span>
                </div>
                <div class="one_line" v-if="false">
                    <div class="label">조명</div>
                    <div class="torch">
                        <v-switch v-model="is_torch" color="primary" hide-details></v-switch>
                    </div>
                </div>
            </div>

            <div class="cameraWrap">
                <div class="btn_flash" v-if="has_torch">
                    <v-btn density="default" icon="mdi-flash" v-if="is_torch" @click="is_torch = false"></v-btn>
                    <v-btn density="default" icon="mdi-flash-off" v-if="!is_torch" @click="is_torch = true"></v-btn>
                </div>
                <div id="reader" width="600px"></div>
                {{ last_qrcode }}
            </div>
        </div>
        <div class="error" v-if="!has_permission">
            <GuideSpan class="g1">카메라 사용권한이 필요합니다.</GuideSpan>
            안드로이드폰은 크롬이나 삼성인터넷을 통한 '홈 화면에 추가'<br>
            아이폰은 사파리를 '홈화면 추가'<br>
            를 이용하서 접근해주시고, 반드시 카메라 사용 허용해주세요.<br>
            네이버앱이나 카카오톡 등 다른 브라우저를 사용할 경우 정상적으로 사용하실 수 없습니다.
        </div>

    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Html5QrcodeScanType, Html5QrcodeScanner, Html5Qrcode } from "html5-qrcode";

import GuideSpan from '@/components/GuideSpan.vue'

import analytics from "@/assets/js/analytics";
import storage from '@/assets/js/storage'

export default defineComponent({
    name: 'CameraView',
    data: () => ({
        cameraId: null,
        scanner: null,
        is_android: false,
        qrbox_size: '100',
        config: {},
        last_qrcode: '',
        time: 0,
        box_size_max: 0,
        cameras: [],
        db_camera: {},
        setting: 0,
        has_permission: true,
        is_torch: false,
        has_torch: false,
        zoom: {
            current: 2,
            level : 0,
            min: 1,
            max: 4,
            step: 1,
            has: false
        }
    }),
    props: {
        dialog: {
            type: Boolean,
            default: false
        },
        qrbox: {
            type: Number,
            default: 100
        },
        result: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: 'general'
        }
    },
    components: {
        GuideSpan,
    },
    created() {

    },
    mounted() {
        var vm = this;

        this.config = {
            fps: this.fps ? this.fps : 10,
            aspectRatio: 1,
            showTorchButtonIfSupported: false,
            showZoomSliderIfSupported: true,
            defaultZoomValueIfSupported: 3,
            supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_CAMERA],
        };

        var qrbox_width = 250;

        if (document.body.clientWidth < 250) {
            qrbox_width = parseInt(document.body.clientWidth / 100) * 100;
        }
        if (qrbox_width > 350) qrbox_width = 350;

        this.box_size_max = parseInt((document.body.clientWidth - 50) / 50) * 50;
        this.box_size_max = this.box_size_max > 400 ? 400 : this.box_size_max

        this.qrbox_size = qrbox_width.toString();

        if (storage.qrSize() != null) this.qrbox_size = storage.qrSize();
        //this.is_torch = storage.cameraTorch();

        if (storage.cameraZoom() != null) {
            this.zoom.level = storage.cameraZoom();
        }

        this.time++;

        Html5Qrcode.getCameras().then(devices => {
            if (devices.length > 0) {
                vm.cameras = devices;

                vm.cameras.forEach(camera => {
                    var label = camera.label;
                    if (label.indexOf(' (') != -1) {
                        label = camera.label.substring(0, camera.label.indexOf(' ('));
                    }
                    camera.label = label;

                    vm.db_camera[label] = camera;
                    vm.db_camera[camera.id] = camera;
                });

                var cameraId = null;//storage.cameraId();
                var cameraLabel = storage.cameraLabel();
                if (cameraId == undefined) cameraId = devices[devices.length - 1].id;
                if (cameraLabel != undefined && vm.db_camera[cameraLabel] != undefined) {
                    cameraId = vm.db_camera[cameraLabel].id;
                }

                vm.cameraId = cameraId;
                vm.start();
            } else {
                vm.$alert('카메라가 없어서 사용할 수 없습니다.').then(() => {
                    vm.$emit('close');
                });

            }
        }).catch(err => {
            console.error(err);
            vm.has_permission = false;
        });
    },
    methods: {
        close: function () {
            this.stop(function () {
            })
            this.$emit('update:result', 'akakaka');
        },
        log(data) {
            data.type = this.type;
            var p_id = this.$root.popup_id['QR_READ'] != undefined ? this.$root.popup_id['QR_READ'] : '';
            analytics.log_h('H_QR_READ', p_id, data);
        },
        open_drug(d) {
            var data = JSON.parse(JSON.stringify(d));
            data.valid = true;

            if (this.type == 'expert_share') {
                this.$alert('환자약력 조회용 QR코드를 촬영해주세요.')
                data.valid = false;
            } else if (this.type == 'new_take') {
                this.$emit('new_form', d)
            } else if (this.type == 'new_prescription') {
                this.$emit('new_form', d)
            } else {
                this.$emit('open_drug', d)
            }
            this.log(data);
        },
        open_prescription(qrCodeMessage) {
            var data = { qrcode: qrCodeMessage };
            data.valid = true;

            if (this.type == 'expert_share') {
                this.$alert('환자약력 조회용 QR코드를 촬영해주세요.')
                data.valid = false;
            } else if (this.type == 'new_take') {
                this.$alert('의약품의 바코드 또는 QR코드를 촬영해주세요.')
                data.valid = false;
            } else {
                this.$emit('prescription_qr', qrCodeMessage);
            }
            this.log(data);
        },
        open_share(qrCodeMessage) {
            var data = { qrcode: qrCodeMessage };
            data.valid = true;

            var user = storage.user();
            if (user.type == 'E') {
                this.$emit('expert_share', qrCodeMessage.substring(6));
                this.close();
            } else {
                this.$alert('전무가만 사용할 수 있습니다.');
                data.valid = false;
            }
            this.log(data);
        },
        start() {
            this.config["qrbox"] = { width: this.qrbox_size, height: this.qrbox_size };
            var vm = this;

            if (this.is_android) {
                this.scanner = new Html5QrcodeScanner("reader", { fps: 10, qrbox: { width: 250, height: 250 } }, false);
                this.scanner.render(this.scanned, () => { });
            } else {
                this.scanner = new Html5Qrcode(/* element id */ "reader");
                this.scanner.start(vm.cameraId, this.config, function (qrCodeMessage) {
                    try {
                        if (vm.last_qrcode == qrCodeMessage) return;
                        vm.last_qrcode = qrCodeMessage;

                        setTimeout(function () {
                            vm.last_qrcode = null;
                        }, 2000);

                        if (qrCodeMessage.charCodeAt(0) != 29) {
                            qrCodeMessage = qrCodeMessage.trim();
                        }

                        if (
                            qrCodeMessage.charCodeAt(0) == 29 &&
                            qrCodeMessage.substring(1, 3) == "01"
                        ) {
                            var edi_code = qrCodeMessage.substring(4, 17);
                            var q1 = "";
                            var q2 = "";
                            if (qrCodeMessage.length > 17) {
                                var indx = qrCodeMessage.indexOf(String.fromCharCode(29), 1);
                                if (indx != 1) {
                                    q1 = qrCodeMessage.substring(17, indx);
                                    q2 = qrCodeMessage.substring(indx + 1);
                                } else {
                                    q1 = qrCodeMessage.substring(17, indx);
                                }
                            }
                            vm.open_drug({ edi_code, q1, q2 })
                        } else if (qrCodeMessage.startsWith("]") && qrCodeMessage.length > 20) {
                            var _qr = qrCodeMessage.substring(6, 19);
                            vm.open_drug({ edi_code: _qr })
                        } else if (qrCodeMessage.substring(0, 3) == "880") {
                            vm.open_drug({ edi_code: qrCodeMessage })
                        } else if (qrCodeMessage.indexOf("KOQP") == 0) {
                            vm.open_prescription(qrCodeMessage);
                        } else if (qrCodeMessage.indexOf("SHARE:") == 0) {
                            vm.open_share(qrCodeMessage)
                        } else if (qrCodeMessage.indexOf("https://") == 0 || qrCodeMessage.indexOf("http://") == 0) {
                            vm.$emit('close');
                            window.open(qrCodeMessage, "_blank");
                        } else {
                            /*
                            vm.$alert('지원하지 않는 코드입니다.').then(()=>{
                                vm.$alert(qrCodeMessage);
                            });*/
                            vm.log({
                                valid: false,
                                qrcode: qrCodeMessage
                            });
                        }
                    } catch (err) {
                        console.error(err);
                    }
                }).then(() => {

                    let settings = this.scanner.getRunningTrackSettings();

                    if ("torch" in settings) {
                        this.has_torch = true;
                    } else {
                        this.has_torch = false;
                    }

                    const scannerCapabilities = this.scanner.getRunningTrackCapabilities();



                    if (scannerCapabilities.zoom != undefined && scannerCapabilities.zoom.max != undefined) {
                        //if (scannerCapabilities.zoom.max > 2) this.zoom.current = 2
                        //else this.zoom.current = scannerCapabilities.zoom.max;

                        this.zoom.max = scannerCapabilities.zoom.max;
                        this.zoom.min = scannerCapabilities.zoom.min;
                        this.zoom.has = true;

                        if(this.zoom.min == 0.5) this.zoom.step = 0.5;
                        else this.zoom.step = 1;

                        this.zoom.current = this.zoom.step * this.zoom.level;

                        if (this.zoom.current > scannerCapabilities.zoom.max) {
                            this.zoom.current = scannerCapabilities.zoom.max;
                        }
                    }

                    var constraints = {
                        frameRate: { ideal: scannerCapabilities.frameRate?.max || 30 },
                        advanced: [
                            ...(scannerCapabilities.zoom ? [{ zoom: this.zoom.current }] : []),
                            ...(scannerCapabilities.focusDistance ? [{ focusDistance: 1 }] : []),
                        ],
                    };

                    if (this.$root.is_apple) {
                        constraints.focusMode = "continuous";
                    }

                    /*
                    this.$alert(this.has_torch+' / '+this.is_torch).then(()=>{
                        this.$alert(JSON.stringify(constraints))
                    })*/

                    if (scannerCapabilities.zoom != undefined && this.scanner.applyVideoConstraints != undefined) {
                        setTimeout(function () {
                            vm.scanner.applyVideoConstraints(constraints).then(() => {
                                if (vm.has_torch && vm.is_torch) {
                                    const torch = vm.scanner.getRunningTrackCameraCapabilities().torchFeature();
                                    torch.apply(!torch.value());
                                }
                            })
                        }, 500)
                    } else {
                        if (this.has_torch && this.is_torch) {
                            const torch = this.scanner.getRunningTrackCameraCapabilities().torchFeature();
                            torch.apply(!torch.value());
                        }
                    }
                })
            }
        },
        stop(callback) {
            if (this.scanner != null && this.scanner.getState() == 2) {
                this.scanner
                    .stop()
                    .then(() => {
                        callback();
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            }
        },
        toggleSetting() {
            if (this.setting == 1) this.setting = 0;
            else this.setting = 1;
        }
    },
    watch: {
        dialog(val) {
            if (val == false) {
                //this.close();
            }
        },
        qrbox_size(val) {
            var vm = this;
            this.stop(function () {
                vm.start()
            })
            storage.qrSize(val);
        },
        cameraId(val, val2) {
            if (val2 != null) {
                var vm = this;
                this.stop(function () {
                    vm.start()
                })
                if (this.db_camera[val] != undefined) {
                    storage.cameraLabel(this.db_camera[val].label);
                }
                storage.cameraId(val);
            }
        },
        is_torch(val) {
            if (this.has_torch) {
                const torch = this.scanner.getRunningTrackCameraCapabilities().torchFeature();
                torch.apply(!torch.value());
            }
            storage.cameraTorch(val);
        },
        'zoom.current'(val) {
            this.scanner.applyVideoConstraints({
                advanced: [{ zoom: val }]
            });

            var val2 = val / this.zoom.step;
            this.zoom.level = val2;
            storage.cameraZoom(val2);
        }
    },
    beforeUnmount() {
        this.close()
    },
    unmounted() {

    },
});
</script>

<style lang="scss" scoped>
@import "@/assets/css/_variables.scss";
@import "@/assets/css/_mixins.scss";

.CameraComp {
    max-width: 600px;
    margin: 0px auto;
}

.top {
    margin: 20px 0px 0px 0px !important;
}

span.GuideSpan {
    margin: 10px 0px 20px 0px;
    padding: 0px;
}

.guide1 {
    line-height: 24px;
}



.guide {
    display: flex;

    .title {
        flex-grow: 1;
    }

    .right {
        min-width: 50px;
    }
}

.setting {
    padding: 10px;

    .label {
        display: block;
        margin: 10px 0px 5px;
    }

    .v-select {
        margin-bottom: 20px;
    }
}


.v-select {
    @include select_de;
}


.cameraWrap {
    position: relative;

    .btn_flash {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 100;
    }
}

@media (max-width : 400px) {
    .GuideSpan {
        font-size: 14px;
    }
}

.slider {
    display: flex;
    max-width: 400px;
    margin: 0px auto;
}

.slider>span {
    display: block;
    min-width: 32px;
    max-width: 40px;
    text-align: right;
}

.slider>span:first-child {
    text-align: left;
}

.slider>span {
    flex-grow: 1;
    line-height: 32px;
}

.one_line {
    display: flex;

    .label {
        width: 100px;
    }

    .torch {
        flex-grow: 1;
    }

    /*
    .torch:deep(.v-selection-control) {
        flex-direction: row-reverse;
    }*/
}
</style>