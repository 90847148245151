import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import AsyncComputed from 'vue-async-computed';
import vuetify from './plugins/vuetify';
import { loadFonts } from './plugins/webfontloader';

import common from '@/assets/js/common.js';
import push from '@/assets/js/push';
import api from '@/assets/js/api.js';
import 'sweetalert2/dist/sweetalert2.min.css';
import '@/assets/css/style.css';


import analytics from './assets/js/analytics';

loadFonts();

createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .use(AsyncComputed)
  .use(common)
  .use(api)
  .use(push)
  .use(analytics)
  .mount('#app');
