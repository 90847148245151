<template>
    <div class="DatePicker">
        <v-date-picker v-model='date' header="날짜를 선택해주세요." :title="title" color="primary" @update:modelValue="select_date"
            show-adjacent-months>
            <template v-slot:header>
                <div class="v-date-picker-header__content" v-if="date == ''">날짜를 선택해주세요.</div>
                <div class="v-date-picker-header__content" v-if="date != ''">{{ dateFormat(date,
                    'YYYY년 M월 D일') }}
                </div>
            </template>
        </v-date-picker>
        <ButtonComp @click="today">오늘</ButtonComp>
    </div>
</template>
<script>
import ButtonComp from '@/components/ButtonComp.vue'
import moment from 'moment'

export default {
    name: 'DatePicker',
    components: {
        ButtonComp
    },
    props: {
        modelValue: {
            type: String,
            default: '',
        },
        title: {
            type: String,
            default: '',
        },
    },
    computed: {},
    data: () => ({
        date: ''
    }),
    mounted() {
        this.date = this.modelValue;
    },
    methods: {
        dateFormat(a, format) {
            return moment(a).format(format);
        },
        select_date(val) {
            this.$emit('select_date', moment(val, 'ddd MM D YYYY').format('YYYY-MM-DD'))
        },
        today() {
            this.date = moment().format('YYYY-MM-DD');
            this.$emit('select_date', this.date);
        }
    },
    watch: {}
}
</script>
<style lang="scss" scoped>
@import "@/assets/css/_variables.scss";
@import "@/assets/css/_mixins.scss";

.DatePicker {
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
}

.ButtonComp {
    margin: 10px 20px 20px 20px;
}
</style>