<template>
    <div>
        <BasicCardText v-for="(item2, i) in list" :key="(item2.item_seq + '_' + i)" :title="item2.title">
            <pre>{{ item2.text }}</pre>
        </BasicCardText>
    </div>
</template>
<script>
import BasicCardText from '../../components/BasicCardText.vue'

import analytics from "@/assets/js/analytics";

export default {
    name: 'SafetyLetter',
    components: {
        BasicCardText,
    },
    props: {
        item: {
            type: Object,
            default: ()=>{}
        }
    },
    data: () => ({
        list: [],
    }),
    computed: {

    },
    mounted() {
        var item = this.item;
        item.product_num = item.product_num.replace(/,/g,', ')

        var list = [['제품명', 'item_name'], ['업체명', 'entp_name'], ['제품번호', 'product_num'], ['회수명령일자', 'order_date'], ['공개마감일자', 'end_date'], ['상세내용', 'reason']];

        list.forEach(k => {
            if (item[k[1]] != undefined && item[k[1]] != null && item[k[1]] != '') {
                this.list.push({
                    "title": k[0],
                    "text": item[k[1]]
                })
            }
        })

        analytics.log_h('H_DISCARDS', '', {
            item_seq:this.item.item_seq
        });
    }
}
</script>
<style lang="scss" scoped>
@import "@/assets/css/_variables.scss";
@import "@/assets/css/_mixins.scss";

pre {
    white-space: pre-wrap;
}

.scroll_medicine {
    max-height: 400px;
    overflow-y: auto;
    padding: 10px
}
</style>