import axios from "axios";
import { message } from "@/assets/js/messages";
import storage from "@/assets/js/storage";

import router from "@/router";

const packageInfo = require('../../../package.json');

const methods = {
    make_url: (url, params, query) => {
        var _url = url;
        var arr_url = _url.split("/");
        arr_url.forEach((chip, i) => {
            if (chip.startsWith(":")) {
                if (params[chip.substring(1)]) {
                    arr_url[i] = params[chip.substring(1)];
                } else {
                    arr_url[i] = "";
                }
            }
        });
        _url = arr_url.join("/");
        if (query) {
            var arr = [];
            Object.keys(query).forEach((q) => {
                arr.push(`${q}=${encodeURIComponent(query[q])}`);
            });

            if (arr.length > 0) {
                _url += "?" + arr.join("&");
            }
        }
        _url = _url.replace("//", "/");
        _url = _url.replace("//", "/");

        if (_url.endsWith("/")) {
            _url = _url.substring(0, _url.length - 1);
        }
        return _url;
    },
    resource: (url, opt) => {
        var api = {};
        Object.keys(opt).forEach((key) => {
            api[key] = function (params, query, body) {
                return new Promise((resolve, reject) => {
                    var _params = {};
                    var _query = {};

                    if (opt[key].params) {
                        Object.keys(opt[key].params).forEach((k1) => {
                            _params[k1] = opt[key].params[k1];
                        });
                    }
                    if (params) {
                        Object.keys(params).forEach((k1) => {
                            _params[k1] = params[k1];
                        });
                    }

                    if (opt[key].query) {
                        Object.keys(opt[key].query).forEach((k1) => {
                            _query[k1] = opt[key].query[k1];
                        });
                    }
                    if (query) {
                        Object.keys(query).forEach((k1) => {
                            _query[k1] = query[k1];
                        });
                    }

                    var _url = methods.make_url(url, _params, _query);

                    var options = {};
                    options.url = _url;
                    options.method = opt[key].method;

                    if (opt.headers != undefined) {
                        options.headers = opt.headers;
                    }

                    if (body != undefined) options.data = body;

                    axios(options)
                        .then(function (response) {
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            };
        });
        return api;
    },
};

export default {
    install: (app) => {
        axios.interceptors.request.use((config) => {
            if (storage.session_key() != undefined)
                config.headers.psk = storage.session_key();
            if (storage.suid() != undefined)
                config.headers.suid = storage.suid();
            config.headers.app_version = packageInfo.version;
            return config;
        });

        // 응답 에러 관련
        axios.interceptors.response.use(
            (response) => {
                return response;
            },
            (error) => {
                console.error(error);
                if (error.response.data.message != undefined) {
                    if (message[error.response.data.message] != undefined) {
                        if (
                            error.response.data.message == "need_to_login" ||
                            error.response.data.message == "need_to_re_login"
                        ) {
                            if(router.currentRoute != undefined) {
                                var path = router.currentRoute.value.fullPath;
                                if (path != undefined) localStorage.setItem("return_url", path);
                                else localStorage.removeItem("return_url");
                            }

                            app.config.globalProperties
                                .$alert(message[error.response.data.message])
                                .then(() => {
                                    storage.clear();
                                    router.push("/user/login");
                                });
                        } else {
                            app.config.globalProperties.$alert(
                                message[error.response.data.message]
                            );
                        }
                    } else {
                        app.config.globalProperties.$alert(error.response.data.message);
                    }
                }
                return Promise.reject(error);
            }
        );

        var api = {};

        api.atc = methods.resource("/api/atc_code/:code", {
            list: {
                method: "get",
            },
        });

        api.autocomplete = methods.resource("/api/autocomplete/:cmd", {
            entp_name: {
                method: "get",
                params: {
                    cmd: "entp_name",
                },
            },
            medicine: {
                method: "get",
                params: {
                    cmd: "medicine",
                },
            },
            ingredient: {
                method: "get",
                params: {
                    cmd: "ingredient",
                },
            },
        });

        api.discards = methods.resource("/api/recall_discards", {
            list: {
                method: "get",
            },
        });

        api.information = methods.resource("/api/information/:cmd", {
            atc_mapping: {
                method: "get",
                params: {
                    cmd: "atc_mapping",
                },
            },
            food_warning: {
                method: "get",
                params: {
                    cmd: "food_warning",
                },
            },
            academic: {
                method: "get",
                params: {
                    cmd: "academic",
                },
            },
        });

        api.edu = methods.resource("/api/edu", {
            list: {
                method: "get",
            },
        });

        api.etc = methods.resource("/api/etc/:cmd", {
            qna_categories: {
                method: "get",
                params: {
                    cmd: "qna/categories",
                },
            },
            speech_grammar: {
                method: "get",
                params: {
                    cmd: "speech/grammar",
                },
            },
        });

        api.info = methods.resource("/api/info/:cmd", {
            drug_safety: {
                method: "get",
                params: {
                    cmd: "drug_safety",
                },
            }
        });

        api.manage = {};

        api.marks = methods.resource("/api/marks", {
            list: {
                method: "get",
            },
        });

        api.medicine = methods.resource("/api/medicines/:code/:cmd", {
            detail: {
                method: "get",
            },
            search: {
                method: "get",
            },
            discards: {
                method: "get",
                params: {
                    cmd: "discards",
                },
            },
            easy: {
                method: "get",
                params: {
                    cmd: "easy",
                },
            },
            safety_notices: {
                method: "get",
                params: {
                    cmd: "safety_notices",
                },
            },
            dur: {
                method: "get",
                params: {
                    cmd: "dur",
                },
            },
            shapes: {
                method: "get",
                params: {
                    cmd: "shapes",
                },
            },
        });

        api.my = {};

        api.my.dose = methods.resource("/api/my/dose/:cmd/:uuid", {
            log: {
                method: "get",
                params: {
                    cmd: "log",
                },
            },
            make: {
                method: "get",
                params: {
                    cmd: "make",
                },
            },
            booking: {
                method: "get",
                params: {
                    cmd: "booking",
                },
            },
            notification: {
                method: "get",
                params: {
                    cmd: "notification",
                },
            },
            notification_delete: {
                method: "delete",
                params: {
                    cmd: "notification",
                },
            },
            notification_new: {
                method: "post",
                params: {
                    cmd: "notification",
                },
            },
            notification_update: {
                method: "put",
                params: {
                    cmd: "notification",
                },
            },
            take: {
                method: "post",
                params: {
                    cmd: "take",
                },
            },
            not_take: {
                method: "post",
                params: {
                    cmd: "not_take",
                },
            },
        });

        api.my.drug = {};

        api.my.drug.bookmarks = methods.resource(
            "/api/my/drug/bookmarks/:item_seq",
            {
                list: {
                    method: "get",
                },
                get: {
                    method: "get",
                },
                insert: {
                    method: "post",
                },
                delete: {
                    method: "delete",
                },
                update: {
                    method: "put",
                },
            }
        );

        api.my.drug.history = methods.resource("/api/my/drug/history/:uuid", {
            list: {
                method: "get",
            },
            get: {
                method: "get",
            },
        });

        api.my.drug.log = methods.resource("/api/my/drug/log", {
            post: {
                method: "post",
            },
        });

        api.my.drug.memo = methods.resource("/api/my/drug/memo/:item_seq", {
            get: {
                method: "get",
            },
            list: {
                method: "get",
            },
            update: {
                method: "post",
            },
            delete: {
                method: "delete",
            },
        });

        api.my.drug.share = methods.resource("/api/my/drug/share", {
            post: {
                method: "post",
            },
        });

        api.my.drug.takes = methods.resource("/api/my/drug/takes/:uuid/:cmd", {
            list: {
                method: "get",
            },
            get: {
                method: "get",
            },
            insert: {
                method: "post",
            },
            update: {
                method: "put",
            },
            medication_on: {
                method: "put",
                params: {
                    cmd: "on",
                },
            },
            medication_off: {
                method: "put",
                params: {
                    cmd: "off",
                },
            },
            important_on: {
                method: "put",
                params: {
                    cmd: "important",
                },
            },
            important_off: {
                method: "delete",
                params: {
                    cmd: "important",
                },
            },
        });

        api.my.folder = methods.resource("/api/my/folders/:uuid", {
            list: {
                method: "get",
            },
            get: {
                method: "get",
            },
            insert: {
                method: "post",
            },
            update: {
                method: "put",
            },
            delete: {
                method: "delete",
            },
        });

        api.my.info = methods.resource("/api/my/info/:cmd", {
            get: {
                method: "get",
            },
            update_password: {
                method: "put",
                params: {
                    cmd: "password",
                },
            },
            update_email: {
                method: "put",
                params: {
                    cmd: "email",
                },
            },
            bio: {
                method: "get",
                params: {
                    cmd: "bio",
                },
            },
            bio_update: {
                method: "post",
                params: {
                    cmd: "bio",
                },
            },
            bio_keyword: {
                params: {
                    cmd: "bio/keyword",
                },
            },
            request_auth_change_mobile: {
                method: "post",
                params: {
                    cmd: 'sms/change_mobile'
                }
            },
            update_mobile: {
                method: "put",
                params: {
                    cmd: "mobile",
                },
            }
        });

        api.my.notifications = methods.resource(
            "/api/my/notifications/:uuid/:cmd",
            {
                list: {
                    method: "get",
                },
                get: {
                    method: "get",
                },
                safety_letter: {
                    method: "get",
                    query: { t: "sl" },
                },
                read_all: {
                    method: "put",
                    query: { act: "read_all" },
                },
            }
        );

        api.my.prescription = methods.resource("/api/my/prescriptions/:uuid/:cmd", {
            list: {
                method: "get",
            },
            get: {
                method: "get",
            },
            insert: {
                method: "post",
            },
            update: {
                method: "put",
            },
            delete: {
                method: "delete",
            },
            history: {
                method: "get",
                params: {
                    cmd: "history",
                },
            },
            medication_on: {
                method: "put",
                params: {
                    cmd: "on",
                },
            },
            medication_off: {
                method: "put",
                params: {
                    cmd: "off",
                },
            },
        });

        api.my.qna = methods.resource("/api/my/qna/:uuid/:cmd", {
            list: {
                method: "get",
            },
            get: {
                method: "get",
            },
            write: {
                method: "post",
            },
            view: {
                method: "put",
                params: {
                    cmd: "view",
                },
            },
            delete: {
                method: "delete"
            },
            update: {
                method: "put"
            },
        });

        api.push = methods.resource("/api/push/:cmd", {
            subscribe: {
                method: "post",
                params: {
                    cmd: "subscribe",
                },
            },
            unsubscribe: {
                method: "post",
                params: {
                    cmd: "unsubscribe",
                },
            },
        });

        api.safety_notices = methods.resource("/api/safety_notices/:code/:cmd", {
            list: {
                method: "get",
            },
            detail: {
                method: "get",
            },
            medicines: {
                method: "get",
                params: {
                    cmd: "medicines",
                },
            },
        });

        api.share = methods.resource("/api/share/:cmd", {
            takes: {
                method: "get",
                params: {
                    cmd: "takes",
                },
            },
        });

        api.test = methods.resource("/api/test/:cmd", {
            speech: {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                method: "post",
                params: {
                    cmd: "speech",
                },
            },
        });

        api.user = methods.resource("/api/user/:cmd", {
            is_login: {
                method: "get",
                params: {
                    cmd: "is_login",
                },
            },
            login: {
                method: "post",
                params: {
                    cmd: "login",
                },
            },
            logout: {
                method: "get",
                params: {
                    cmd: "logout",
                },
            },
            join: {
                method: "post",
                params: {
                    cmd: "join",
                },
            },
            find_user_id: {
                method: "post",
                params: {
                    cmd: "find/user_id",
                },
            },
            find_passwd: {
                method: "post",
                params: {
                    cmd: "find/passwd",
                },
            },
            reset_passwd: {
                method: "post",
                params: {
                    cmd: "find/reset_passwd",
                },
            },
            mail_auth: {
                method: "post",
                params: {
                    cmd: "mail/auth",
                },
            },
            sms_join_auth: {
                method: "post",
                params: {
                    cmd: "sms/join_auth",
                },
            },
            sms_reset_password_auth: {
                method: "post",
                params: {
                    cmd: "sms/reset_password_auth",
                },
            },
            sms_reset_password: {
                method: "put",
                params: {
                    cmd: "sms/reset_password",
                },
            },
            sms_check: {
                method: "post",
                params: {
                    cmd: "sms/check_auth",
                },
            },
            join_code: {
                params: {
                    cmd: "join_code",
                },
            },
            check_auth: {
                method: "post",
                params: {
                    cmd: "mail/check_auth",
                },
            },
        });

        api.utils = {};

        api.utils.sido = methods.resource("/api/utils/sido/:sido/:cmd", {
            list: {
                method: "get",
                params: {
                    cmd: "is_login",
                },
            },
            gugun: {
                method: "get",
                params: {
                    cmd: "gugun",
                },
            },
        });

        api.log = methods.resource("/api/log/:cmd", {
            save: {
                method: "post"
            },
        });

        app.config.globalProperties.$api = api;
    },
};
