<template>
  <v-checkbox hide-details density="compact"></v-checkbox>
</template>
  
<script>
export default {
  name: 'CheckBoxComp',
  components: {

  },
  data: () => ({
    isChecked: false
  }),
  mounted() {

  },
  methods: {
    update() {

    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/_variables.scss";
@import "@/assets/css/_mixins.scss";

@include check-default;

.v-checkbox {
  padding: 0px 0px 0px;
}
</style>