const share = () => import("@/views/expert/ShareView.vue");
import Layout from "../layouts/LayoutBasic.vue";

export default [
  {
    path: "/expert/share",
    name: "expertShare",
    component: share,
    meta: { layout: Layout, title: "환자약력 조회" },
  },
];
