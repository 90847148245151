<template>
    <div class="container">
        <button id="speech" class="btn">
            <div class="pulse-ring" :class="{ play:is_playing}"></div>
            <v-icon>mdi-microphone-outline</v-icon>
        </button>
    </div>
</template>
<script>
export default {
    name:'MicAnimation',
    props:{
        is_playing:{
            type:Boolean,
            default:false
        }
    },
    data:()=>({

    }),
    methods:{
        start() {
            //this.is_playing = true;
        },
        stop() {
            //this.is_playing = false;
        }
    }
}
</script>
<style lang="scss" scoped>
@import "@/assets/css/_variables.scss";
@import "@/assets/css/_mixins.scss";

$primary: #189BFF;
$secondary: #d8d8d8;
$size: 150px;

.btn {
    border: none;
    padding: 0;
    border-radius: 100%;
    width: $size;
    height: $size;
    font-size: 3em;
    color: #fff;
    padding: 0;
    margin: 0;
    background: red;
    position: relative;
    z-index: 1;
    display: inline-block;
    line-height: $size;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
}

.pulse-ring {
    content: '';
    width: $size;
    height: $size;
    background: $primary;
    border: 5px solid $primary;
    border-radius: 50%;
    position: absolute;
    top: 0px;
    left: 0px;
    
}

.pulse-ring.play {
    animation: pulsate infinite 1.5s;
}


@keyframes pulsate {
    0% {
        -webkit-transform: scale(1, 1);
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(1.3, 1.3);
        opacity: 0;
    }
}

.container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.m-left {
    margin-left: $size * 3;
}

.type2 .pulse-ring {
    background: transparent;
}
</style>