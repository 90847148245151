<template>
    <div class="SafetyLetterBox" v-if="safety_letter.length > 0">
        <div class="wrap">
            <div class="title">
                <span>안전성 공지 알림</span>
            </div>
            <div class="noticeWrap">
                <div class="left">

                </div>
                <div class="notice">
                    <p>해당 의약품에 안전성 관련</p>
                    <p>공지 &#123;{{ safety_letter.length }}&#125; 건 있습니다</p>
                </div>
                <div class="arrow" @click="go">
                    <v-icon>mdi-arrow-right-thick</v-icon>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: 'SafetyLetterBox',
    props: {
        discards: {
            type: Array,
            default: () => { [] }
        },
        safety_letter: {
            type: Array,
            default: () => { [] }
        }
    },
    data: () => ({
    }),
    mounted() {
    },
    methods:{
        go() {
            this.$root.safety_letter(this.safety_letter[0].saft_lett_no.toString())
        }
    }
}
</script>
  
<style lang="scss" scoped>
@import "@/assets/css/_variables.scss";
@import "@/assets/css/_mixins.scss";

.title {
    padding: 0 0 10px 0;
}

.title span {
    @include H4_EM;
    color: $b-9;
}

.noticeWrap {
    @include between;
    padding: 7px 0;
    background-color: $b-05;
    border: 2px solid $y-9;
    border-radius: 4px;
}

.notice {
    flex-grow: 9;
}

.notice p {
    @include H4_EM;
    color: $b-9;
}

.left {
    width: 50px;
}

.arrow {
    width: 50px;
}

@media (min-width: 400px) {
    .left {
        width: 100px;
    }

    .arrow {
        width: 100px;
    }
}
</style>
  