<template>
    <div class="SideMenuCard">
        <div class="wrap">
            <div :class="['title', user == null || user.type == 'G' ? 'blue' : '']" v-if="title!=''">
                <span>{{ title }}</span>
            </div>
            <div class="menu">
                <slot></slot>
            </div>
        </div>
    </div>
</template>
  
<script>
import storage from '@/assets/js/storage';
export default {
    name: "SideMenu",
    props: {
        title: {
            type: String,
            default: ""
        }
    },
    computed:{
        user() {
            return storage.user();
        }
    }
};
</script>
  
<style lang="scss" scoped>
@import "@/assets/css/_variables.scss";
@import "@/assets/css/_mixins.scss";

.SideMenuCard {
    padding: 10px 0;
}

.wrap {
    box-shadow: $shadow;
    border-radius: 8px;
}

.title {
    padding: 6px 20px;
    background-color: $b-g-4;
    border-radius: 8px 8px 0 0;
}

.title.blue {
    background-color: $b-7;
}

.title span {
    @include H3;
    color: $w;
}
</style>
  