const sign = () => import("@/views/edu/SignView.vue");
const data = () => import("@/views/edu/DataView.vue");
const link = () => import("@/views/edu/LinkView.vue");

import Layout from "../layouts/LayoutBasic.vue";

export default [
    {
      path: "/edu/sign",
      name: "eduSign",
      component: sign,
      meta: { layout: Layout, title: "수어영상" },
    },
    {
      path: "/edu/data",
      name: "eduData",
      component: data,
      meta: { layout: Layout, title: "교육자료" },
    },
    {
      path: "/edu/link",
      name: "eduLink",
      component: link,
      meta: { layout: Layout, title: "학술정보" },
    },
  ];