<template>
    <div class="ListDiscard">
        <div class="card_wrap" v-for="(data, j) in list" :key="j" @click="view(data)">
            <div class="top">
                <span>{{ j + 1 }}</span>
                <span>|</span>
                <span>회수/폐기</span>
                <span>|</span>
                <span>{{ data.order_date }}</span>
            </div>
            <div class="bottom">
                <span class="item_name">{{ ng.clean_item_name(data.item_name) }}</span>
                <span class="entp_name">{{ data.entp_name }}</span>
            </div>
        </div>

        <DialogComp v-model="v_letter" title="회수 · 폐기" :fullscreen="true" class="no_actions">
            <template v-slot:body>
                <DiscardView :item="item" />
            </template>
        </DialogComp>
    </div>
</template>

<script>
import DialogComp from './DialogComp.vue'
import DiscardView from '../views/notice/DiscardView.vue'
import ng from '@/assets/js/ng'

export default {
    name: 'Tab1Comp',
    components: {
        DialogComp,
        DiscardView
    },
    data: () => ({
        ng: ng,
        v_letter: false,
        item: {}
    }),
    props: {
        list: {
            type: Array,
            default: () => { return [] }
        }
    },
    methods: {
        view(item) {
            this.v_letter = true;
            this.item = item;
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/_variables.scss";
@import "@/assets/css/_mixins.scss";

.card_wrap {
    border-radius: 12px;
    box-shadow: $shadow;
    margin: 0 0 6px;
}

.top,
.bottom {
    padding: 2px 8px;
}

.top span {
    margin-left: 8px;
}

.top span:nth-child(1) {
    @include H3;
    color: $b-8;
}

.top span:nth-child(3) {
    @include H3;
    color: $y-6;
}

.top span:nth-child(5) {
    @include H3;
    color: $b-g-4;
}

.top span:nth-child(2),
:nth-child(4) {
    color: $b-g-3;
}

.bottom span {
    @include H4;
    color: $b-g-8;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    margin-right: 10px;
    max-width: 100%;
}

@media (max-width: 300px) {
    .bottom span {
        display: block;
        margin-right: 0px;
    }
}
</style>
