<template>
    <div class="SpeechRecon">
        <div class="ok" v-if="has_permission">
            <GuideSpan class="g1">의약품의 제품명이나 성분명<br>또는 제조사명을 말해주세요.</GuideSpan>
            <GuideSpan class="g2">예) 부루펜</GuideSpan>
            <div class="animate">
                <MicAnimation ref="wave" :is_playing="is_speaking" @:click="retry" :disabled="is_speaking">
                </MicAnimation>
            </div>
            <GuideSpan class="result" v-if="is_speaking">말해주세요.</GuideSpan>
            <GuideSpan class="result">{{ speak_text }}</GuideSpan>

            <div class="foot_button type3">
                <button @:click="retry" :disabled="is_speaking">다시 시도</button>
                <button @:click="search" :disabled="is_speaking">검색</button>
                <button @:click="close">닫기</button>
            </div>
        </div>
        <div class="error" v-if="!has_permission">
            <GuideSpan class="g1">마이크 사용권한이 필요합니다.<br>아래와 같이 브라우저의 사용권한을 허용해주세요.</GuideSpan>
            크롬, 사파리, 삼성인터넷 등 상황에 맞는 이미지 캡셔해서 삽입할 예정
        </div>
    </div>
</template>
<script>
import GuideSpan from './GuideSpan.vue'
import MicAnimation from '@/components/MicAnimation.vue'

import analytics from "@/assets/js/analytics";

window.SpeechRecognition = window.webkitSpeechRecognition || window.SpeechRecognition;
window.SpeechGrammarList = window.webkitSpeechGrammarList || window.SpeechGrammarList;

export default {
    name: 'SpeechRecon',
    components: {
        MicAnimation,
        GuideSpan
    },
    data: () => ({
        is_speaking: false,
        is_record: false,
        recognition: null,
        speechRecognitionList: null,
        speak_text: '',
        has_permission: true,
        is_apple: false,
        grammar: null,
        use_grammar : false
    }),
    mounted() {
        this.is_apple = this.$isApple();
        this.is_apple = true;
        var vm = this;
        setTimeout(function () {
            vm.retry();
        }, 100);
    },
    methods: {
        retry() {
            var vm = this;

            if (this.is_speaking)
                this.recognition.stop();

            setTimeout(function () {
                vm.mic_on();
            }, 100);
        },
        search() {
            if (this.speak_text.length < 2) {
                this.$alert('두글자 이상 검색가능합니다.')
                return;
            }
            this.$emit('search', this.speak_text)
        },
        close() {
            this.mic_off();
            this.$emit('close')
        },
        stop() {
            this.mic_off();
        },
        log(text) {
            var p_id = this.$root.popup_id['SEARCH_VOICE'] != undefined ? this.$root.popup_id['SEARCH_VOICE'] : '';
            analytics.log_h('H_VOICE', p_id, { st: text });
        },
        mic_on() {
            this.speak_text = '';
            var vm = this;

            this.recognition = new window.SpeechRecognition();

            if (this.use_grammar) {
                this.speechRecognitionList = new window.SpeechGrammarList();
                this.speechRecognitionList.addFromString(this.$root.setting.grammar, 1);
                this.recognition.grammars = this.speechRecognitionList;
            }

            this.recognition.interimResults = true;
            this.recognition.continuous = false;
            this.recognition.lang = "ko-KR";

            if (this.$isApple()) {
                //this.recognition.continuous = true;
                this.recognition.interimResults = true;
                this.recognition.maxAlternatives = 5;
            }

            var final_transcript = '';
            this.recognition.onstart = function () {
                vm.is_speaking = true;
                final_transcript = '';
            };

            this.recognition.onsoundstart = function () {
                vm.is_record = true;
                final_transcript = '';
            };

            this.recognition.onsoundend = function () {
                vm.is_speaking = false;
                vm.is_record = false;
            };

            this.recognition.onspeechend = function () {
                vm.is_speaking = false;
                vm.is_record = false;
                /*
                if (vm.$isApple()) {
                    try { vm.recognition.start(); }
                    catch (err) {
                        console.error(err);
                    }
                }*/
                vm.recognition.stop();
            };

            this.recognition.onerror = function (event) {
                console.error(event);
                var ignore_onend = false;
                if (event.error == 'no-speech') {
                    ignore_onend = true;
                }
                if (event.error == 'audio-capture') {
                    ignore_onend = true;
                }
                if (event.error == 'not-allowed') {
                    vm.has_permission = false;
                    ignore_onend = true;
                }

                if (ignore_onend) {
                    vm.mic_off();
                }
                vm.is_record = false;
            };

            this.recognition.onend = function () {
                vm.is_speaking = false;
                vm.is_record = false;
                // 음성인식 로그
                if (vm.speak_text != '') vm.log(vm.speak_text);
            };


            this.recognition.onresult = function (event) {
                if (typeof (event.results) == 'undefined') {
                    vm.recognition.stop();
                    return;
                }
                var interim_transcript = '';
                for (var i = event.resultIndex; i < event.results.length; ++i) {
                    if (event.results[i].isFinal) {
                        final_transcript += event.results[i][0].transcript;
                        if(vm.$root.setting.use_final) {
                            vm.speak_text = final_transcript;
                            //console.log('use final');
                        }
                        //console.log('final', event.results[i][0].transcript)
                    } else {
                        interim_transcript += event.results[i][0].transcript;
                        vm.speak_text = interim_transcript;
                        //console.log('interim_transcript', event.results[i][0].transcript)
                    }
                    vm.speak_text = vm.speak_text.split('.').join("");
                }
                if (vm.$isApple()) {
                    //vm.recognition.stop();
                }
            };
            this.recognition.start();
        },
        mic_off() {
            this.recognition.stop();
        },
    },
    watch: {
        is_record(val) {
            if (val) {
                this.$refs.wave.start();
            } else {
                this.$refs.wave.stop();
            }
        }
    }
}
</script>
<style lang="scss" scoped>
@import "@/assets/css/_variables.scss";
@import "@/assets/css/_mixins.scss";

.GuideSpan.g1 {
    color: #334155;
}

.GuideSpan.g2 {
    color: #64748b;
}

.animate {
    display: block;
    width: 300px;
    margin: 20px auto;
}

.foot_button {
    position: absolute;
    width: calc(100% - 40px);
    bottom: 0px;
}

.foot_button button:disabled {
    color: grey
}
</style>