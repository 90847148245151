<template>
    <div class="NBTextBox">
        <div class="wrap">
            <v-expansion-panels v-model="panel">
                <v-expansion-panel>
                    <v-expansion-panel-title>
                        <div class="TitleWrap">
                            <span v-if="false">
                                <img class="img" :src="require('@/assets/images/common/text_to_speech.svg')" alt="speech"
                                    @click.stop="speech(nb)">
                            </span>
                            <span class="mainTitle">{{ nb.title }}</span>
                            <span class="hr" />
                        </div>
                        <template v-slot:actions="{ expanded }">
                            <svg-icon type="mdi" :path="expanded ? up : down"></svg-icon>
                        </template>
                    </v-expansion-panel-title>
                    <v-expansion-panel-text>
                        <div class="textWrap">
                            <div class="Right" v-for="(paragraph, pi) in nb.paragraphs" :key="pi">
                                <pre v-if="paragraph.tag == 'p'" v-html="highlight_regex(paragraph.text)"></pre>
                                <div class="tableWrap" v-if="paragraph.tag == 'table'">
                                    <table v-html="paragraph.text" class="nb_basic"></table>
                                </div>
                            </div>
                        </div>
                    </v-expansion-panel-text>
                </v-expansion-panel>
            </v-expansion-panels>
        </div>
    </div>
</template>
  
<script>
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiUnfoldMoreHorizontal } from '@mdi/js';
import { mdiUnfoldLessHorizontal } from '@mdi/js';

import { use_regex } from '@/assets/js/highlight';

export default {
    name: 'NBTextBox2',
    components: {
        SvgIcon
    },
    props: {
        nb: {
            type: Object,
            default: () => ({}),
        },
        keyword_highlight:{
            type:String,
            default:'0'
        }
    },
    data: () => ({
        up: mdiUnfoldLessHorizontal,
        down: mdiUnfoldMoreHorizontal,
        panel: [1],
    }),
    computed: {
        filteredArticles() {
            if (this.nb.articles == undefined) return [];
            return this.nb.articles.filter((x) => x.paragraphs.length > 0);
        }
    },
    mounted() {
    },
    methods: {
        speech(easy) {
            console.log(easy)
            //this.$root.speech(easy.text);
            this.$alert('주의사항 뷰 확정 및 읽는 부분 정리 필요')
        },
        highlight_regex(val) {
            if(this.keyword_highlight == 0) return val;
            return use_regex(val);
        }
    }
}
</script>
  
<style lang="scss" scoped>
@import "@/assets/css/_variables.scss";
@import "@/assets/css/_mixins.scss";


.wrap {
    @include panel_white_border_none;
}

.wrap .v-expansion-panel-title span {
    font-size: 1em;
}

.TitleWrap {
    @include between;
    width: 100%;
}

.TitleWrap span {
    text-align: left;
    color: $b-g-7;
    font-size: 1em;
    font-weight: $fw-9;
}

@media (min-width: 300px) {
    .TitleWrap span {
        font-size: 16px;
    }
}

.TitleWrap img {
    @include center;
    margin-right: 10px;
}

.mainTitle {
    flex-grow: 1;
}

.subTitle {
    flex-grow: 1;
}

.hr {
    flex-grow: 8;
    @include hr-line;
}

.Right {
    font-size: 1em;
    padding-bottom: 10px !important;
}


.Right:last-child {
    padding-bottom: 0px;
}

.tableWrap {
    width: 100%;
    overflow-x: auto
}

table.nb_basic {
    @include nb_basic;
}

table.nb_basic {
    min-width: 700px;
    @include nb_basic;
}


</style>
  