<template>
    <div class="GridButtonBox">
        <div class="title">
            <span>{{ title }}</span>
        </div>
        <div class="wrap">
            <slot></slot>
        </div>
    </div>
</template>
<script>


export default {
    components: {

    },
    props: {
        title: {
            type: String,
            default: ''
        },
    }
}
</script>
<style lang="scss" scoped>
@import "@/assets/css/_variables.scss";
@import "@/assets/css/_mixins.scss";

.title {
    padding-bottom: 10px;
}

.title span {
    @include H4;
    color: $b-g-6;
}

.wrap {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    border-radius: 4px;
    box-shadow: $shadow;
}

.one .wrap {
    grid-template-columns: repeat(1, 1fr);
}

.five .wrap {
    grid-template-columns: repeat(5, 1fr);
}
</style>