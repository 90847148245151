const safety_letter = () => import("@/views/my/notification/SafetyLetter.vue");

import Layout from "../layouts/LayoutBasic.vue";

export default [
    {
      path: "/my/notification/safety_letter",
      name: "myNotificationSafetyLetter",
      component: safety_letter,
      meta: { layout: Layout, title: "안전성 서한 알림이력" },
    },
  ];