<template>
    <canvas id="wave_comp" style="width:300px;height:100px"></canvas>
</template>
<script>
import { defineComponent } from 'vue';
import { Wave } from "@foobar404/wave";

export default defineComponent({
    name: 'AudioWave',
    props: {
        is_record: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            recorder: null,
            mediaStream: null,

            blob: null,
            chunks: [],
        };
    },
    mounted() {

    },
    methods: {
        start() {
            var vm = this;
            if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
                navigator.mediaDevices
                    .getUserMedia(
                        // constraints - only audio needed for this app
                        {
                            audio: true,
                            video: false
                        },
                    )
                    .then((stream) => {

                        vm.mediaStream = stream;
                        vm.recorder = new MediaRecorder(stream);

                        vm.recorder.ondataavailable = (e) => {
                            if (vm.is_record) vm.chunks.push(e.data);
                        };

                        vm.recorder.onstop = () => {
                            vm.mediaStream.getTracks().forEach(track => track.stop());

                            if (vm.is_record) {
                                vm.blob = new Blob(vm.chunks, { type: "audio/wav" });
                                vm.chunks = [];
                                vm.$emit('send_blob', vm.blob)
                            }
                            vm.$emit('on_stop');
                        };

                        vm.recorder.onstart = () => {
                            vm.context = new (window.AudioContext || window.webkitAudioContext);
                            vm.analyser = vm.context.createAnalyser();

                            vm.microphone = vm.context.createMediaStreamSource(stream);
                            vm.microphone.connect(vm.analyser);

                            vm.wave = new Wave(
                                { context: vm.context, source: vm.microphone },
                                document.querySelector("#wave_comp"),
                                true
                            );

                            vm.wave.addAnimation(
                                new vm.wave.animations.Lines({
                                    count: 30
                                })
                            );

                            if (vm.is_record) {
                                vm.analyser.fftSize = 2048;
                                vm.bufferLength = vm.analyser.frequencyBinCount;
                                vm.dataArray = new Uint8Array(vm.bufferLength);

                                vm.timer = setInterval(function () {
                                    vm.analyser.getByteFrequencyData(vm.dataArray);

                                    var canvasHeight = 100;
                                    for (let i = 0; i < vm.bufferLength; i++) {

                                        let v = vm.dataArray[i] / 128.0;
                                        let y = v * canvasHeight / 2;

                                        if (y > canvasHeight * 0.7) {
                                            if (vm.timer_stop != null) {
                                                clearTimeout(vm.timer_stop);
                                                vm.timer_stop = null;
                                            }
                                            vm.start_speaking = true;
                                        } else {
                                            if (vm.start_speaking && vm.timer_stop == null) {
                                                vm.timer_stop = setTimeout(function () {
                                                    vm.stop();
                                                }, 2000);
                                            }

                                        }
                                    }
                                }, 10);
                            }
                            vm.$emit('on_start');
                        }

                        vm.recorder.start()
                    })
                    .catch((err) => {
                        console.error(`The following getUserMedia error occurred: ${err}`);
                        vm.$emit('has_permission', false);
                    }
                    );
            } else {
                console.error("getUserMedia not supported on your browser!");
                vm.$emit('has_permission', false);
            }
        },
        stop() {
            this.recorder.stop();
        }
    }
})
</script>