<template>
    <v-text-field variant="plain" hide-no-data density="compact" :hide-details="hideDetails"></v-text-field>
</template>
<script>
export default {
    name:'SimpleInput',
    props:{
        hideDetails:{
            type:Boolean,
            default:true
        }
    }
}
</script>
<style lang="scss" scoped>
@import "@/assets/css/_variables.scss";
@import "@/assets/css/_mixins.scss";


.v-input {
    @include simple_input;
}
</style>