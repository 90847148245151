const login = () => import("@/views/user/LoginView.vue");
const find_id = () => import("@/views/user/FindUserid.vue");
const reset_passwd = () => import("@/views/user/ResetPasswd.vue");
const join = () => import("@/views/user/JoinView.vue");

import LayoutOnly from "../layouts/LayoutOnly.vue";

export default [
  {
    path: "/user/login",
    name: "userLogin",
    component: login,
    meta: { layout: LayoutOnly, title: "로그인" },
  },
  {
    path: "/user/find_id",
    name: "userFindId",
    component: find_id,
    meta: { layout: LayoutOnly, title: "아이디 찾기" },
  },
  {
    path: "/user/reset_passwd",
    name: "userResetPasswd",
    component: reset_passwd,
    meta: { layout: LayoutOnly, title: "비밀번호 초기화" },
  },
  {
    path: "/user/join",
    name: "userJoin",
    component: join,
    meta: { layout: LayoutOnly, title: "회원가입" },
  },
];
