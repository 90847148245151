<template>
    <div v-if="show" class="popup" :class="{ 'fullscreen': s_fullscreen, 'no_fullscreen':!s_fullscreen }" :style="style" :key="time">
        <div class="bg" @click="show = false"></div>
        <div class="content" :style="style_card">
            <slot name="body">
                {{ defaultBodyContent }}
            </slot>
        </div>
    </div>
</template>

<script>
import ng from '@/assets/js/ng.js'

export default {
    data() {
        return {
            notifications: false,
            sound: true,
            widgets: false,
            defaultBodyContent: "body slot 영역을 작성해주세요.",
            s_fullscreen: false,
            s_max_width: null,
            s_min_width: null,
            time: null,
            ng: ng,
            z_index: 0
        };
    },
    props: {
        modelValue: {
            type: Boolean,
            default: false,
        },
        fullscreen: {
            type: Boolean,
            default: false,
        },
        no_actions: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: "제목",
        },
        transition: {
            type: String,
            default: "dialog-bottom-transition",
        },
        width: {
            type: String,
            default: undefined,
        },
        no_title: {
            type: Boolean,
            default: false,
        },
        size: {
            type: Object,
            default: null
        }
    },
    computed: {
        show: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit("update:modelValue", value);
            },
        },
        style() {
            var obj = {};
            obj['z-index'] = this.z_index;
            return obj;
        },
        style_card() {
            var obj = {};
            if (this.width != undefined) obj['width'] = this.width + 'px';
            return obj;
        }
    },
    mounted() {
        if (this.size != null) {
            this.s_fullscreen = this.size.fullscreen;
            if (!this.s_fullscreen) {
                this.s_max_width = this.size.max_width;
                this.s_min_width = this.size.min_width;
            }
        } else {
            this.s_fullscreen = this.fullscreen;
            this.s_max_width = this['max-width'];
            this.s_min_width = this['min-width'];
        }
    },
    methods: {

    },
    watch: {
        show(val) {
            if (val) {
                this.time = new Date().getTime();
                ng.modal.list.push(this.time);
                ng.modal.modal_open();

                /*
                var opt = {
                    path: this.$route.path,
                    query: this.$route.query,
                    params: this.$route.params
                }

                opt.params._menu = 'Y'
                opt.params._time = new Date().getTime();
                this.$router.push(opt)
                */
                this.z_index = ng.modal.z_index;
            } else {
                ng.modal.close();
            }
        }
    }
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/_variables.scss";
@import "@/assets/css/_mixins.scss";

.popup {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    /*
    height: 100vh;
    height: 100dvh;*/
    z-index: 100;
    animation: fadeInUp 0.1s;

    .bg {
        position: absolute;
        top: 0px;
        right: 0px;
        width: 100%;

        height: 100vh;
        background-color: black;
        opacity: 0.7;
    }

    .content {
        border-radius: 12px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-width: 300px;
        max-width: 100%;
        height: fit-content;
        min-height: 100px;
        max-height: 100dvh;
    }

}

.no_fullscreen {
    > .content {
        width: fit-content;
        height: fit-content;
        max-height: calc(100vh - 20px);
    }
}
.fullscreen {
    height: 100vh;

    > .content {
        display: flex !important;
        flex-direction: column;
        min-width: 100%;
        height: 100vh;
    }
}

.popup.youtube {
    .content {
        width: 100%;
    }
}

.top {
    > .content {
        top:0px;
        transform: translate(-50%, 0);
    }
}
</style>
