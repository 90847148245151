<template>
    <v-select v-model="folder" :items="folders" label="" dense outlined hide-details variant="plain" item-title="name"
        item-value="uuid" @update:model-value="this.$emit('selectFolder', this.folder);">
        <template v-slot:selection="{ item }">
            <span class="folder" v-if="item.raw.uuid == undefined">
                {{ placeholder }}
            </span>
            <span class="folder" v-if="item.raw.uuid != undefined">
                <v-icon v-if="item.raw.color == ''">mdi-folder-outline</v-icon>
                <v-icon :class="folderColor(item.raw)" v-if="item.raw.color != ''">mdi-folder</v-icon>
                {{ item.raw.name }}
                <span v-if="type == 'drug' && item.raw.drug_cnt != null"> ({{ item.raw.drug_cnt }}건)</span>
                <span v-if="type == 'prescription' && item.raw.prescription_cnt != null"> ({{ item.raw.prescription_cnt
                }}건)</span>
            </span>
        </template>
        <template #item="{ item, props }">
            <v-list-item v-bind="props">
                <template #title>
                    <span class="folder">
                        <v-icon v-if="item.raw.color == ''">mdi-folder-outline</v-icon>
                        <v-icon :class="folderColor(item.raw)" v-if="item.raw.color != ''">mdi-folder</v-icon>
                        {{ item.raw.name }}
                        <span v-if="type == 'drug' && item.raw.drug_cnt != null"> ({{ item.raw.drug_cnt }}건)</span>
                        <span v-if="type == 'prescription' && item.raw.prescription_cnt != null"> ({{
                            item.raw.prescription_cnt }}건)</span>
                    </span>
                </template>
            </v-list-item>
        </template>
    </v-select>
</template>
<script>
export default {
    name: "FolderSelect",
    components: {

    },
    props: {
        all: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: '전체'
        }
    },
    computed: {

    },
    data: () => ({
        folder: '',
        folders: [],
    }),
    mounted() {
        this.$api.my.folder.list().then(response => {
            var folders = response.data.data;
            this.$emit('folders', folders);
            if (this.all) folders.splice(0, 0, { name: '전체', uuid: '', color: '' });
            this.folders = folders;
        });
    },
    methods: {
        folderColor(item) {
            var cl = {}
            if (item.color == '') return {}
            cl[item.color] = true;
            return cl;
        }
    }
}
</script>
<style lang="scss" scoped>
@import "@/assets/css/_variables.scss";
@import "@/assets/css/_mixins.scss";

.v-select {
    @include select_de;
}

.v-select__selection {
    margin-right: 10px;
}

span.folder {
    line-height: 32px;

    .v-icon {
        display: inline-block;
        margin-right: 5px;
        height: 32px;
    }
}
</style>