<template>
    <div class="InputCompWrap">
        <div class="title" v-if="title != ''">
            <span>{{ title }}</span>
        </div>
        <div class="input1">
            <v-text-field v-model="input" :disabled="disabled" :readonly="readonly" :value="modelValue"
                :hide-details="hideDetails2" :hint="hint" persistent-hint :placeholder="placeholder" variant="outlined"
                hide-no-data density="compact" :type="type" :rules="rules" :suffix="suffix"
                @update:modelValue="$emit('update:modelValue', input)"></v-text-field>
            <span>{{ subText }}</span>
        </div>
    </div>
</template>
<script>
export default {
    name: "InputCompWrap",
    components: {

    },
    props: {
        modelValue: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: '',
        },
        hint: {
            type: String,
            default: '',
        },
        label: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: '',
        },
        subText: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: 'text',
        },
        hideDetails: {
            type: String,
            default: ''
        },
        rules: {
            type: Array,
            default: () => { return [] }
        },
        suffix: {
            type: String,
            default: ''
        }
    },
    data:()=>({
        input:null,
        hideDetails2:false
    }),
    mounted() {
        if (this.hideDetails == '') this.hideDetails2 = true;
        if (this.rules.length > 0) this.hideDetails2 = false;
    }
}
</script>
<style lang="scss" scoped>
@import "@/assets/css/_variables.scss";
@import "@/assets/css/_mixins.scss";

.InputCompWrap {
    padding: 0 0px 20px;
}

@media (min-width: 300px) {
    .InputCompWrap {
        padding: 0 0px 20px;
    }
}

.InputCompWrap span {
    @include H5;
    color: $b-8;
}

.title {
    padding-bottom: 4px;
}

.title span {
    @include H4;
    color: $b-g-6;
}

.input {
    @include input_default;
}


.v-text-field ::v-deep {
    * {
        padding: 0;
        border: none;
        border-radius: 8px;
        opacity: 1;
    }

    .v-field__outline {
        border-radius: 8px;
        border: 2px solid $b-8;
    }

    input {
        color: $b-9;
        @include H4;
        padding: 0 16px;
        line-height: 48px;
    }


    .v-text-field__suffix {
        line-height: 48px;
        height: 48px;
        padding-right: 10px;
    }

    span,
    input::placeholder {
        @include H4;
        color: $b-9;
        opacity: 1;
    }

    :hover input,
    :focus input {
        color: $b-9;
        background-color: $b-1;
    }
}
</style>