<template>
    <span>
        <template v-if="show_all == false">
            <span class="folder" v-if="item.folders == undefined || item.folders.length == 0">
                <v-icon>mdi-folder-outline</v-icon> 미지정
            </span>
            <span class="folder" v-if="item.folders != undefined && item.folders.length > 0 && item.exp_folder != true"
                @click.stop="$emit('folder_expand')">
                <v-icon v-if="item.folders[0].color == null">mdi-folder-outline</v-icon>
                <v-icon v-if="item.folders[0].color != null" :class="folderColor(item.folders[0])">mdi-folder</v-icon>
                {{ item.folders[0].name }}
                <template v-if="item.folders.length > 1">...</template>
            </span>
            <span class="folder folder_all"
                v-if="item.folders != undefined && item.folders.length > 0 && item.exp_folder == true"
                @click.stop="$emit('folder_shrink')">
                <span class="folder_a" v-for="folder in item.folders" :key="folder.uuid">
                    <v-icon v-if="folder.color == null">mdi-folder-outline</v-icon>
                    <v-icon v-if="folder.color != null" :class="folderColor(folder)">mdi-folder</v-icon>
                    {{ folder.name }}
                </span>
            </span>
        </template>
        <template v-if="show_all == true">
            <span class="folder" v-if="item.folders == undefined || item.folders.length == 0">
                <v-icon>mdi-folder-outline</v-icon> 미지정
            </span>
            <span class="folder folder_all"
                v-if="item.folders != undefined && item.folders.length > 0">
                <span class="folder_a" v-for="folder in item.folders" :key="folder.uuid">
                    <v-icon v-if="folder.color == null">mdi-folder-outline</v-icon>
                    <v-icon v-if="folder.color != null" :class="folderColor(folder)">mdi-folder</v-icon>
                    {{ folder.name }}
                </span>
            </span>
        </template>
    </span>
</template>
<script>
export default {
    name: 'FolderInfo',
    components: {},
    props: {
        item: {
            type: Object,
            default: () => ({})
        },
        show_all: {
            type: Boolean,
            default: false
        }
    },
    computed: {},
    data: () => ({}),
    mounted() {

    },
    methods: {
        folderColor(item) {
            var cl = {}
            if (item.color == undefined || item.color == '') return {}
            cl[item.color] = true;
            return cl;
        },
    },
    watch: {}
}
</script>
<style lang="scss" scoped>
@import "@/assets/css/_variables.scss";
@import "@/assets/css/_mixins.scss";

.folder_all>div {
    display: inline-block
}

.folder_a {
    display: inline-block;
    margin-right: 10px;
}
</style>