<template>
    <div class="logo">
        <img @click="$router.push('/')" :src="img.main.src" :alt="img.main.alt">
    </div>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'MainLogo',

    components: {
    },
    data: () => ({
        img: {
            main: {
                src: require('@/assets/images/main/logo.png'),
                alt: '메인로고'
            }
        }
    }),
    methods: {
    }
});
</script>
<style lang="scss" scoped>
@import "@/assets/css/_variables.scss";
@import "@/assets/css/_mixins.scss";

.logo {
    @include center;
    padding: 10px 0 30px;
}

.logo img {
  max-height: 90px;
  max-width: 60%;
}
</style>