<template>
    <div class="ComboBox">
        <v-text-field v-model="input" :value="modelValue" variant="plain" hide-no-data density="compact"
            :hide-details="hideDetails" :clearable="clearable" @click:clear="input = ''" :placeholder="placeholder"
            @update:modelValue="$emit('update:modelValue', input)" :append-inner-icon="menuIcon"
            @update:focused="focused" @click:control="click_control($event)" @click:appendInner="toggleMenu"
            @keydown="keydown">
        </v-text-field>
        <div class="list_wrapper" v-if="items.length > 0 && is_show">
            <div class="list">
                <span class="btn_close"><v-icon @click="toggleMenu">mdi-close-circle</v-icon></span>
                <span v-for="item in items" :key="item" @click="updateValue(item)">{{ item }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SimpleInput',
    props: {
        modelValue: {
            type: String,
            default: ''
        },
        clearable: {
            type: Boolean,
            default: true
        },
        hideDetails: {
            type: Boolean,
            default: true
        },
        items: {
            type: Array,
            default: () => ([])
        },
        placeholder: {
            type: String,
            default: ''
        }
    },
    data: () => ({
        input: null,
        is_show: false,
        time: new Date().getTime(),
        timer: null,
        isDirty: false
    }),
    computed: {
        menuIcon() {
            if (this.items.length == 0) return '';
            if (this.is_show) {
                return 'mdi-chevron-up';
            } else {
                return 'mdi-chevron-down';
            }
        }
    },
    methods: {
        click_control(event) {
            if (event.target.tagName == 'INPUT') this.is_show = true;
        },
        is_valid() {
            var time = new Date().getTime();
            if ((time - this.time) > 100) {
                this.time = time;
                return true;
            }
            return false;
        },
        toggleMenu() {
            clearTimeout(this.timer);
            var is_valid = this.is_valid();
            if (is_valid) this.is_show = this.is_show ? false : true;
        },
        updateValue(val) {
            this.input = val;
            this.is_show = false;

            this.$emit('update:modelValue', val);
        },
        focused(val) {
            var vm = this;

            if (val) {
                this.timer = setTimeout(function () {
                    if (vm.is_valid()) {
                        if (val) vm.is_show = true;
                        else vm.is_show = false;
                    }
                }, 200)
            } else {
                this.is_show = true;
            }
        },
        keydown() {
            this.isDirty = true;
        }
    },
    watch: {
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/_variables.scss";
@import "@/assets/css/_mixins.scss";


.v-input {
    @include simple_input;
}

.ComboBox {
    position: relative;
}


.list_wrapper {
    position: absolute;
    z-index: 1;
    width: 100%;
    max-height: 200px;
    height: 200px;
    overflow-y: scroll;
    border: 1px solid grey;
    background-color: white;
    border-radius: 5px;

    .list {
        position: absolute;
        background: white;
        min-width: 100px;
        width: 100%;
        border: 0px solid grey;
        padding: 0px 15px 15px 15px;
        border-radius: 5px;

        span {
            display: block;
            cursor: pointer;
            line-height: 170%;
            margin: 5px 0px;
        }

        span.btn_close {
            text-align: right;
        }
    }
}
</style>