<template>
    <div class="AdvancedSearch">
        <div class="mainWrap">
            <ASBox1 title="제품명">
                <template v-slot:input>
                    <ComboBox ref="item_name" v-model="search_opts.item_name" placeholder="제품명의 일부나 초성을 입력해주세요."
                        :items="autocomplete_item_names" :filter="customFilter" :clearable="true"
                        @click:clear="autocomplete_item_names = []" />
                </template>

            </ASBox1>


            <ASBox1 title="성분명">

                <template v-slot:title>
                    <div class="btn"><v-btn class="btn_edit2" variant="text" icon="mdi-plus-box"
                            @click="add_ingr"></v-btn><v-btn class="btn_edit2" variant="text" icon="mdi-minus-box"
                            v-if="ingr_names.length > 1" @click="sub_ingr"></v-btn></div>
                </template>

                <template v-slot:input>
                    <div class="inputWrap" v-for="(item, i) in ingr_names" :key="i">
                        <div class="l">
                            <SimpleSelect v-model="ingr_names_opt[i]" :items="and_or_not" />
                        </div>
                        <div class="r">
                            <ComboBox v-model="ingr_names[i]" placeholder="성분명을 입력해주세요."
                                :items="autocomplete_ingr_names[i]" :filter="customFilter" :clearable="true"
                                @click:clear="autocomplete_ingr_names[i] = []" />
                        </div>
                    </div>
                </template>
            </ASBox1>

            <ASBox1 title="회사명">

                <template v-slot:input>
                    <ComboBox ref="entp_name" v-model="search_opts.entp_name" placeholder="회사명의 일부나 초성을 입력해주세요."
                        :items="autocomplete_entp_names" :filter="customFilter" :clearable="true"
                        @click:clear="autocomplete_entp_names = []" />
                </template>
            </ASBox1>

            <ASBox1 title="단일/복합">

                <template v-slot:input>
                    <SimpleSelect v-model="search_opts.is_single" :items="search_opts_list.is_single" />
                </template>
            </ASBox1>

            <ASBox1 title="전문/일반">

                <template v-slot:input>
                    <SimpleSelect v-model="search_opts.etc_otc_code" :items="search_opts_list.etc_otc_code" />
                </template>
            </ASBox1>

            <div class="etc_line">
                <span class="btn_etc_exp" v-if="etc_exp == 0" @click="etc_exp = 1">
                    기타 검색 조건
                    <v-icon >mdi-arrow-expand-down</v-icon>
                </span>
                <span class="btn_etc_exp" v-if="etc_exp == 1" @click="etc_exp = 0">
                    기타 검색 조건
                    <v-icon >mdi-arrow-collapse-up</v-icon>
                </span>
            </div>


            <div class="etc_exp" v-if="etc_exp == 1">
                <ASBox1 title="품목구분">

                    <template v-slot:input>
                        <SimpleSelect v-model="search_opts.induty" :items="search_opts_list.induty" />
                    </template>
                </ASBox1>

                <ASBox1 title="취소/취하">

                    <template v-slot:input>
                        <SimpleSelect v-model="search_opts.cancel_name" :items="search_opts_list.cancel_name" />
                    </template>
                </ASBox1>

                <ASBox1 title="원제/원료">

                    <template v-slot:input>
                        <SimpleSelect v-model="search_opts.make_material_flag"
                            :items="search_opts_list.make_material_flag" />
                    </template>
                </ASBox1>

                <ASBox1 title="품목기준코드">

                    <template v-slot:input>
                        <SimpleInput v-model="search_opts.item_seq" />
                    </template>
                </ASBox1>

                <ASBox1 title="표준코드">

                    <template v-slot:input>
                        <SimpleInput v-model="search_opts.bar_code" />
                    </template>
                </ASBox1>

                <ASBox1 title="ATC코드">

                    <template v-slot:input>
                        <SimpleInput v-model="search_opts.atc_code" />
                    </template>
                </ASBox1>

                <ASBox1 title="허가일">

                    <template v-slot:input>
                        <SimpleSelect v-model="search_opts.permit_date" :items="search_opts_list.permit_date" />
                    </template>
                </ASBox1>
            </div>

            <div class="nb_group">
                <span>허가사항 카테고리별 "키워드" 입력 시, 해당하는 의약품을 검색할 수 있습니다.
                    <v-icon @click="$root.v_dialog.help_advanced_search = true">mdi-help-circle-outline</v-icon>
                </span>

                <ASBox1 title="효능효과">
                    <template v-slot:input>
                        <SimpleInput v-model="search_opts.search_ee" placeholder="예: 당뇨 고혈압" />
                    </template>
                </ASBox1>

                <ASBox1 title="용법용량">
                    <template v-slot:input>
                        <SimpleInput v-model="search_opts.search_ud" placeholder="예: 주사 20mg" />
                    </template>
                </ASBox1>

                <div class="group1">
                    <ASBox1 title="주의사항">
                        <template v-slot:input>
                            <span class="g1">주의사항 전반 또는 특정 카테고리내 키워드 검색이 가능합니다.</span>
                            <SimpleInput v-model="search_opts.search_nb" placeholder="예: 알러지 알레르기" />
                        </template>
                    </ASBox1>

                    <div class="group2">
                        <ASBox1 title="투여금지">
                            <template v-slot:input>
                                <SimpleInput v-model="search_opts.nb_s02" placeholder="예: 임신 투석 신장" />
                            </template>
                        </ASBox1>

                        <ASBox1 title="상호작용">
                            <template v-slot:input>
                                <SimpleInput v-model="search_opts.nb_s06" placeholder="예: 항생제 CYP" />
                            </template>
                        </ASBox1>

                        <ASBox1 title="특정집단에서의 주의사항 여부" class="nb_etc">
                            <template v-slot:input>
                                <CheckBox v-model="search_opts.opt_nb_index.NB0701A" label="임신/수유/가임" value="0701A" />
                                <CheckBox v-model="search_opts.opt_nb_index.NB0704" label="소아" value="0704" />
                                <CheckBox v-model="search_opts.opt_nb_index.NB0705" label="고령자" value="0705" />
                                <CheckBox v-model="search_opts.opt_nb_index.NB0706" label="신장애" value="0706" />
                                <CheckBox v-model="search_opts.opt_nb_index.NB0707" label="간장애" value="0707" />
                            </template>
                        </ASBox1>
                    </div>
                </div>
            </div>
        </div>

        <div class="foot_button">
            <button @:click="search_clear">초기화</button>
            <button @:click="search_adv">검색</button>
        </div>
    </div>
</template>

<script>
//import AutoComplete from '@/components/search/AutoComplete.vue'
import ASBox1 from '@/components/search/ASBox1.vue'
import CheckBox from '@/components/CheckBox.vue'
import ComboBox from '@/components/search/ComboBox2.vue'
import SimpleInput from '@/components/search/SimpleInput.vue'
import SimpleSelect from '@/components/search/SimpleSelect.vue'

import SearchData from '@/assets/data/search.json';

export default {
    name: 'AdvancedSearch',
    components: {
        ASBox1,
        /*AutoComplete,*/
        CheckBox,
        ComboBox,
        SimpleInput,
        SimpleSelect
    },
    props: {},
    data: () => ({
        autocomplete_entp_names: [],
        autocomplete_ingr_names: [[]],
        autocomplete_item_names: [],

        ingr_names: [null],
        ingr_names_opt: ["+"],


        search_opts: {
            item_name: null,
            nb_index: {
                NB0701A: false,
                NB0704: false,
                NB0705: false,
                NB0706: false,
                NB0707: false
            }
        },
        focusCombox: false,

        and_or_not: [
            { l: "AND", v: "+" },
            { l: "OR", v: "" },
            { l: "NOT", v: "-" },
        ],
        search_ingr_name: [''],
        search_opts_list: {},
        search_item_name: '',
        timer_entp_names: null,
        timer_item_names: null,
        timer_ingr_names: null,
        etc_exp: 0
    }),
    computed: {
        ingrForWatcher() {
            return JSON.parse(JSON.stringify(this.ingr_names));// _.cloneDeep(this.ingr_names);
        }
    },
    created() {
        Object.keys(SearchData['고급검색']).forEach(k => {
            this[k] = JSON.parse(JSON.stringify(SearchData['고급검색'][k]));
        });

        this.default_search_opts = JSON.parse(JSON.stringify(this.search_opts));
    },
    mounted() {
        Object.keys(this.$route.query).forEach((q) => {
            if (this.$route.query[q] != "") {
                this.search_opts[q] = this.$route.query[q];
            }
        });

        if (this.$route.query.nb_index != undefined) {
            var arr_nb = this.$route.query.nb_index.split(',')
            arr_nb.forEach(k => {
                this.search_opts.opt_nb_index['NB' + k] = k;
            })
        }

        if (
            this.search_opts.ingr_name != undefined &&
            this.search_opts.ingr_name.length > 0
        ) {
            this.search_ingr_name = [];
            this.ingr_names = [];
            this.ingr_names_opt = [];
            var arr = this.search_opts.ingr_name.split(",");
            arr.forEach((name) => {
                var sopt = "";
                if (name.startsWith("+")) {
                    name = name.substring(1);
                    sopt = "+";
                } else if (name.startsWith("-")) {
                    name = name.substring(1);
                    sopt = "-";
                }
                this.ingr_names.push(name);
                this.search_ingr_name.push(name);
                this.ingr_names_opt.push(sopt);
            });
        }
    },
    methods: {
        add_ingr() {
            this.ingr_names.push(null);
            this.ingr_names_opt.push("+");
        },
        sub_ingr() {
            this.ingr_names.pop();
            this.ingr_names_opt.pop();
        },

        customFilter() {
            return true;
        },
        auto_query(val) {
            if (val == null || val.length < 2) return null;

            var pattern1 = /[가-힣]/; //한글
            var pattern2 = /[ㄱ-ㅎ]/; //한글

            var query = {};

            if (pattern1.test(val)) {
                if (val.length < 2) return;
                query.name = val;
            } else if (pattern2.test(val)) {
                if (val.length < 3) return;
                query.choseong = val;
            } else {
                return null;
            }
            return query;
        },
        autocomplete_entp_name(val) {
            var query = this.auto_query(val);
            if (query == null) {
                this.autocomplete_entp_names = [];
                return;
            }

            this.$api.autocomplete.entp_name({}, query).then((response) => {
                this.autocomplete_entp_names = [];
                if (response.data.data) {
                    response.data.data.forEach((item) => {
                        this.autocomplete_entp_names.push(item.name);
                    });
                }
            });
        },
        autocomplete_ingr_name(i) {
            var v = this.ingr_names[i];

            if (v == null || v.length < 2) {
                this.autocomplete_ingr_names[i] = [];
                return;
            }

            var pattern1 = /[가-힣]/; //한글
            var pattern2 = /[ㄱ-ㅎ]/; //한글

            var query = {};
            query.lang = "kor";

            if (pattern1.test(v)) {
                if (v.length < 2) return;
                query.q = v;
            } else if (pattern2.test(v)) {
                if (v.length < 3) return;
                query.q = v;
            } else {
                if (v.length < 3) return;
                query.q = v;
                query.lang = "eng";
            }

            this.autocomplete_ingr_names[i] = [];
            this.$api.autocomplete.ingredient({}, query).then((response) => {
                if (response.data.data) {
                    response.data.data.forEach((item) => {
                        this.autocomplete_ingr_names[i].push(item.name);
                    });
                }
            });
        },
        autocomplete_item_name(val) {
            if (val == null || val.length < 2) {
                this.autocomplete_item_names = [];
                return;
            }


            var pattern1 = /[가-힣]/; //한글
            var pattern2 = /[ㄱ-ㅎ]/; //한글

            var query = {};

            var search_type = "ko";

            if (pattern1.test(val)) {
                query.item_name = val;
            } else if (pattern2.test(val)) {
                query.choseong = val;
            } else {
                query.item_name_eng = val;
                search_type = "en";
            }

            this.$api.autocomplete.medicine({}, query).then((response) => {
                this.autocomplete_item_names = [];
                if (response.data.data) {
                    response.data.data.forEach((item) => {
                        if (search_type == "ko")
                            this.autocomplete_item_names.push(item.item_name);
                        else this.autocomplete_item_names.push(item.item_name_eng);
                    });

                    //this.$refs.item_name.focus()
                }
            });
        },
        search_clear() {
            this.search_opts = JSON.parse(JSON.stringify(this.default_search_opts));
        },
        search_adv() {
            if (this.search_item_name != "" && this.search_opts.item_name == "") {
                this.search_opts.item_name = this.search_item_name;
            }

            var query = {};
            Object.keys(this.search_opts).forEach((item) => {
                if (this.search_opts[item] != "전체") {
                    query[item] = this.search_opts[item];
                    if (query[item] == null) query[item] = "";
                }
            });

            query.mode = "exp";
            var ingr = [];
            var ingr_or = [];
            this.ingr_names.forEach((name, i) => {
                if (name != null) {
                    name = name.trim();
                    if (name.length >= 2) {
                        ingr.push(this.ingr_names_opt[i] + name);
                    }
                    if (this.ingr_names_opt[i] == "") ingr_or.push(name);
                }
            });

            if (query.sorting == 'ingr_name.asc' && ingr.length == 0) {
                query.sorting = 'item_name.asc';
            }

            if (ingr.length > 0) {
                query.sorting = "ingr_name.asc";
            }

            query.ingr_name = ingr.join(",");

            if (ingr_or.length == 1) {
                this.$alert({
                    html: "OR 조건의 성분명은 한개로 검색할 수 없습니다.<br>2개 이상 등록하거나 OR A, OR B로 선택해주세요.",
                });
                return;
            }

            var num = 0;

            var query2 = {};
            Object.keys(query).forEach(k => {
                if (query[k] != null && query[k] != '' && k != 'opt_nb_index') {
                    num++;
                    query2[k] = query[k];
                }
            });

            var arr_nb_index = []
            Object.keys(this.search_opts.opt_nb_index).forEach(key => {
                if (this.search_opts.opt_nb_index[key] != '') arr_nb_index.push(this.search_opts.opt_nb_index[key]);
            });

            num += arr_nb_index.length;
            query2.nb_index = arr_nb_index.join(",")


            if (num < 2) {
                this.$alert('한개이상의 검색 옵션을 설정해주세요.');
                return;
            }
            query2.p = 1;
            query2.t = new Date().getTime();

            if( (query2.item_name != undefined && query2.item_name != '') 
                || (query2.ingr_name != undefined && query2.ingr_name != '') 
                || (query2.entp_name != undefined && query2.entp_name != '')) {

                    query2.sorting = 'best.desc';
            }   

            this.$emit("close_adv_option");
            this.$router.push({ path: "/drug/search", query: query2, replace: true });
        }
    },
    watch: {
        'search_opts.item_name'(val) {
            if (this.$refs.item_name == undefined || !this.$refs.item_name.$data.isDirty) {
                return;
            }

            var vm = this;
            if (this.timer_item_names != null) {
                clearTimeout(this.timer_item_names)
            }
            this.timer_item_names = setTimeout(function () {
                vm.autocomplete_item_name(val)
            }, 100);
        },
        'search_opts.entp_name'(val) {
            if (this.$refs.entp_name == undefined || !this.$refs.entp_name.$data.isDirty) {
                return;
            }

            var vm = this;

            if (this.timer_entp_names != null) {
                clearTimeout(this.timer_entp_names)
            }
            this.timer_entp_names = setTimeout(function () {
                vm.autocomplete_entp_name(val)
            }, 100);
        },
        ingrForWatcher: {
            deep: true,
            handler(newItems, oldItems) {
                var vm = this;

                newItems.forEach(function (item, index) {
                    if (item != oldItems[index]) {
                        if (vm.timer_ingr_names != null) {
                            clearTimeout(vm.timer_ingr_names)
                        }
                        vm.timer_entp_names = setTimeout(function () {
                            vm.autocomplete_ingr_name(index)
                        }, 100);
                    }
                });
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/_variables.scss";
@import "@/assets/css/_mixins.scss";

.mainWrap {
    padding: 0px 0px 20px 0px;
}

.ASBox1 {
    margin-bottom: 20px !important;
}

.title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
}

.title>* {
    display: inline-flex;
}

.title>div.btn {
    padding-left: 10px;
}


.inputWrap {
    display: flex;
    margin-bottom: 10px;
}

.ASBox1 .inputWrap:last-child {
    margin-bottom: 0px;
}

.inputWrap .l {
    min-width: 100px;
}

.inputWrap .r {
    flex-grow: 1;
    padding-left: 10px;
}

.btn_edit2::v-deep.v-btn--icon.v-btn--density-default {
    width: 30px;
    height: 30px;
}


.btn_edit2::v-deep {
    .v-btn--icon.v-btn--density-default {
        width: 30px;
        height: 30px;
    }

    .v-icon {
        font-size: 30px;
        color: #1e40af
    }
}

.nb_etc::v-deep {

    .input {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .v-checkbox {
            min-width: 100px;
        }
    }

}



.foot_button {
    @include center;
}

.foot_button button {
    @include H2;
    width: 50%;
    padding: 10px 0;
    color: $b-9;
    min-height: 48px;
}

.foot_button button:first-child {
    border-radius: 8px 0 0 0;
    background-color: $b-g-1;
}

.foot_button button:last-child {
    border-radius: 0 8px 0 0;
    background-color: $b-g-1;
}

.foot_button.type2 button {
    border-radius: 8px 8px 0 0;
}

.foot_button button:hover {
    background-color: $b-1;
}


.nb_group {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid $b-9;

    span.g1 {
        display: block;
        margin-bottom: 10px;
    }

    div.group1 {
        background-color: $b-g-05;
        border-radius: 10px;
        padding: 10px;
    }

    div.group2 {
        padding-left: 20px;
    }
}

.nb_group>span {
    display: block;
    margin-bottom: 20px;
}

.etc_line {
    display: flex;
    flex-direction: row-reverse;
}

.btn_etc_exp {
    width: fit-content;
    color: $b-g-6;
    font-size: 14px;
    font-weight: bold;
    border-bottom: 1px solid $b-g-6;
    padding-bottom: 5px;
    cursor: pointer;
}

.etc_exp {
    margin-top: 10px;
}
</style>