<template>
    <div>
      <div class="TheView">
        <slot />
      </div>
    </div>
  </template>
  <script>
  export default {
    name: 'layoutBasic',
    components: {

    },
    data: () => ({
      simple: true
    }),
  }
  </script>
  <style lang="scss" scoped>
  @import "@/assets/css/_variables.scss";
  @import "@/assets/css/_mixins.scss";
  
  .TheView {
    padding: 20px 8px;
    max-width: 800px;
    margin: 0px auto;
  }
  
  @media (min-width: 300px) {
    .TheView {
      padding: 20px 18px;
    }
  }
  </style>