<template>
    <div v-if="show" class="popup" :class="{ 'fullscreen': s_fullscreen }" :style="style" :key="time">
        <div class="bg"></div>
        <v-card :style="style_card">
            <v-toolbar dark color="primary" density="compact" v-if="!no_title">
                <v-toolbar-items>
                    <v-btn icon dark disabled="">

                    </v-btn>
                </v-toolbar-items>
                <v-toolbar-title>{{ title }}</v-toolbar-title>
                <v-toolbar-items>
                    <v-btn icon dark @click="show = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
                <slot name="body">
                    {{ defaultBodyContent }}
                </slot>
            </v-card-text>
            <v-card-actions class="justify-center pb-4" v-if="no_actions == false">
                <slot name="actions"> </slot>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import analytics from "@/assets/js/analytics";
import ng from '@/assets/js/ng.js'

export default {
    data() {
        return {
            notifications: false,
            sound: true,
            widgets: false,
            defaultBodyContent: "body slot 영역을 작성해주세요.",
            s_fullscreen: false,
            s_max_width: null,
            s_min_width: null,
            time: null,
            ng: ng,
            z_index: 0,
            p_id:''
        };
    },
    props: {
        modelValue: {
            type: Boolean,
            default: false,
        },
        fullscreen: {
            type: Boolean,
            default: false,
        },
        no_actions: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: "제목",
        },
        transition: {
            type: String,
            default: "dialog-bottom-transition",
        },
        width: {
            type: String,
            default: null,
        },
        no_title: {
            type: Boolean,
            default: false,
        },
        size: {
            type: Object,
            default: null
        },
        contentType: {
            type: String,
            default: null
        },
        maxWidth:{
            type: String,
            default: null,
        }
    },
    computed: {
        show: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit("update:modelValue", value);
            },
        },
        style() {
            var obj = {};
            obj['z-index'] = this.z_index;
            return obj;
        },
        style_card() {
            var obj = {};
            if (this.width != null) obj['width'] = this.width + 'px';
            if (this.s_max_width != null) obj['max-width'] = this.s_max_width + 'px';
            if (this.s_min_width != null) obj['min-width'] = this.s_min_width + 'px';
            return obj;
        }
    },
    mounted() {
        if (this.size != null) {
            this.s_fullscreen = this.size.fullscreen;
            if (!this.s_fullscreen) {
                this.s_max_width = this.size.max_width;
                this.s_min_width = this.size.min_width;
            }
        } else {
            this.s_fullscreen = this.fullscreen;
            this.s_max_width = this.maxWidth;
            this.s_min_width = this.minWidth;
        }
        /*
        var vm = this;
        window.onpopstate = function () {
            setTimeout(function () {
                if (vm.show && ng.modal.last_time() == vm.time) {
                    vm.show = false
                }
            }, 100)
            return false;
        };*/
    },
    methods: {

    },
    watch: {
        show(val) {
            if(this.contentType != null) {
                if(val) {
                    this.p_id = ng.uuid();
                    this.$root.popup_id[this.contentType] = this.p_id;
                    analytics.log_p('P_'+this.contentType+'.OPEN', this.p_id);
                } else {
                    if(this.p_id != undefined) {
                        analytics.log_p('P_'+this.contentType+'.CLOSE', this.p_id);
                    }
                }
            }

            if (val) {
                this.time = new Date().getTime();
                ng.modal.list.push(this.time);
                ng.modal.modal_open();

                /*
                var opt = {
                    path: this.$route.path,
                    query: this.$route.query,
                    params: this.$route.params
                }

                opt.params._menu = 'Y'
                opt.params._time = new Date().getTime();
                this.$router.push(opt)
                */
                this.z_index = ng.modal.z_index;
            } else {
                ng.modal.close();
            }
        }
    }
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/_variables.scss";
@import "@/assets/css/_mixins.scss";

.popup {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    /*
    height: 100vh;
    height: 100dvh;*/
    z-index: 100;
    animation: fadeInUp 0.1s;

    .bg {
        position: absolute;
        top: 0px;
        right: 0px;
        width: 100%;

        height: 100vh;
        background-color: black;
        opacity: 0.7;
    }

    .v-card {
        border-radius: 12px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-width: 300px;
        max-width: 100%;
        max-height: 100dvh;
        width: 100%;
    }

    .v-toolbar-title {
        text-align: center;
    }

    .v-card-text {
        padding: 16px 24px 24px;
        max-height: calc(var(100vh) - 48px);
        max-height: calc(var(100dvh) - 48px);
        max-height: calc(100vh - 48px);
        max-height: calc(100dvh - 48px);
        overflow-y: auto;
    }

    .v-card-actions {
        padding: 10px;
        width: 100%;

        .ButtonComp {
            margin: 0px 10px;
            min-width: 150px;
        }
    }
}

.popup.drug {
    .v-card-text {
        padding: 16px;
    }
}

.fullscreen {
    height: 100vh;
    height: 100dvh;

    & > .v-card {
        position: absolute;
        top: 0px;
        left: 0px;
        display: flex !important;
        flex-direction: column;
        min-width: 100%;
        height: 100vh;
        height: 100dvh;
        transform: none;
    }

    & > .v-card > .v-card-text {
        flex-grow: 1;
        overflow: auto;
    }
}

.no_actions > .v-card > .v-card-actions{
    display: none;
}

.no_bottom {
    .v-card>.v-card-text {
        /*padding-bottom: 0px !important;*/
        padding-bottom: env(safe-area-inset-bottom) !important;
    }
}

.no_margin {
    .v-card>.v-card-text {
        padding: 16px 24px 24px;
    }
}

.pb-4 {
    padding-bottom: 36px !important;
}
</style>
